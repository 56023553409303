import Grid from '../../assets/dashboard.svg';
import List from '../../assets/jobs.svg';
import { MouseEvent } from 'react';
import { Tooltip } from '@mui/material';

export default function ToggleView({ isGrid, toggleView }: { isGrid: boolean, toggleView: (e:MouseEvent<HTMLButtonElement>) => void }) {
    return (
        <div className='flex justify-center items-center rounded bg-darker-background'>
            <Tooltip title='List View' placement='bottom'>
                <button onClick={toggleView} disabled={!isGrid ? true : false} className={`${!isGrid ? 'bg-tertiary' : 'bg-white darken-icons hover:bg-grad-one'} transition-all flex justify-center items-center px-2 rounded-tl rounded-bl h-full`}><img src={List} alt='list-view-icon' /></button>
            </Tooltip>
            <Tooltip title='Grid View' placement='bottom'>
                <button onClick={toggleView} disabled={isGrid ? true : false} className={`${isGrid ? 'bg-tertiary' : 'bg-white darken-icons hover:bg-grad-one'} transition-all flex justify-center items-center px-2 rounded-tr rounded-br h-full`}><img src={Grid} alt='list-view-icon' /></button>
            </Tooltip>
        </div>

    )
}