import FilterIcon from '../../assets/filter.svg';
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from "react";
import { PopupType } from "../../hooks/usePopup";
import { FilterType } from "../../types/all.types";
import { isRecordType, isSizeType } from "../../data/functions";
import { RecordType, SizeType } from "../Popups/FilterPopup";
export default function FilterButton({ 
    toggleFilterPopup, 
    setPopupType,
    filter,
}: { 
    toggleFilterPopup: (e: MouseEvent<HTMLButtonElement>) => void, 
    setPopupType?: Dispatch<SetStateAction<PopupType>>,
    filter?: FilterType,
}) {

    const [ filtersApplied, setFiltersApplied ] = useState(0);

    useEffect(() => {
        if (filter) {
            let applied = 0;

            Object.keys(filter).forEach(f => {
                const value = filter[f];

                if (Array.isArray(value)) {
                    applied += (value as string[]).length;
                } else if (isRecordType(value)) {
                    let record = value as RecordType;
                    if (record.op && record.quantity) {
                        applied += 1;
                    }
                } else if (isSizeType(value)) {
                    let size = value as SizeType;
                    if (size.op && size.size) {
                        applied += 1;
                    }
                } 
            });

            setFiltersApplied(applied);
        }
    }, [filter]);


    return (
        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {toggleFilterPopup(e); setPopupType && setPopupType('filter')}} className={`bg-grad-two hover:brightness-90 transition-all  text-primary flex justify-center items-center gap-2 py-2 px-5 rounded text-nowrap`}>
            <img src={FilterIcon} alt='filter-icon' className='w-[20px]'/>
            <p className='font-medium hidden lg:block'>Filter</p>
            {filtersApplied !== 0 && <div className="py-[2px] px-2 flex justify-center items-center rounded-full bg-primary bg-opacity-10 text-primary text-[12px] font-medium">{filtersApplied}</div>}
        </button>

    )
}