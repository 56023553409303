import { useState, MouseEvent } from "react";

export type PopupType = 'upload-file' | 'create-dataset' | 'delete' | 'delete-vis' | 'delete-all-vis' | 'filter' | 'readacross' | 'chemical' | 'success' | 'existing-file' | 'select-file-type' | 'add-user' | 'existing-dataset' | 'error' | 'existing-model' | 'permissions' | 'visualizations'
 | 'table-info'

const usePopup = () => {
    const [ isSideMenuOpen, setIsSideMenuOpen ] = useState(false);
    const [ isPopupOpen, setIsPopupOpen ] = useState(false);
    const [ popupType, setPopupType ] = useState<PopupType>('upload-file');

    const togglePopup = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsPopupOpen(!isPopupOpen);
    }

    const toggleSideMenu = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!isSideMenuOpen) {
            setIsSideMenuOpen(!isSideMenuOpen);
            const openSide = () => {
                const overlay = document.getElementById('overlay');
                if (overlay) {
                    overlay.classList.add('open');
                }
            }
            setTimeout(openSide, 0);
        } else {
            const overlay = document.getElementById('overlay');
            if (overlay) {
                overlay.classList.remove('open');
            }
            setTimeout(function() {
                setIsSideMenuOpen(!isSideMenuOpen);
            }, 300);
            
        }
        
        
    }








    return {
        toggleSideMenu,
        isSideMenuOpen,
        togglePopup,
        setPopupType,
        popupType,
        isPopupOpen,
    }
}

export default usePopup;