import { Header } from "../../../types/all.types"
import { SinglePredictionResults } from "../../../types/predictions.types"
import { useState, useEffect, Dispatch, SetStateAction } from "react"
import useSortByHeaders from "../../../hooks/useSortByHeaders"
import { CompleteModel } from "../../../types/models.types";
import ThresholdSlider from "../../../components/Data/ThresholdSlider"
import TableBase from "../../../components/Table/TableBase"
import TableRowBase from "../../../components/Table/TableRowBase"

interface Result {
    model: string,
    [key:string]: string,
}

export default function ResultTable ({ results, selectedModels }: { results: SinglePredictionResults[], selectedModels: CompleteModel[] | null }){
    const [ headers, setHeaders ] = useState<Header[]>([]);
    const [ allResults, setAllResults ] = useState<Result[]>([]);
    const [ sortedResults, setSortedResults ] = useState<Result[]>([]);
    const [ thresold, setThreshold ] = useState<number | null>(null);

    const {  
        selectedHeader,
        setSortedColumn
    } = useSortByHeaders({allItems: allResults, setSortedItems: setSortedResults});


    useEffect(() => {
        if (selectedModels) {
            const modelNames = selectedModels.map(model => model.name);
            let all: Result[] = [];
    
            modelNames.forEach(name => {
                let obj:any = {};
                results.forEach(res => {
                    if (res.model === name) {
                        obj[res.method] = !Array.isArray(res.value) ? (Math.round(Number(res.value) * 100) / 100 as number).toFixed(2) : res.value;
                    }
                });
                if (Object.keys(obj).length !== 0) {
                    all.push({...obj, model: name});
                }
            });

            const methods = Array.from(new Set(results.map(res => res.method)));

            const head = [{
                label: 'Model',
                value: 'model',
                isRequired: true,
                isAscending: true,
                isSelected: true,
                isSortable: true,
            }].concat(methods.map(method => ({
                label: method,
                value: method,
                isRequired: false,
                isAscending: true,
                isSelected: true,
                isSortable: true,
            })))

            setHeaders(head);
    
            let newResults: Result[] = [];
    
            all.forEach(res => {
                let obj: any = {};
                head.forEach(h => {
                    obj = {...obj, [h.value]: res[h.value]}
                })
                newResults.push(obj);
            })

            setAllResults(newResults);
            setSortedResults(newResults);

        }

        
    }, [results, selectedModels]);

    useEffect(() => {
        if (sortedResults && sortedResults.length > 0) {
            let resWithConf: string[] = [];
            sortedResults.forEach(res => {
                const keys = Object.keys(res).filter(k => k !== 'model');
                if (['ada','bnb', 'knn', 'lreg', 'DL', 'rf', 'svc', 'xgb'].includes(keys[0]) && Array.isArray(res[keys[0]])) {
                    resWithConf.push(res.model)
                } 
            });

            if (resWithConf.length > 0) {
                setThreshold(0.5)
            } else {
                setThreshold(null);
            }
        }
    }, [sortedResults])    

    
    return (
        <div>
            {thresold !== null && (
                <div className="py-4 px-6">
                    <ThresholdSlider threshold={thresold} setThreshold={setThreshold as Dispatch<SetStateAction<number>>}/>
                </div>
            )}

            <TableBase
                isLoading={false}
                isPopupOpen={false}
                isChecked={false}
                hasOptions={false} 
                headers={headers} 
                selectedHeader={selectedHeader} 
                setSortedColumn={setSortedColumn} 
            >
                {sortedResults && sortedResults.map((item,i) => {
                    const keys = Object.keys(item);
                    const method = keys.find(k => k !== 'model');
                    return (
                        <TableRowBase 
                         item={item} 
                         headers={headers}
                         rowType={!Array.isArray(item[method as keyof object]) ? 'color' : 'conformal'}
                         threshold={thresold ? thresold : 0.5}
                         isChecked={false}
                        />
                    )    
                })}
            </TableBase>
            
            
        </div>
    )
}