import { ParsedValidationStats, ValidationStats } from "../../../types/predictions.types"
import useResultsetValidations from "../../../hooks/pageHooks/useResultsetValidations";
import { useState, MouseEvent } from "react";
import useModifyTableItems from "../../../hooks/useModifyTableItems";
import useLayout from "../../../hooks/useLayout";
import ToggleArrows from "../../../components/Page/ToggleArrows";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import PlotlyGraphs from "../../../components/Data/PlotlyGraphs";
import TableControls from "../../../components/Table/TableControls";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import ToggleView from "../../../components/Page/ToggleView";
import Grid from "../../../components/Grid/Grid";
import GridItemBase from "../../../components/Grid/GridItemBase";
import ChangePage from "../../../components/Page/ChangePage";
import ClassificationGraphs from "../../Models/components/ClassificationGraphs";
import EmptyTable from "../../../components/Table/EmptyTable";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import { classificationLimits } from "../../../data/models";
import usePopup from "../../../hooks/usePopup";
import Overlay from "../../../components/Popups/Overlay";
import PlotPopup from "../../../components/Popups/PlotPopup";


export default function Validations({ valid_stats, isLoading }: {
    valid_stats: ValidationStats[],
    isLoading: boolean,
}) {

    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const [ isFirst, setIsFirst ] = useState(true);
    const [ selectedMethod, setSelectedMethod ] = useState<ParsedValidationStats | null>(null);


    const {
        modifiedStats,
        stats,
        modifyRecords,
        headers,
        setHeaders,
        barData,
        radarData,
        back,
        next,
        methodPlots,
        modelName,
    } = useResultsetValidations({ valid_stats })

    
    const {
        selectHeaders,
        setSortedColumn,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyRecords, setHeaders, searchInput, setSelectedHeader, selectedHeader })

    const { 
        toggleView, 
        isGrid 
    } = useLayout();

    const { 
        isPopupOpen,
        setPopupType,
        togglePopup
    } = usePopup();

    const findSelectedMethod = (e:MouseEvent<HTMLButtonElement>, name: string) => {
        e.preventDefault();
        const method = stats.find(s => s.method_name === name);
        if (method) {
            setSelectedMethod(method);
            togglePopup(e);
            setPopupType('chemical');
        }
    }
    

    return (
        <>
            <div className='mb-6'>
                <div className={`w-full bg-white border border-[#CBCEDC] rounded flex flex-col gap-4 h-full p-8`}>
                    <div className={`w-full flex justify-between items-center`}>
                        <p className='text-[1.5rem] font-semibold text-nowrap'>{modelName}</p>
                        <div className='flex justify-center items-center rounded bg-darker-background shadow-inner'>
                            <ToggleArrows firstCondition={!isFirst} secondCondition={isFirst} firstAction={(e) => setIsFirst(true)} secondAction={(e) => setIsFirst(false)}/>
                        </div>
                    </div>
                    {isLoading !== undefined && isLoading ? (
                        <Skeleton/>
                    ) : (
                        <div className='w-full flex justify-center items-center gap-10'>
                        
                        {isFirst ? (
                            <PlotlyGraphs isSquare={false} type="bar" data={barData as any} />
                        ) : (
                            <PlotlyGraphs isSquare={false} type="radar" data={radarData as any} />  
                        )}
                        
                        </div>
                    )}
                </div>
            </div>

            <div style={{maxHeight: 'calc(100vh - 70px)'}} className="h-full overflow-hidden flex flex-col justify-start items-stretch gap-4">
                {/* Search Bar & Buttons Component */}
                <TableControls applySearch={applySearch} identifier="Method Name" setInput={setSearchInput} searchInput={searchInput}>
                    <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                    <ToggleView toggleView={toggleView} isGrid={isGrid} />
                </TableControls>
                
                {/* Table Component */}
                <>
                {isGrid ? (
                    <Grid noResults={!modifiedStats.length ? true : false} isLoading={isLoading} largerBoxes={true}>
                        {modifiedStats.length > 0 ? (
                            <>
                            {modifiedStats.length > 0 && modifiedStats.map(record => {
                                const obj = methodPlots.find(g => g.name === record.method_name);
                                return (
                                <GridItemBase 
                                    title={record.method_name} 
                                    optionButton={<ChangePage name={record.method_name} controls={{next, back}}  methodPlot={methodPlots.filter(plot => plot.name === record.method_name)[0]}/>}
                                    item={record} 
                                    action={{findSelectedMethod}}
                                    isChecked={false}
                                    gridType="structure"
                                >
                                    <ClassificationGraphs obj={obj} item={record as any} />
                                </GridItemBase>
                                )
                            })}
                            </>
                        ) : (
                            <EmptyTable isGrid={true} searchInput={searchInput} subject="This page contains no methods" />
                        )}
                        
                    </Grid>
                ) : (
                    <>
                    {modifiedStats.length > 0 || isLoading ? (
                        <TableBase
                            isLoading={isLoading}
                            isPopupOpen={false}
                            isChecked={false} 
                            hasOptions={false} 
                            headers={headers} 
                            selectedHeader={selectedHeader} 
                            setSortedColumn={setSortedColumn}
                        >
                            {modifiedStats.length > 0 && modifiedStats.map((record,i) => (
                                <TableRowBase 
                                    rowType="color"
                                    isChecked={false}
                                    item={record} 
                                    action={{findSelectedMethod}}
                                    headers={headers} 
                                    limits={classificationLimits}
                                >
                                </TableRowBase>
                            ))}
                        </TableBase>
                        ) : (
                            <EmptyTable searchInput={searchInput} subject="This page contains no methods" />
                        )}
                    </>
                    )}
                </>
            </div>

            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    <PlotPopup togglePopup={togglePopup} allData={selectedMethod && selectedMethod} methodPlot={methodPlots.filter(plot => plot.name === selectedMethod!.method_name)[0]} metrics={stats} limits={classificationLimits} title={selectedMethod ? selectedMethod.method_name : '' } type='classification' />
                </Overlay>
            )}
        </>
    )
}