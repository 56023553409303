// hooks
import { useState } from "react";
import { useParams } from "react-router-dom";
import usePopup from "../../../hooks/usePopup";
import useLayout from "../../../hooks/useLayout";
import useSelection from "../../../hooks/useEnableSelection";

// types
import { SingleClassificationModel, SingleRegressionModel } from "../../../types/models.types"
import { MouseEvent } from "react";

// assets
import Info from '../../../assets/info-circle.svg';

// components
import SinglePageHeader from "../../../components/Page/SinglePageHeader";
import Button from "../../../components/Buttons/Button";
import EnableSelectionButton from "../../../components/Buttons/EnableSelectionButton";
import ToggleView from "../../../components/Page/ToggleView";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import Grid from "../../../components/Grid/Grid";
import AdditionalInfoSideMenu from "../../../components/Popups/AdditionalInfoSideMenu";
import DeletePopup from "../../../components/Popups/DeletePopup";
import TableControls from "../../../components/Table/TableControls";
import GridItemBase from "../../../components/Grid/GridItemBase";
import ChangePage from "../../../components/Page/ChangePage";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import EmptyTable from "../../../components/Table/EmptyTable";
import useSingleModel from "../../../hooks/pageHooks/useSingleModel";
import { useSelector } from "react-redux";
import { selectApplication } from "../../../store/selectors/auth.selector";
import ClassificationGraphs from "../components/ClassificationGraphs";
import RegressionGraphs from "../components/RegressionGraphs";
import PlotPopup from "../../../components/Popups/PlotPopup";
import OptionsDropdownBase from "../../../components/Dropdowns/OptionsDropdownBase";
import IndividualModelOptions from "../components/IndividualModelOptions";
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";
import Overlay from "../../../components/Popups/Overlay";
import useMessage from "../../../hooks/useMessage";
import SnackBar from "../../../components/Alerts/SnackBar";
import useModifyTableItems from "../../../hooks/useModifyTableItems";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import ToggleArrows from "../../../components/Page/ToggleArrows";
import PlotlyGraphs from "../../../components/Data/PlotlyGraphs";


export default function SingleModelTemplate() {
    const { name } = useParams();
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const [ selectedMethod, setSelectedMethod ] = useState<SingleClassificationModel | SingleRegressionModel | null>(null);
    const [ isFirst, setIsFirst ] = useState(true);
    const application = useSelector(selectApplication);
    

    const {
        open,
        message,
        isSuccess,
        setPopupMessage,
        setOpen,
    } = useMessage();

    const {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        info,
        routes,
        downloadModels,
        allMethodIDs, 
        updateModel,
        deleteModels,
        singleModel,
        isClassification,
        isLoading,
        headers,
        setHeaders,
        methodPlots,
        back,
        next,
        stats,
        barData,
        radarData,
        limits,
        maxValue
    } = useSingleModel({setPopupMessage, name});
    
    const {
        selectHeaders,
        setSortedColumn,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyRecords, setHeaders, searchInput, setSelectedHeader, selectedHeader })

    const { 
        isSideMenuOpen, 
        toggleSideMenu, 
        isPopupOpen,
        setPopupType,
        popupType,
        togglePopup
    } = usePopup();

    const { 
        toggleView, 
        isGrid 
    } = useLayout();

    const { 
        isChecked, 
        handleChange,
        toggleChecked,
        selectAllRows,
        selectedRows,
        allAreSelected,
        editSelectedRows,
        deselectItems,
        addSingleItem
    } = useSelection({allItems: allMethodIDs});

    




    const findSelectedMethod = (e:MouseEvent<HTMLButtonElement>, name: string) => {
        e.preventDefault();
        const obj = singleModel.find(m => m.method_name === name);
        if (obj) {
            setSelectedMethod(obj);
            togglePopup(e);
            setPopupType('chemical');
        }
    }


    return (
        <div className="flex flex-col justify-start items-stretch gap-4">
                <SinglePageHeader label={name ? name : 'Single Model'} isLoading={isLoading} routes={routes ? routes : []} stats={stats}>
                    <OptionsDropdownBase isLoading={isLoading} >
                        <IndividualModelOptions application={application} singleModel={singleModel} name={name ? name : ''} downloadFunc={downloadModels} toggleDelete={togglePopup} setPopupType={setPopupType} handleClose={() => {}}/>
                    </OptionsDropdownBase>
                    <Button isLoading={isLoading} togglePopup={toggleSideMenu}>
                        <img src={Info} alt='info-logo' className="w-[16px]"/>
                        <p className='font-medium text-nowrap'>Show Details</p>
                    </Button>
                </SinglePageHeader>

                

                <div className='mb-6'>
                    <div className={`w-full bg-white border border-[#CBCEDC] rounded flex flex-col gap-4 h-full p-8`}>
                        <div className={`w-full flex justify-between items-center`}>
                            <p className='text-[1.5rem] font-semibold text-nowrap'>Method Comparison</p>
                            <div className='flex justify-center items-center rounded bg-darker-background shadow-inner'>
                                {isClassification && <ToggleArrows firstCondition={!isFirst} secondCondition={isFirst} firstAction={(e) => setIsFirst(true)} secondAction={(e) => setIsFirst(false)}/>}
                            </div>
                        </div>
                        {isLoading !== undefined && isLoading ? (
                            <Skeleton/>
                        ) : (
                            <div className='w-full flex justify-center items-center gap-10'>
                            
                            {isFirst ? (
                                <PlotlyGraphs isSquare={false} type="bar" data={barData as any} maxValue={isClassification ? undefined : maxValue}/>
                            ) : (
                                <>
                                {isClassification ? (
                                    <PlotlyGraphs isSquare={false} type="radar" data={radarData as any} />  
                                ) : (
                                    <></>
                                )}
                                </>
                            )}
                            
                            </div>
                        )}
                    </div>
                </div>

                <div style={{maxHeight: 'calc(100vh - 70px)'}} className="h-full overflow-hidden flex flex-col justify-start items-stretch gap-4">
                {/* Search Bar & Buttons Component */}
                <TableControls applySearch={applySearch} identifier="Method Name" name={name} deselectItems={deselectItems} toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={searchInput} selectedRows={selectedRows} downloadFunc={downloadModels} toggleCheck={toggleChecked}>
                    <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                    {application && (application).includes('assay-central') && <EnableSelectionButton isChecked={isChecked} handleChange={handleChange} />}
                    <ToggleView toggleView={toggleView} isGrid={isGrid} />
                </TableControls>
                
                {/* Table Component */}
                <>
                {isGrid ? (
                    <Grid noResults={!modifiedRecords.length ? true : false} isLoading={isLoading} largerBoxes={true}>
                        {modifiedRecords.length > 0 ? (
                            <>
                            {modifiedRecords.length > 0 && modifiedRecords.map(record => {
                                const item = singleModel.find(m => m.method_name === record.method_name);
                                if (item) {
                                    return (
                                    <GridItemBase 
                                        title={item.method_name} 
                                        optionButton={isClassification ? <ChangePage name={item.method_name} controls={{next, back}}  methodPlot={methodPlots.filter(plot => plot.name === item.method_name)[0]}/> : undefined}
                                        action={{findSelectedMethod}}
                                        item={item} 
                                        editSelectedRows={editSelectedRows} 
                                        selectedRows={selectedRows} 
                                        isChecked={isChecked}
                                        gridType="structure"
                                    >
                                        {isClassification ? (
                                            <ClassificationGraphs obj={methodPlots.filter(plot => plot.name === item.method_name)[0]} item={item as SingleClassificationModel} />
                                        ) : (
                                            <RegressionGraphs limits={limits} item={item as SingleRegressionModel} />
                                        )} 
                                    </GridItemBase>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                            </>
                        ) : (
                            <EmptyTable isGrid={true} searchInput={searchInput} subject="This page contains no methods" />
                        )}
                        
                    </Grid>
                ) : (
                    <>
                    {modifiedRecords.length > 0 || isLoading ? (
                        <TableBase
                            isLoading={isLoading}
                            isPopupOpen={false}
                            isChecked={isChecked} 
                            selectAllRows={selectAllRows} 
                            allAreSelected={allAreSelected} 
                            hasOptions={isLoading ? false : true} 
                            headers={headers} 
                            selectedHeader={selectedHeader} 
                            setSortedColumn={setSortedColumn}
                        >
                            {modifiedRecords.length > 0 && modifiedRecords.map((record,i) => (
                                <TableRowBase 
                                    rowType="color"
                                    selectedRows={selectedRows} 
                                    editSelectedRows={editSelectedRows} 
                                    isChecked={isChecked} 
                                    action={{findSelectedMethod}}
                                    item={record} 
                                    headers={headers} 
                                    limits={limits}
                                >
                                    {application && (application).includes('assay-central') ? 
                                        <IndividualOptionsDropdownBase>
                                            <IndividualModelOptions application={application} handleClose={() => {}} name={record.id} addSingleItem={addSingleItem} toggleDelete={togglePopup} setPopupType={setPopupType}/> 
                                        </IndividualOptionsDropdownBase>
                                        : 
                                        <div></div>
                                    }
                                </TableRowBase>
                            ))}
                        </TableBase>
                        ) : (
                            <EmptyTable searchInput={searchInput} subject="This page contains no methods" />
                        )}
                    </>
                    )}
                </>
                </div>

                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  

           
            {isSideMenuOpen && (
                <AdditionalInfoSideMenu id={name} info={info} label='Model Details' togglePopup={toggleSideMenu} updateFunc={updateModel}/>
            )}
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup deselectItems={deselectItems} modelName={name} toggleChecked={toggleChecked} isChecked={isChecked} togglePopup={togglePopup} deleteFunc={deleteModels} items={selectedRows.length ? selectedRows : name ? [name] : []} label={selectedRows.length > 1 ? 'these methods' : 'this model'} path={!selectedRows.length ? '/models' : undefined}/>
                    ) : (
                        <PlotPopup methodPlot={methodPlots.filter(plot => plot.name === selectedMethod!.method_name)[0]} togglePopup={togglePopup} allData={selectedMethod && selectedMethod} metrics={orderedRecords} limits={limits} title={selectedMethod ? selectedMethod.method_name : '' } type={isClassification ? 'classification' : 'regression'} />
                    )}
                </Overlay>
            )}
            

        </div>
    )
}