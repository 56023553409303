import { useEffect, useState } from "react";
import { ParsedJobs } from "../../types/jobs.types";
import { Tooltip } from "@mui/material";

const defaultStatuses = {
    Pending: 0,
    Running: 0,
    Done: 0,
    Failed: 0
}

interface JobStatuses {
    Pending: number,
    Running: number,
    Done: number,
    Failed: number,
}
export default function ProgressCell({ associatedItems }: { associatedItems?: ParsedJobs[] }) {
    const [ statuses, setStatuses ] = useState<JobStatuses>(defaultStatuses);
    const [ total, setTotal ] = useState(0);


    useEffect(() => {
        if (associatedItems && associatedItems.length > 0) {
            let Pending = 0;
            let Running = 0;
            let Failed = 0;
            let Done = 0;

           associatedItems.forEach(item => {
            const status = item.status;
            if (status === 'Pending') {
                Pending += 1;
            } else if (status === 'Running') {
                Running += 1;
            } else if (status === 'Failed') {
                Failed += 1;
            } else if (status === 'Done') {
                Done += 1;
            }
           })
            
           const stats: JobStatuses = { Pending, Running, Failed, Done };

            setStatuses(stats);
            setTotal(Pending + Running + Failed + Done);
        }
    }, [associatedItems]);

    const getWidth = (status: string) => {
        const statusTotal = statuses[status as keyof object];
        const ratio = statusTotal/total;
        const decimal = ratio*100;
       
        return `${decimal}%`;
    }


    return (
        <div className="w-full min-w-[200px] rounded-full h-[35px] overflow-hidden flex justify-between items-center">
            <>
            {statuses.Running > 0 ? (
                <div className="h-full py-2 bg-[#FFC061] w-full">
                    <p className="font-semibold text-primary">Running</p>
                </div>
            ) : statuses.Pending > 0 ? (
                <div className="h-full py-2 bg-[#FFF278] w-full">
                    <p className="font-semibold text-primary">Pending</p>
                </div>
            ) : statuses.Failed === total ? (
                <div className="h-full py-2 bg-[#FF8484] w-full">
                    <p className="font-semibold text-primary">Failed</p>
                </div>
            ) : statuses.Done === total ? (
                <div className="h-full py-2 bg-[#C7DB78] w-full">
                    <p className="font-semibold text-primary">Done</p>
                </div>
            ) : (
                <>
                {statuses.Failed !== 0 && (
                <Tooltip title={statuses.Failed === total ? '' : `Failed (${statuses.Failed})`} placement="bottom">
                <div style={{width: getWidth('Failed')}} className="h-full py-2 bg-[#FF8484]">
                    {statuses.Failed === total && <p className="font-semibold text-primary">Failed</p>}
                </div>
                </Tooltip>
                )}
                {statuses.Done !== 0 && (
                    <Tooltip title={statuses.Done === total ? '' : `Done (${statuses.Done})`} placement="bottom">
                    <div style={{width: getWidth('Done')}} className="h-full py-2 bg-[#C7DB78]">
                        {statuses.Done === total && <p className="font-semibold text-primary">Done</p>}
                    </div>
                    </Tooltip>
                )}
                
                </>
            )}
            </>
        </div>
    )
}