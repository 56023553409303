import Close from '../../../assets/x-close.svg';
import { useSelector } from 'react-redux';
import { selectApplication } from '../../../store/selectors/auth.selector';

export default function TableLegend({ togglePopup, isConformal }: {
    togglePopup: (e:any) => void,
    isConformal: boolean,
}) {
    const application = useSelector(selectApplication);

    return (
        <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
            <div id='table-info-popup' className={`bg-background rounded max-w-[500px] w-full popup-menu`}>
                <div className='popup-max-height overflow-y-auto'>
                    <div className='flex flex-col justify-center items-stretch p-8 gap-8'>  

                        <div className='w-full flex justify-between items-center'>
                            <p className='text-primary font-semibold text-[24px]'>Table Legend</p>
                            <button className='highlight' onClick={togglePopup}>
                                <img src={Close} alt='close-icon'/>
                            </button>
                        </div>

                        <div className='w-full flex flex-col gap-8 justify-start items-start'>
                            <div className='w-full flex flex-col justify-start items-stretch gap-1'>
                                <p className='font-semibold text-[16px] text-left'>ad-score</p>
                                <div className='w-full h-[32px] ad-gradient' />
                                <div className='w-full flex justify-between items-center gap-4'>
                                    <p className='font-medium text-[14px]'>Low Applicability</p>
                                    <p className='font-medium text-[14px]'>High Applicability</p>
                                </div>
                            </div>

                            {!isConformal && <div className='w-full flex flex-col justify-start items-stretch gap-1'>
                                <p className='font-semibold text-left text-[16px]'>Prediction Values</p>
                                <div className={`w-full h-[32px] ${application === 'megatox' ? 'tox-gradient' : 'pred-gradient'}`} />
                                <div className='w-full flex justify-between items-center gap-4'>
                                    <p className='font-medium text-[14px]'>{application === 'megatox' ? 'Non-Toxic (0)' : 'Inactive (0)'}</p>
                                    <p className='font-medium text-[14px]'>{application === 'megatox' ? 'Toxic (1)' : 'Active (1)'}</p>
                                </div>

                            </div>}

                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}