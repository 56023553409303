import { MouseEvent, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Overlay from "./Overlay";
import { CompleteModel } from "../../types/models.types";
import { selectCompleteModels } from "../../store/selectors/models.selector";
import AllModelsTable from '../../routes/Models/components/AllModelsTable';
import FormButtons from '../Buttons/FormButtons';




export default function ExistingModelPopup({ togglePopup, addMultipleModels, selectedModels }: { 
    togglePopup:  (e:MouseEvent<HTMLButtonElement>) => void, 
    addMultipleModels: (models: CompleteModel[]) => void,
    selectedModels?: CompleteModel[] | null,
}) {
    const completeModels = useSelector(selectCompleteModels);

    const [ isSubmitLoading, setIsSubmitLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ selectedModelNames, setSelectedModelNames ] = useState<string[]>([]);

    useEffect(() => {
        if (selectedModels && selectedModels.length) {
            setSelectedModelNames(selectedModels.map(m => m.name))
        } else {
            setSelectedModelNames([])
        }
    }, [selectedModels]);


    const getSelectedModels = async (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsSubmitLoading(true);
        setIsDisabled(true);

        let modelArr: CompleteModel[] = [];

        if (selectedModelNames.length > 0 && completeModels.length > 0) {
            selectedModelNames.forEach(name => {
                const completeModel = completeModels.find(m => m.name === name);
                if (completeModel) {
                    modelArr.push(completeModel);
                }
            })
        }

        addMultipleModels(modelArr);

        togglePopup(e);
        setIsSubmitLoading(false);
        setIsDisabled(false);
        
    }

    const cancelFunction = (e:MouseEvent<HTMLButtonElement>) => {
        togglePopup(e);
    };

    const continueFunction = (e:MouseEvent<HTMLButtonElement>) => {
        getSelectedModels(e);
    }


    return (
        <Overlay togglePopup={togglePopup}>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded p-5  max-w-[1400px] w-full'>
                    <div className='flex flex-col justify-center items-stretch'>
                    
                        <div className='w-full text-center mb-6'>
                            <p className='text-primary font-semibold text-[1.5rem]'>Select Existing Models</p>
                        </div>

                        <AllModelsTable setSelectedModelNames={setSelectedModelNames} selectedModelNames={selectedModelNames}/>

                        <div className="mt-6">
                            <FormButtons cancelFunction={cancelFunction} continueFunction={continueFunction} isDisabled={isDisabled} isLoading={isSubmitLoading} />
                        </div>


                    </div>
                    
                </div>
            </div>
        </Overlay>
    )
}