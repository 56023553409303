import { MouseEvent, useState } from 'react';
import { Header } from '../../types/all.types';

import ChevronDown from '../../assets/chevron-down.svg';
import ChevronUp from '../../assets/chevron-up.svg';
import Columns from '../../assets/columns.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';





export default function HeadersDropdown({ headers, selectHeaders, isDisabled, updateHeaders }: { 
    headers: Header[], 
    selectHeaders?: (e:MouseEvent<HTMLButtonElement>, label: string) => void,
    updateHeaders?: (e:MouseEvent<HTMLButtonElement>, label: string, headers: Header[]) => void,
    isDisabled?: boolean,
}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    





    return (
        <div>
            
            <button disabled={isDisabled} onClick={handleClick}>
                <div className={`transition-all ${isDisabled ? 'bg-quaternary bg-opacity-20' : 'bg-grad-two'} hover:brightness-90 text-primary flex justify-center items-center gap-3 py-3 px-5 rounded w-full`}>
                    <img src={Columns} alt='columns-dropdown-icon' className='w-[20px]'/>
                    <p className='font-medium hidden lg:block'>Columns</p>
                    {open ? <img src={ChevronUp} alt='close-dropdown-icon' /> : <img src={ChevronDown} alt='open-dropdown-icon' />}
                </div>
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                sx={{
                    marginTop: '8px',
                    '& .MuiMenu-list': {
                        padding: 0,
                    }
                }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {headers.filter(h => !h.isRequired).map((header, i) => (
                    <MenuItem key={i} sx={{
                        fontFamily: 'Montserrat',
                        fontWeight: 'medium',
                        color: `${header.isSelected ? '#006D77' : '#35424A'}`,
                        backgroundColor: `${header.isSelected ? 'rgba(0,109,119,0.2)' : 'ffffff'}`,
                        padding: '12px 20px',
                    }} onClick={(e:any) => {e.stopPropagation(); updateHeaders ? updateHeaders(e, header.label, headers)  :  selectHeaders && selectHeaders(e,header.label)}}>
                        <button  className={`text-left font-medium flex justify-start items-center gap-3 w-full h-full`}>
                            <input type='checkbox' checked={header.isSelected} className='accent-secondary'/>
                            <p className='text-nowrap'>{header.label}</p>
                        </button>
                    </MenuItem>
                ))}
            </Menu>
        </div>

    )
}