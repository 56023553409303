import { Dispatch, SetStateAction } from "react";
import { FieldMappingType, FilterType, Header, Limit } from "../types/all.types";
import { FileType } from "../types/files.types";
import { Dataset } from "../types/datasets.types";
import { AuthUser, User } from "../types/users.types";
import { Model, SingleClassificationModel } from "../types/models.types";
import { Job } from "../types/jobs.types";
import { Prediction } from "../types/predictions.types";


export function capitalize(text:string) {
    return text.replace(/\b\w/g , function(m){ return m.toUpperCase(); } );
};

interface SizeType {
    op: string,
    size: string,
}

interface RecordType {
    op: string,
    quantity: string,
}

interface AccessType {
    authenticated: boolean,
    private: boolean,
}

export const isSizeType = (obj:any): obj is SizeType => {
    if (obj.size !== undefined) {
        return true;
    } else {
        return false;
    }
}

export const isRecordType = (obj:any): obj is RecordType => {
    if (obj.quantity !== undefined) {
        return true;
    } else {
        return false;
    }
}

export const isAccessType = (obj:any): obj is AccessType => {
    if (obj.authenticated !== undefined || obj.private !== undefined) {
        return true;
    } else {
        return false;
    }
}


export const getDateFromObjectId = (id: string) => {
    return new Date(parseInt(id.substring(0 , 8), 16) * 1000);
}

export const getTimezoneOffset = (date: string) => {
    const d = new Date(date);
    const offset = d.getTimezoneOffset();
    const offsetHours = -offset / 60;
    const sign = offsetHours >= 0 ? '+' : '-';
    const formattedOffset = `UTC${sign}${Math.abs(offsetHours)}`;
    return formattedOffset;
}




const makeComparison = (op: string, a: number, b: number) => {
    if (op === '<') {
        return a < b;
    } else if (op === '<=') {
        return a <= b;
    } else if (op === '>') {
        return a > b;
    } else if (op === '>=') {
        return a >= b;
    }
}


export const applyFilters = (filterObj: FilterType, items: any[], setFilteredItems: Dispatch<SetStateAction<any[]>>, searchInput?: string) => {
    const arr = items.filter((item) => {
        let doesNotSatisfy: string[] = [];
        Object.keys(filterObj).forEach((key) => {   
            const objKey: keyof object = key as keyof object;
            if (Array.isArray(filterObj[objKey])) {
                if (!(filterObj[objKey] as string[]).length) {
                    return;
                } else if ((filterObj[objKey] as string[]).includes(item[objKey])) {
                    return;
                } else {
                    doesNotSatisfy.push(objKey);
                } 
            } else if (isAccessType(filterObj[objKey])) {
                
                const trueValues = Object.keys(filterObj[objKey]).filter(k => ((filterObj[objKey] as AccessType)[k as keyof AccessType]) === true);
                if (trueValues.length) {
                    if (trueValues.includes(item[objKey])) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            } else if (isRecordType(filterObj[objKey])) {
                if (!filterObj[objKey]) {
                    return;
                } else if (!(filterObj[objKey] as RecordType).op || !(filterObj[objKey] as RecordType).quantity) {
                    return;
                } else {
                    const { op, quantity } = (filterObj[objKey] as RecordType);
                    const result = makeComparison(op, Number(item[objKey]), Number(quantity));
                    if (result) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            } else if (isSizeType(filterObj[objKey])) {
                if (!filterObj[objKey]) {
                    return;
                } else if (!(filterObj[objKey] as SizeType).op || !(filterObj[objKey] as SizeType).size) {
                    return;
                } else {
                    const { op, size } = (filterObj[objKey] as SizeType);
                    const result = makeComparison(op, item[objKey], Number(size));
                    if (result) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            }
        })
        if (doesNotSatisfy.length) {
            return false;
        } else {
            return true;
        }
    });



    if (searchInput !== undefined) {
        const searchedArr = arr.filter(f => getLabel(f.name).includes(searchInput));
        setFilteredItems(searchedArr);
    } else {
        setFilteredItems(arr);
    }

    
}


export const filterItems = (filterObj: FilterType, items: any[]) => {
    const arr = items.filter((item) => {
        let doesNotSatisfy: string[] = [];
        Object.keys(filterObj).forEach((key) => {   
            const objKey: keyof object = key as keyof object;
            if (Array.isArray(filterObj[objKey])) {
                if (!(filterObj[objKey] as string[]).length) {
                    return;
                } else if ((filterObj[objKey] as string[]).includes(item[objKey])) {
                    return;
                } else {
                    doesNotSatisfy.push(objKey);
                } 
            } else if (isAccessType(filterObj[objKey])) {
                
                const trueValues = Object.keys(filterObj[objKey]).filter(k => ((filterObj[objKey] as AccessType)[k as keyof AccessType]) === true);
                if (trueValues.length) {
                    if (trueValues.includes(item[objKey])) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            } else if (isRecordType(filterObj[objKey])) {
                if (!filterObj[objKey]) {
                    return;
                } else if (!(filterObj[objKey] as RecordType).op || !(filterObj[objKey] as RecordType).quantity) {
                    return;
                } else {
                    const { op, quantity } = (filterObj[objKey] as RecordType);
                    const result = makeComparison(op, Number(item[objKey]), Number(quantity));
                    if (result) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            } else if (isSizeType(filterObj[objKey])) {
                if (!filterObj[objKey]) {
                    return;
                } else if (!(filterObj[objKey] as SizeType).op || !(filterObj[objKey] as SizeType).size) {
                    return;
                } else {
                    const { op, size } = (filterObj[objKey] as SizeType);
                    const result = makeComparison(op, item[objKey], Number(size));
                    if (result) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            }
        })
        if (doesNotSatisfy.length) {
            return false;
        } else {
            return true;
        }
    });



    return arr;
    
};


export const sortByColumn = (value: string, headers: Header[], dynamicKeys: boolean, items: any[], setHeaders?: Dispatch<SetStateAction<Header[]>>, isModel?: boolean) => {
    
    const updatedHeaders = headers.map(h => {
        if (h.value === value) {
            return { ...h, isAscending: !h.isAscending }
        } else {
            return { ...h }
        }
    });

    setHeaders && setHeaders(updatedHeaders);
  

    const headerObj = setHeaders ? updatedHeaders.find(h => h.value === value) : headers.find(h => h.value === value);


    if (headerObj) {
        const orderedItems = changeItemOrder(headerObj.isAscending, value, dynamicKeys, items, isModel);

        return orderedItems;
    } else {
        return items;
    }
};


const changeItemOrder = (isAscending: boolean, label: string, dynamicKeys: boolean, allItems: any[], isModel?: boolean) => {
    let key = '';

    if (dynamicKeys) {
        key = label;
    } else {
        key = label.toLocaleLowerCase().split(' ').join('_');
    }

    
    const ascending = [...allItems].sort((a,b) => {
        let aVal: number | string | Date = 0;
        let bVal: number | string | Date = 0;

        if (key === 'date_created') {
            aVal = new Date(a[key as keyof object] as string);
            bVal = new Date(b[key as keyof object] as string);
        } else {

            if (isModel && key === 'name') {

                const aPrefix = a[key].slice(0,4);
                const bPrefix = b[key].slice(0,4);

                aVal = !isNaN(aPrefix) ? a[key].slice(5).toLocaleString().toLocaleLowerCase() : a[key].toLocaleString().toLocaleLowerCase();
                bVal = !isNaN(bPrefix) ? b[key].slice(5).toLocaleString().toLocaleLowerCase() : b[key].toLocaleString().toLocaleLowerCase();


            } else {
                let numA = parseFloat(a[key]);
                let numB = parseFloat(b[key]);
    
                if (isNaN(numA) || isNaN(numB)) {
    
                    aVal = a[key] === undefined ? '' : a[key].toLocaleString().toLocaleLowerCase();
                    bVal = b[key] === undefined ? '' : b[key].toLocaleString().toLocaleLowerCase();
                } else {
                    
                    aVal = numA;
                    bVal = numB;
                }

            }
            
        }

        if (aVal < bVal) {
            return -1;
        } else if (aVal > bVal) {
            return 1;
        } else {
            return 0;
        }


        
    })

    const descending = [...allItems].sort((a,b) => {
        let aVal: number | string | Date = 0;
        let bVal: number | string | Date = 0;

        if (key === 'date_created') {
            aVal = new Date(a[key as keyof object] as string);
            bVal = new Date(b[key as keyof object] as string);
        } else {

            if (isModel && key === 'name') {

                const aPrefix = a[key].slice(0,4);
                const bPrefix = b[key].slice(0,4);

                aVal = !isNaN(aPrefix) ? a[key].slice(5).toLocaleString().toLocaleLowerCase() : a[key].toLocaleString().toLocaleLowerCase();
                bVal = !isNaN(bPrefix) ? b[key].slice(5).toLocaleString().toLocaleLowerCase() : b[key].toLocaleString().toLocaleLowerCase();

            } else {

                let numA = parseFloat(a[key]);
                let numB = parseFloat(b[key]);
    
                if (isNaN(numA) || isNaN(numB)) {
    
                    aVal = a[key] === undefined ? '' : a[key].toLocaleString().toLocaleLowerCase();
                    bVal = b[key] === undefined ? '' : b[key].toLocaleString().toLocaleLowerCase();
                } else {
                    aVal = numA;
                    bVal = numB;
                }

            }
        }

        if (aVal > bVal) {
            return -1;
        } else if (aVal < bVal) {
            return 1;
        } else {
            return 0;
        }
        
        
    });
    

    if (isAscending) {
        return ascending;
        
       
    } else {
        return descending;
        
    }
};


export const searchItems = (searchInput: string, items: any[], identifier?: string) => {
    let searchedArr: any[] = [];

    if (identifier) {
        searchedArr = items.filter(f => (f[identifier]).toLocaleString().toLocaleLowerCase().includes(searchInput.toString().toLocaleLowerCase()));
    } else {
        searchedArr = items.filter(f => (!f.name ? '' : f.name).toLocaleString().toLocaleLowerCase().includes(searchInput.toString().toLocaleLowerCase()));
    }

    return searchedArr;
};



export const cleanFileName = (name: string) => {
    return name.replaceAll('?', '_').replaceAll('/','_').replaceAll('%', '_percent_').replaceAll('#', '_').trim()
}

export const getDatedFilename = (prefix: string, ext: string) => {
    const now = new Date();
    return prefix + '-' + now.toISOString().substring(0, 19) + '.' + ext;
};

export const formatBytes = (bytes:number) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export const download = (filename: string, data: any) => {
    let opt;
    const format = filename.split('.')[1].toLowerCase();
    if (format === 'xlsx') opt = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    else if (format === 'csv') opt = { type: 'text/csv' };
    else if (format === 'sdf') opt = { type: 'chemical/x-mdl-sdfile' };
    else if (format === 'zip') opt = { type: 'application/zip' };
    else opt = { type: 'text/plain' };

    console.log(filename, format)
  
    let blob = new Blob([data], opt); // pass a useful mime type here
    let url = URL.createObjectURL(blob);

    console.log(url)
  
    let element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

  export const filesExists = (id: string, files: FileType[]) => {
    const file = files.find(f => f._id.$oid === id);
    if (file) {
        return `/files/${id}`
    } else {
        return undefined;
    }
};

export const modelExists = (name: string, models: Model[]) => {
    const model = models.find(m => m.name === name);
    if (model) {
        return `/models/${name}`
    } else {
        return undefined;
    }
}

export const getFileName = (id: string, files: FileType[]) => {
    const file = files.find(f => f._id.$oid === id);
    if (file) {
        return file.name;
    } else {
        return 'No file name'
    }
};

export const dsExists = (id: string, datasets: Dataset[]) => {
    const ds = datasets.find(f => f._id.$oid === id);
    if (ds) {
        return `/datasets/${id}`
    } else {
        return undefined;
    }
};

export const isAdmin = (auth: AuthUser) => {
    const adminField = auth.privileges.find(p => p.value === 'admin');
    if (adminField) {
        return true
    } else {
        return false
    }
}

export const isOwner = (auth: AuthUser, ownerID: string) => {
    if (auth.user._id.$oid === ownerID) {
        return true;
    } else {
        return false;
    }
}

export const getOwnerName = (ownerID: string, users: User[]) => {
    const user = users.find(u => u._id.$oid === ownerID);
    if (user) {
        return user.username;
    } else {
        return 'No owner'
    }
}

export const getThresholdValue = (numArr: number[], threshold: number) => {
    if (numArr === undefined) {
        return { color: '#ffffff', label: 'N/A' }
    }
    const inactive = numArr[0];
    const active = numArr[1];

    if (inactive < threshold && active < threshold) {
        return { color: '#e0e0e0', label: 'Out of Domain' }
    } else if (inactive > threshold && active > threshold) {
        return { color: '#FFC061', label: 'Inconclusive' }
    } else if (inactive > threshold && active < threshold) {
        return { color: '#FF8484', label: 'Inactive' }
    } else {
        return { color: '#7BC960', label: 'Active' }
    }
}

const getLabel = (label: any) => {
    if (label && typeof label === 'string') {
        return label.toLocaleLowerCase()
    } else if (label) {
        return label.toLocaleString();
    } else {
        return '';
    }
}

export const searchByIdentifier = (records: any[], headers: Header[], fields_mapping: FieldMappingType[], searchInput: string) => {
    const identifierObj = headers.find(header => header.isRequired && header.label !== "Structure");
    if (identifierObj && fields_mapping.length) {

        return records.filter(f => getLabel(f[identifierObj.label]).includes(searchInput))
        
    } else {
        return records;
    }
}

export const getIdentifier = (nonEmptyKeys: string[], fields_mapping: FieldMappingType[]) => {

    let identifier = '';

    const nameField = fields_mapping.find(f => f.type === 'chem-name');
    const idField = fields_mapping.find(f => f.type === 'chem-id');

  
    if (!fields_mapping.length || (!nameField && !idField)) {
        identifier = ''

    } else if (nameField && nameField.name) {
        identifier = nameField.name;
    } else if (idField && idField.name) {
        identifier = idField.name
    } else {
        identifier = ''
    }
    // } else if (!nonEmptyKeys.includes(nameField!.name) || !nameField) {
    //     if (nonEmptyKeys.includes(idField!.name)) {
    //       identifier = idField!.name;
    //     } else {
    //       identifier = ''
    //     }
    // } else if (!nonEmptyKeys.includes(idField!.name) || !idField) {
    //     if (nonEmptyKeys.includes(nameField.name)) {
    //       identifier = nameField.name;
    //     } else {
    //       identifier = ''
    //     }
    // } else if (nameField && idField) {
    //     if (nonEmptyKeys.includes(nameField.name)) {
    //         identifier = nameField.name
    //     } else if (nonEmptyKeys.includes(idField.name)) {
    //         identifier = idField.name
    //     } else {
    //         identifier = ''
    //     }
    // }

    return identifier;
} 

export const getStringValue = (value: any) => {
    if (typeof value === 'string') {
        return value.split(' ').join('_'); 
    } else {
        return value;
    }
}

export const orderByDate = (items: any[]) => {
    const ordered = items.sort((a,b) => {
        if ((new Date(a.date_created as string)) > (new Date(b.date_created as string))) {
            return -1
        } else if ((new Date(a.date_created as string)) < (new Date(b.date_created as string))) {
            return 1;
        } else {
            return 0;
        }
    });

    return ordered;
};

export const convertDecimal = (value: number | string) => {
    return (Math.round(Number(value) * 100) / 100 as number).toFixed(2);
}

const convertPercentage = (min: number, max: number, decimal: number) => {
    const difference = Math.abs(max - min);
    const ratio = difference * decimal;
    const mark = min + ratio;
    return mark;
};

export const getDomainColor = (limitsObj: Limit, value: number) => {
    const { min, max } = limitsObj;

    if (value <= convertPercentage(min, max, 0.3)) {
        return {
            bg: '#B5E0D7',
            text: '#35424A'
        }
    } else if (value > convertPercentage(min, max, 0.3) && value <= convertPercentage(min, max, 0.49) ) {
        return {
            bg: '#79BFC9',
            text: '#35424A'
        }
    } else if (value > convertPercentage(min, max, 0.49) && value <= convertPercentage(min, max, 0.69)) {
        return {
            bg: '#4EA7BC',
            text: '#35424A'
        }
    } else if (value > convertPercentage(min, max, 0.69) && value <= convertPercentage(min, max, 0.89)) {
        return {
            bg: '#3582A4',
            text: '#FFFFFF'
        }
    } else if (value > convertPercentage(min, max, 0.89)) {
        return {
            bg: '#2E5F8A',
            text: '#FFFFFF'
        }
    } else {
        return {
            bg: '#E0E0E0',
            text: '#35424A'
        }
    }
};

export const getPredictionColor = (limitsObj: Limit, value: number) => {
    const { min, max, isReversed } = limitsObj;

    if (value <= convertPercentage(min, max, 0.3)) {
        if (isReversed) {
            return '#7BC960';
        } else {
            return '#FF8484'
        }
    } else if (value > convertPercentage(min, max, 0.3) && value <= convertPercentage(min, max, 0.49) ) {
        if (isReversed) {
            return '#C7DB78';
        } else {
            return '#FFA461'
        }
    } else if (value > convertPercentage(min, max, 0.49) && value <= convertPercentage(min, max, 0.69)) {
        return '#FFF278';
    } else if (value > convertPercentage(min, max, 0.69) && value <= convertPercentage(min, max, 0.89)) {
        if (isReversed) {
            return '#FFA461';
        } else {
            return '#C7DB78';
        }
    } else if (value > convertPercentage(min, max, 0.89)) {
        if (isReversed) {
            return '#FF8484'
        } else {
            return '#7BC960';
        }
    } else {
        return '#E0E0E0';
    }
}


export const getStatusColor = (status: string) => {
    switch(status) {
        case "Pending": 
            return '#FFF278';
        
        case "Rescheduled":
            return '#FFF278';
            
        case "Running":
            return '#FFC061';

        case "Done":
            return '#C7DB78';

        case "Failed":
            return '#FF8484';
        
        case "train":
            return '#B3D5F5';

        case 'predict': 
            return '#D7EEBF';

        case 'ad_domain': 
            return '#E8BFF3';

        default:
            return '#EEEEEE';
    }
};

export const getGroupStatusColor = (associatedItems?: any[]) => {
    if (associatedItems) {
        let statusCount: any = {};

        associatedItems.forEach(item => {
            const status = item.status;
            statusCount[status] = statusCount[status] ? statusCount[status]+1 : 1;
        });

        let groupStatus = "";

        const statusKeys = Object.keys(statusCount);

        if (statusKeys.includes('Running')) {
            groupStatus = 'Running';
        } else if (statusKeys.includes('Pending')) {
            groupStatus = 'Pending';
        } else if (statusKeys.includes('Failed')) {
            groupStatus = 'Failed';
        } else {
            groupStatus = 'Done';
        };

        return {
            color: getStatusColor(groupStatus),
            status: groupStatus,
        }
    } else {
        return {
            color: '#FFF278',
            status: 'Pending'
        }
    }
};


export const fileExists = (jobs: Job[], datasets: Dataset[], models: Model[], results: Prediction[], value: string, id: string) => {
    const job = jobs.find(j => j._id.$oid === id);
    if (job) {
        if (value === 'dataset') {
            const dsID = job.params.ds_id;
            const ds = datasets.find(d => d._id.$oid === dsID);
            if (ds) {
                return true;
            } else {
                return false;
            }
        } else if (value === 'result') {
            if (job.params.model_name) {
                const resID = job.params.model_name;
                const mod = models.find(m => m.name === resID);
                if (mod) {
                    return true
                } else {
                    return false
                }
            } else if (job.params.rs_ids) {
                const resID = job.params.rs_ids[0];
                const res = results.find(r => r._id.$oid === resID);
                if (res) {
                    return true;
                } else {
                    return false
                }
            } else if (job.params.rs_id) {
                const resID = job.params.rs_id;
                const res = results.find(r => r._id.$oid === resID);
                if (res) {
                    return true;
                } else {
                    return false;
                }
            }  else {
                return false;
            }              
        } else {
            return false;
        }
    } else {
        return false;
    }
    
}

export const goToDataset = (jobs: Job[], id: string) => {
    const job = jobs.find(j => j._id.$oid === id);
    if (job) {
        return `/datasets/${job.params.ds_id}`;
    } else {
        return '';
    }
}

export const gotToResult = (jobs: Job[] ,id:string, type: string) => {
    const job = jobs.find(j => j._id.$oid === id);
    if (job) {
        if (type === 'train') {
            if (job.params.model_name) {
                return `/models/${job.params.model_name}`;
            } else {
                return '';
            }
        } else if (type === 'predict') {
            if (job.params.rs_ids) {
                return `/predictions/${job.params.rs_ids[0]}`;
            } else {
                return '';
            }
        } else if (type === 'visualize_resultset') {
            if (job.params.rs_id) {
                return `/predictions/${job.params.rs_id}`;
            } else {
                return '';
            }
        } else {
            return `/datasets/${job.params.ds_id}`;
        }
    } else {
        return '';
    }
}

export const hist_data = (method: SingleClassificationModel) => {
    if (!method.y_true || !method.y_pred || !method.y_prob) {
  
      return null;
  
    } else {
  
      let pred_vals = [];
      let true_vals = [];
      let i = 0;
      for (let val of method.y_true) {
        if (val === 0) {
          pred_vals.push(method.y_prob[i])
        }
        i++;
      }
      let j = 0;
      for (let val of method.y_true) {
        if (val === 1) {
          true_vals.push(method.y_prob[j])
        }
        j++;
      }
  
      var trace1 = {
        x: pred_vals,
        name: 'Predicted Values',
        autobinx: false, 
        histnorm: "count", 
        marker: {
          color: "rgb(219, 30, 58, .7)", 
          line: {
            color:  "rgb(219, 30, 58, 1)", 
            width: 1
          }
        },  
        opacity: 0.5, 
        type: "histogram", 
        xbins: {
          end: 1, 
          size: 0.025, 
          start: 0
          }
        };
  
      var trace2 = {
        x: true_vals,
        name: "True Values", 
        histnorm: "count",
        autobinx: false, 
        marker: {
                color: "rgb(94, 146, 219, .7)",
                line: {
                  color:  "rgb(94, 146, 219, 1)", 
                  width: 1
          } 
            }, 
        opacity: 0.5, 
        type: "histogram", 
        xbins: { 
          end: 1, 
          size: 0.025, 
          start: 0
          }
        };
  
      return [trace1, trace2]
  
    }
      
    }

    export const rgbColors = {
        bnb: 'rgba(44, 114, 142, 0.1)',
        DL: 'rgba(33, 144, 140, 0.1)',
        ada: '#rgba(59, 82, 139, 0.1)',
        knn: 'rgba(39, 173, 129, 0.1)',
        lreg: 'rgba(93, 200, 99, 0.1)',
        rf: 'rgba(170, 220, 50, 0.1)',
        svc: 'rgba(253, 231, 37, 0.1)',
        xgb: 'rgba(255, 197, 111, 0.1)',
    }
    
    export const trColors = {
        bnb: '#2C728E',
        DL: '#21908C',
        ada: '#3B528B',
        knn: '#27AD81',
        lreg: '#5DC863',
        rf: '#AADC32',
        svc: '#FDE725',
        xgb: '#FFC56F'
    }
    
    export const regTrColors = {
        r2: '#DB5C68',
        mae: '#B8328A',
        rmse: '#5802A3',
    }
