import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import API from '../../api';

import { SingleResultset } from "../../types/predictions.types";
import { SideInfo, Limit, Header, FieldMappingType } from "../../types/all.types";

import { selectApplication, selectAuth } from "../../store/selectors/auth.selector";
import { selectFiles } from "../../store/selectors/files.selectors";
import { convertDecimal, getFileName, filesExists, isAdmin, isOwner, download, modelExists, searchItems, sortByColumn, getThresholdValue } from "../../data/functions";
import { updateExistingResult, deleteExistingResults } from "../../store/slices/predictions.slice";
import { selectModels } from "../../store/selectors/models.selector";
import { allMethods, classificationMethodsArr, regressionMethodsArr } from "../../data/models";



const getModeColor = (modeLabel: string)  => {
    if (modeLabel === 'Out of Domain') {
        return { color: '#BEBEBE', label: 'Out of Domain' }
    } else if (modeLabel === 'Inconclusive') {
        return { color: '#FFC061', label: 'Inconclusive' }
    } else if (modeLabel === 'Inactive') {
        return { color: '#FF8484', label: 'Inactive' }
    } else {
        return { color: '#7BC960', label: 'Active' }
    }
}

export const getMode = (item:any, threshold: number) => {
    let arr: string[] = [];
    let frequency: {[key:string]: number} = {};

    Object.keys(item).forEach(key => {
        const m = key.split('/')[1];
        if (m !== 'ad-score' && m !== 'duplicate' && m !== undefined) {
            const { label } = getThresholdValue(item[key], threshold);
            arr.push(label);
        }
        
    });

    arr.forEach(field => {
        const label = Object.keys(frequency).find(key => key === field);
        if (!label) {
            frequency[field as keyof object] = 1;
        } else {
            frequency[label as keyof object] = frequency[label as keyof object]+1;
        }
    })


    let maxValue = 0;
    let maxKey = null;
    let duplicates: string[] = [];

    for (const [key, value] of Object.entries(frequency)) {
        if (value > maxValue) {
            maxValue = value;
            maxKey = key;
        }
    }

    for (const [key, value] of Object.entries(frequency)) {
        if (value === maxValue) {
            duplicates.push(key);
        }
    };

    if (maxKey && duplicates.length <= 1) {
        return getModeColor(maxKey);
    } else if (maxKey && duplicates.length > 1) {
        if (duplicates.includes('Out of Domain')) {
            return { color: '#BEBEBE', label: 'Out of Domain' }
        } else if (duplicates.includes('Inconclusive')) {
            return { color: '#FFC061', label: 'Inconclusive' }
        } else if (duplicates.includes('Inactive')) {
            return { color: '#FF8484', label: 'Inactive' }
        } else {
            return { color: '#7BC960', label: 'Active' }
        }
    } else {
        return { color: '#BEBEBE', label: 'Out of Domain' }
    }
}

const median = (scores: any[]) => {
    scores.sort()
    let midpoint = scores[Math.floor(scores.length/2)]
    if (scores.length % 2 === 1) {
        return midpoint
    }
    else {
        let lower_midpoint = scores[Math.floor(scores.length/2)-1]
        return ( (midpoint + lower_midpoint) / 2 )
    }
}

const useSingleResult = ({ id, setPopupMessage }: {
    id?: string,
    setPopupMessage?: (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ singleResult, setSingleResult ] = useState<SingleResultset | null>(null);
    const [ orderedRecords, setOrderedRecords ] = useState<any[]>([]);
    const [ modifiedRecords, setModifiedRecords ] = useState<any[]>([]);
    const [ info, setInfo ] = useState<SideInfo[]>([]);
    const [ activityColumn, setActivityColumn ] = useState('');
    const [ infoCells, setInfoCells ] = useState(0);
    const [ predictionCells, setPredictionCells ] = useState(0);
    const [ limits, setLimits ] = useState<Limit[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ headers, setHeaders ] = useState<Header[]>([]);
    const [ plotData, setPlotData ] = useState<any[]>([]);
    const [ isConformal, setIsConformal ] = useState(false);

    const [ modelNames, setModelNames ] = useState<string[]>([]);
    const [ tab, setTab ] = useState('');
    const application = useSelector(selectApplication);
    const [ itemIdentifier, setItemIdentifier ] = useState('');

    const files = useSelector(selectFiles);
    const models = useSelector(selectModels);
    const auth = useSelector(selectAuth);


    const formatBoxPlot = (records: any[], modelName: string) => {
        if (singleResult) {
            
            let vals = []
            let method_list = []
            let molecule_name = null
            let molecule_id = null

            for (let field of singleResult.fields_mapping) {
                if (field.type === 'predicted-value') {
                    const name = field.name.split('/')[0];
                    if (name === modelName) {
                        method_list.push(field.name)
                    }
                }
                else if (field.type === 'chem-id') {
                    molecule_id = field.name
                }
                else if (field.type === 'chem-name') {
                    molecule_name = field.name
                }
            }


            for (let molecule of records) {
                let mol_object: any = {}

                mol_object['name'] = 'Molecule' + molecule.ord
                
                mol_object['text'] = molecule.molecule['$oid']
                mol_object['type'] = 'box'
                mol_object['boxmean'] = true

                mol_object['hoverinfo'] = 'x'
                mol_object['hovertext'] = ''
                
                let method_preds = []

                for (let meth of method_list) {
                    if (!meth.includes('avg')) {
                        method_preds.push(molecule.fields[meth])
                    }
                }

                let med = median(method_preds)
                mol_object['sort_by'] = med

                let color_pallette = ['rgb(94,123,54)','rgb(148,186,51)','rgb(255,215,45)','rgb(249,160,41)','rgb(219,94,43)','rgb(216,29,56)']
                if (med > 0.9) {
                    mol_object['marker'] = {
                        color: color_pallette[0],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.8) {
                    mol_object['marker'] = {
                        color: color_pallette[1],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.7) {
                    mol_object['marker'] = {
                        color: color_pallette[2],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.6) {
                    mol_object['marker'] = {
                        color: color_pallette[3],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.5) {
                    mol_object['marker'] = {
                        color: color_pallette[4],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else {
                    mol_object['marker'] = {
                        color: color_pallette[5],
                        size: 3,
                        opacity: 0.8
                    }          
                }
                mol_object['x'] = method_preds

                vals.push(mol_object)          
            }

            vals.sort((a, b) => (a.sort_by > b.sort_by) ? 1 : -1);
            setPlotData(vals);
            
        }
    }

    const formatConfData = (records:any[]) => {
        if (records.length > 0) {
            let methodNames: string[] = [];
            Object.keys(records[0].fields).forEach(name => {
                const m = name.split('/')[1];
                methodNames.push(m);
            });
            const sortedMethods = methodNames.sort((a,b) => {
                if (a.toLocaleLowerCase() < b.toLocaleLowerCase()) {
                    return -1;
                } else if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            }).filter(m => m !== 'ad-score' && m !== 'duplicate' && m !== undefined);
            const methods = ['mode'].concat(sortedMethods).flat();

            if (methods.length > 0) {
                const alphaScores = [0.025, 0.05, 0.075, 0.1, 0.125, 0.15, 0.175, 0.2, 0.225, 0.25, 0.275, 0.3, 0.325, 0.35, 0.375, 0.4, 0.425, 0.45, 0.475, 0.5, 0.525, 0.55, 0.575, 0.6, 0.625, 0.65, 0.675, 0.7, 0.725, 0.75, 0.775, 0.8, 0.825, 0.85, 0.875, 0.9, 0.925, 0.95, 0.975];
                let plots: {data: Plotly.Data[], layout: Partial<Plotly.Layout>, methods: string[]}[] = [];
                methods.forEach(method => {
                    let traces: Plotly.Data[] = [];
                    let fields: number[][] = [];
                    let frequencies: {
                        OOD: number,
                        INC: number,
                        INA: number,
                        ACT: number,
                    }[] = [];
    
                    if (method !== 'mode') {
                        records.forEach(record => {
                            const matchingMethod = Object.keys(record.fields).find(field => field.includes(method));
                            if (matchingMethod) {
                                const value = record.fields[matchingMethod];
                                fields.push(value);
                            }
                        });
    
                        alphaScores.forEach(score => {
                            let OOD = 0;
                            let INC = 0;
                            let INA = 0;
                            let ACT = 0;
        
                            fields.forEach(field => {
                                const { label } = getThresholdValue(field, score);
                                if (label === 'Out of Domain') {
                                    OOD += 1;
                                } else if (label === 'Inconclusive') {
                                    INC += 1;
                                } else if (label === 'Inactive') {
                                    INA += 1;
                                } else if (label === 'Active') {
                                    ACT += 1;
                                }
                            });
                            
        
                            frequencies.push({OOD, INC, INA, ACT});
        
                        });
    
                    } else {
    
                        alphaScores.forEach(score => {
                            let OOD = 0;
                            let INC = 0;
                            let INA = 0;
                            let ACT = 0;
                            records.forEach(record => {
                                const { label } = getMode(record.fields, score);
                                if (label === 'Out of Domain') {
                                    OOD += 1;
                                } else if (label === 'Inconclusive') {
                                    INC += 1;
                                } else if (label === 'Inactive') {
                                    INA += 1;
                                } else if (label === 'Active') {
                                    ACT += 1;
                                }
                            });
    
                            frequencies.push({OOD, INC, INA, ACT});
                        })
                
                    };
    
    
                    traces = [
                        {
                            x: alphaScores,
                            y: frequencies.map(f => f.OOD),
                            name: 'Out of Domain',
                            type: 'bar',
                            legendgroup: 'group1',
                            marker: {
                                color: 'rgba(190, 190, 190, 0.6)',
                                line: {
                                    color: 'rgba(190, 190, 190, 1)',
                                    width: 2,
                                }
                            },
                            
                            
                            
                        },
                        {
                            x: alphaScores,
                            y: frequencies.map(f => f.OOD),
                            name: 'Out of Domain',
                            type: 'scatter',
                            mode: 'lines',
                            legendgroup: 'group1',
                            line: {
                                color: 'rgba(190, 190, 190, 1)',
                                smoothing: 0.7,
                                shape: 'spline',
                                width: 3,
                            },
                        },
                        {
                            x: alphaScores,
                            y: frequencies.map(f => f.INC),
                            name: 'Inconclusive',
                            type: 'bar',
                            legendgroup: 'group2',
                            marker: {
                                color: 'rgba(255 192 97, 0.6)',
                                line: {
                                    color: 'rgba(255 192 97, 1)',
                                    width: 2,
                                }
                                
                            }
                        },
                        {
                            x: alphaScores,
                            y: frequencies.map(f => f.INC),
                            name: 'Inconclusive',
                            type: 'scatter',
                            mode: 'lines',
                            legendgroup: 'group2',
                            line: {
                                color: 'rgba(255 192 97, 1)',
                                smoothing: 0.7,
                                shape: 'spline',
                                width: 3,
                            }
                        },
                        {
                            x: alphaScores,
                            y: frequencies.map(f => f.INA),
                            name: 'Inactive',
                            type: 'bar',
                            legendgroup: 'group3',
                            marker: {
                                color: 'rgba(255 132 132, 0.6)',
                                line: {
                                    color: 'rgba(255 132 132, 1)',
                                    width: 2,
                                }
                                
                            }
                        },
                        {
                            x: alphaScores,
                            y: frequencies.map(f => f.INA),
                            name: 'Inactive',
                            type: 'scatter',
                            mode: 'lines',
                            legendgroup: 'group3',
                            line: {
                                color: 'rgba(255 132 132, 1)',
                                smoothing: 0.7,
                                shape: 'spline',
                                width: 3,
                            }
                        },
                        {
                            x: alphaScores,
                            y: frequencies.map(f => f.ACT),
                            name: 'Active',
                            type: 'bar',
                            legendgroup: 'group4',
                            marker: {
                                color: 'rgba(123 201 96, 0.6)',
                                line: {
                                    color: 'rgba(123 201 96, 1)',
                                    width: 2,
                                }
                                
                            }
                        },
                        {
                            x: alphaScores,
                            y: frequencies.map(f => f.ACT),
                            name: 'Active',
                            type: 'scatter',
                            mode: 'lines',
                            legendgroup: 'group4',
                            line: {
                                color: 'rgba(123 201 96, 1)',
                                smoothing: 0.7,
                                shape: 'spline',
                                width: 3,
                            }
                        }
                    ];
    
                    const layout: Partial<Plotly.Layout> = {
                        barmode: 'overlay',
                        title: method.toLocaleUpperCase(),
                        xaxis: {
                            title: 'Alpha Score',
                            
                        },
                        yaxis: {
                            title: 'Frequency',
                            range: [0, records.length]
                        },
                        bargap: 0,
                        
                    };
    
                    plots.push({data: traces, layout, methods});
                });
    
                setPlotData(plots);
            }
        }
    };


    const reorderHeaders = (headers: Header[], itemsWithIndices: {[key: string]: number}) => {
        let itemsToPlace: Header[] = [];
        let remainingItems: Header[] = [];
        Object.keys(itemsWithIndices).forEach(item => {
            const existingItem = headers.find(h => h.label === item);
            if (existingItem) {
                itemsToPlace.push(existingItem);
            }
        });

        headers.forEach(h => {
            if (!Object.keys(itemsWithIndices).includes(h.label)) {
                remainingItems.push(h);
            }
        });

        remainingItems.sort((a,b) => {
            if (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) {
                return -1;
            } else if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });

        let result = new Array(headers.length).fill(null);

        itemsToPlace.forEach(item => {
            const position = itemsWithIndices[item.label];
            result[position] = item;
        });

        const orderedItems = result.filter(item => item !== null);
        return orderedItems.concat(remainingItems);

        


    }


    const setupSingleResult = (singleResult: SingleResultset, selectedTab?: string) => {
        if (singleResult) {
            setRoutes([
                {
                    label: 'Predictions',
                    path: 'predictions',
                },
                {
                    label: singleResult ? singleResult.name : 'Single Resultset',
                    path: id ? `predictions/${id}` : '',
                }
            ]);

            let names: string[] = [];

            singleResult.fields_mapping.forEach(field => {
                if (field.type === 'predicted-value') {
                    names.push(field.name.split('/')[0]);
                }
            });

            names = Array.from(new Set(names));

            setModelNames(names);

            let activityName: string = '';

            if (selectedTab) {
                activityName = selectedTab;
            } else {
                activityName = names[0];
                setTab(names[0]);
            }

            setActivityColumn(activityName);

            // const activityField = singleResult.fields_mapping.find(field => field.type === 'predicted-value');
            // if (activityField) setActivityColumn(activityField.name.split('/')[0]);

            const emptyKeys = Object.keys(singleResult.records[0].fields).filter(key => singleResult.records[0].fields[key] === undefined);
            const all = singleResult.records.map(r => ({ fields: r.fields, molecule: r.molecule.$oid, ord: r.ord }));

            let identifier: FieldMappingType | null = {name: '', type: '', op: null, value: null};
            const nameField = singleResult.fields_mapping.find(f => f.type === 'chem-name')!;
            const idField = singleResult.fields_mapping.find(f => f.type === 'chem-id')!;

            let filteredArr: FieldMappingType[] = [];
            const filteredFields = Object.keys(singleResult.records[0].fields).filter(f => !emptyKeys.includes(f));
            filteredFields.forEach(f => {
                const mappingObj = singleResult.fields_mapping.find(map => map.name === f);
                if (mappingObj) {
                    filteredArr.push(mappingObj)
                }
            })

            if (!singleResult.fields_mapping.length || (!nameField && !idField)) {
                identifier = null;
            } else {
                if (!nameField) {
                    identifier = idField;
                } else {
                    identifier = nameField;
                }
            }

            let orderedFields: FieldMappingType[] = [];

            if (identifier) {
                setItemIdentifier(identifier.name);
                orderedFields = [identifier].concat(filteredArr.filter(f => f.name !== identifier!.name))
            } else {
                orderedFields = filteredArr;
            }


            let moleculeInfoHeaders: Header[] = [{
                label: 'Structure',
                value: 'Structure',
                isAscending: true,
                isRequired: true,
                isSelected: true,
                isSortable: false,
            }];

            let predictionHeaders: Header[] = [];
    
            orderedFields.forEach(field => {
                if (field.type !== 'predicted-value' && field.type !== 'ad-score') {
                    moleculeInfoHeaders.push({
                        label: field.name,
                        value: field.name,
                        isAscending: true,
                        isRequired: identifier && field.name === identifier.name ? true : false,
                        isSelected: true,
                        isSortable: true,
                    })
                } else {
                    const splitName = field.name.split('/');
                    const model = splitName[0];
                    if (model === activityName) {
                        predictionHeaders.push({
                            label: splitName[1],
                            value: splitName[1],
                            isAscending: true,
                            isRequired: false,
                            isSelected: true,
                            isSortable: singleResult.conf_pred && allMethods.includes(splitName[1]) ? false : true,
                        })
                    }
                }
            });

            if (singleResult.conf_pred) {
                predictionHeaders.push({
                    label: 'mode',
                    value: 'mode',
                    isAscending: true,
                    isRequired: false,
                    isSelected: true,
                    isSortable: false,
                })
            }

            setInfoCells(moleculeInfoHeaders.length);
            setPredictionCells(predictionHeaders.length);
            

            const orderedHeaders = reorderHeaders(predictionHeaders, {
                duplicate: 0,
                'ad-score': 1,
                mode: 2,
                min: 3,
                max: 4, 
                avg: 5,
            });


            const allHeaders = moleculeInfoHeaders.concat(orderedHeaders);

            setHeaders(allHeaders);

            let records: any[] = [];
            let recordsWithFields: any[] = [];
            let allLimits: Limit[] = [];
            all.forEach(r => {
                let obj = {};
                let fields: any = {};
                Object.keys(r.fields).forEach((field,i) => {
                    let key = '';
                    if (field.includes(activityName)) {
                        key = (field.split('/'))[1];
                        let isML = key !== 'duplicate' && key !== 'ad-score' && key !== undefined;
                        allLimits.push({
                            value: (field.split('/'))[1],
                            min: 0,
                            max: 1,
                            isReversed: application === 'megatox' && isML ? true : false,
                        })
                        if (key !== 'duplicate' && key !== 'ad-score' && key !== undefined) {
                            fields[field as keyof object] = r.fields[field];
                        }
                        
                    } else {
                        key = field;
                    }
                    obj = { ...obj, [key]: (!isNaN(Number(r.fields[field]))) ? convertDecimal(Number(r.fields[field])) : r.fields[field] }
                })
                records.push({...obj, id: r.molecule});
                recordsWithFields.push({...r, fields});
            });



            setLimits(allLimits)
            
            let sortedArr: any[] = [];
            records.forEach(r => {
                let obj = {};
                allHeaders.forEach(h => {
                    if (h.label !== 'Structure') {
                        obj = {...obj, [h.label]: r[h.label]}
                    } else {
                        obj = {...obj, [h.label]: ''}
                    }
                })
                sortedArr.push({...obj, id: r.id});
            })

            for (const key of Object.keys(recordsWithFields[0].fields)) {
                const method = key.split('/')[1];
                if (method !== undefined && (classificationMethodsArr.includes(method) || regressionMethodsArr.includes(method))) {
                    const value = recordsWithFields[0].fields[key];
                    if (value.length === 2) {
                        setIsConformal(true);
                        formatConfData(recordsWithFields);
                        break;
                    } else {
                        setIsConformal(false);
                        formatBoxPlot(recordsWithFields, activityName);
                        break;
                    }
                }
            }
            

            setOrderedRecords(sortedArr);
            setModifiedRecords(sortedArr);

            setInfo([
                {
                    label: 'Raw File Name',
                    value: getFileName(singleResult.files[0].$oid, files),
                    path: filesExists(singleResult.files[0].$oid, files),
                },
                {
                    label: 'Model Name',
                    value: names.length ? names[0] : '',
                    path: names.length ? modelExists(names[0], models) : undefined,
                },  
                {
                    label: 'Result Description',
                    value: !singleResult.metadata.description ? 'No description' : singleResult.metadata.description,
                    key: 'description',
                    type: 'text-area',
                },
                // {
                //     label: 'Project',
                //     value: !singleResult.metadata.project ? 'No project' :  singleResult.metadata.project,
                //     key: 'project',
                //     type: 'creatable-select',
                // }, 
                {
                    label: 'Measurement Type',
                    value: !singleResult.metadata.measurementType ? 'No measurement type' : singleResult.metadata.measurementType,
                    key: 'measurementType',
                    type: 'text',
                },
                {
                    label: 'Organism',
                    value: !singleResult.metadata.organism ? 'No organism' : singleResult.metadata.organism,
                    key: 'organism',
                    type: 'text',
                },
                {
                    label: 'Target',
                    value: !singleResult.metadata.target ? 'No target' : singleResult.metadata.target,
                    key: 'target',
                    type: 'text',
                },
                {
                    label: 'Created By',
                    value: singleResult.acl ? singleResult.acl.owner : '',
                    key: auth && (isAdmin(auth) || isOwner(auth, singleResult.acl.owner)) ? 'owner' : undefined,
                    type: 'select',
                },
                {
                    label: 'Access Type',
                    value: singleResult.acl ? singleResult.acl.access : '',
                    key: auth && (isAdmin(auth) || isOwner(auth, singleResult.acl.owner)) ? 'access' : undefined,
                    type: 'select',
                },
            ]);

        }
    }


    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            if (id && authKey) {
                const fetchResults = async () => {
                    setIsLoading(true);
    
                    try {
    
                        const response = await API.get(`resultsets/${id}`, { headers: { 'X-Auth': authKey } });
                        const data: SingleResultset = await response.data;
                        
                        setSingleResult(data);
    
                    } catch (err:any) {
    
                        console.log(err);
                        navigate('/predictions/404');
    
                    }
                    
                    setIsLoading(false);
                };
        
                fetchResults();
            }
        } else {
            navigate('/401');
        }
        
        //eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (singleResult) {
            setupSingleResult(singleResult);
        }
        //eslint-disable-next-line
    }, [singleResult]);

    const changeTab = (name: string) => {
        if (modelNames.length) {
            
            const t = modelNames.find(n => n === name);
            if (t) {
                setTab(t);
                if (singleResult) {
                    setupSingleResult(singleResult, t)
                }
            }
        }
    }


    const modifyRecords = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>) => {
        let searchArr: any[] = [];
        if (itemIdentifier) {
            searchArr = searchItems(searchInput, orderedRecords, itemIdentifier);
        } else {
            searchArr = orderedRecords;
        }
        const sortedArr = sortByColumn(selectedHeader, headers, true, searchArr, updateHeaders);
        setModifiedRecords(sortedArr);
    }

    const updateResultset = async (obj: any) => {
        const { project, measurement_type, organism, target } = obj;
        const aclObj = {owner: obj.owner, access: obj.access, read: [], write: []};
        const metadata = { project, measurement_type, organism, target };
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {

                await API.put(`resultsets/${id}`, metadata, { headers: { 'X-Auth': authKey } });
                if (auth && singleResult && (isAdmin(auth) || isOwner(auth, singleResult.acl.owner))) {
                    await API.put(`resultsets/${id}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                }
                const getResponse = await API.get(`resultsets/${id}`, { headers: { 'X-Auth': authKey } });
                const data = getResponse.data;
                setSingleResult(data);
                dispatch(updateExistingResult(data));
                setPopupMessage && setPopupMessage('This resultset was updated successfully!', true);
    
            } catch(err:any) {
    
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error updating this resultset`, false)
    
            }
        }
    }

    const deleteResults = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`resultsets/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingResults(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);

            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error deleting ${ids.length > 1 ? 'these resultsets' : 'this resultset'}`, false);
                throw new Error('Error');
            }
        }
    }

    const downloadResultset = async (filename: string, id: string, ext:string, threshold: number) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`resultsets/${id}/download?format=${threshold}-allStats-${ext}`, { headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                download(filename, data);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error downloading this resultset`, false)
            }
        }
    }

    return {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        headers,
        setHeaders,
        info,
        routes,
        activityColumn,
        infoCells,
        predictionCells,
        limits,
        singleResult,
        deleteResults,
        downloadResultset,
        updateResultset,
        isLoading,
        modelNames,
        changeTab,
        itemIdentifier,
        isConformal,
        plotData,
        tab,
        setTab
    }

}

export default useSingleResult;