import Plot from "react-plotly.js";
import { useEffect, useState } from "react";
import { Config } from "plotly.js";

export default function TruthTable({data}: {data: {name: string, value: number}[]}) {

    const [ tableData, setTableData ] = useState<Plotly.Data[]>([]);
    const [ layout, setLayout ] = useState<Partial<Plotly.Layout> | null>(null);


    useEffect(() => {
        let traces: Plotly.Data[] = [];
        let tableLayout: Partial<Plotly.Layout> | null = null;
        if (data.length > 0) {
            let TP = data.find(d => d.name === 'True Positive');
            let TN = data.find(d => d.name === 'True Negative');
            let FP = data.find(d => d.name === 'False Positive');
            let FN = data.find(d => d.name === 'False Negative');
            if (TP !== undefined && TN !== undefined && FP !== undefined && FN !== undefined) {
                const total = data.reduce(
                    (acc, value) => value.value + acc, 0
                )

                const totalTruths = TP.value + TN.value;
                const totalFalses = FP.value + FN.value;

                const zBlue = [
                    [null, null],
                    [TP.value, TN.value]
                ]
                
                const zRed = [
                    [FP.value, FN.value],
                    [null, null]
                ]

                traces = [
                    {
                        z: zRed,
                        x: ['Positive', 'Negative'],
                        y: ['False', 'True'],
                        type: 'heatmap',
                        colorscale: [
                            [0, '#FFFFFF'],
                            [1, '#F47948'],
                        ],
                        zmin: 0,
                        zmax: total,
                        colorbar: {
                            tickvals: [],
                            ticktext: [],
                            thickness: 18,
                            x: 1.02,  // Adjust position to the right
                        },
                        hoverinfo: 'none',
                        
                    },
                    {
                        z: zBlue,
                        x: ['Positive', 'Negative'],
                        y: ['False', 'True'],
                        type: 'heatmap',
                        colorscale: [
                            [0, '#FFFFFF'],
                            [1, '#5385BA'],
                        ],
                        zmin: 0,
                        zmax: total,
                        colorbar: {
                            tickvals: [0, total],
                            ticktext: ['0%','100%'],
                            thickness: 18,
                            x: 1.09,  // Adjust position to the right
                        },
                        hoverinfo: 'none',
                    }
                ]
                

                

                tableLayout = {
                    title: {
                        text: 'Model Truth Table',
                        font: {
                            family: 'Montserrat',
                            size: 16,
                        },
                        y: 0.9, // Adjust this value to move the title closer or further away from the heatmap
                        x: 0.5,  // Center the title horizontally
                        xanchor: 'center',
                        yanchor: 'top', // Anchor the title to the top
                    },
                    autosize: true,
                    xaxis: {
                        tickvals: [0, 1],
                        ticktext: ['Positive', 'Negative'],
                    },
                    yaxis: {
                        tickvals: [0, 1],
                        ticktext: [
                            `False (${Math.round((totalFalses/total)*100)}%)`, 
                            `True (${Math.round((totalTruths/total)*100)}%)`, 
                        ],
                        automargin: true,
                    },
                    annotations: [
                        {
                          x: 0,
                          y: 0,
                          text: `${FP.value.toString()} (${Math.round((FP.value/total)*100)}%)`,
                          showarrow: false,
                          font: { size: 14 },
                        },
                        {
                          x: 1,
                          y: 0,
                          text: `${FN.value.toString()} (${Math.round((FN.value/total)*100)}%)`,
                          showarrow: false,
                          font: { size: 14 },
                        },
                        {
                          x: 0,
                          y: 1,
                          text: `${TP.value.toString()} (${Math.round((TP.value/total)*100)}%)`,
                          showarrow: false,
                          font: { size: 14 },
                        },
                        {
                          x: 1,
                          y: 1,
                          text: `${TN.value.toString()} (${Math.round((TN.value/total)*100)}%)`,
                          showarrow: false,
                          font: { size: 14 },
                        },
                    ],
                   
                }
                              

            };
        };

        setTableData(traces);
        setLayout(tableLayout);
        
        
    }, [data]);


    const config: Partial<Config> = {
        scrollZoom: true,
        displaylogo: false,
        displayModeBar: true,
        responsive: true,
        modeBarButtonsToRemove: ['toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian'],
        toImageButtonOptions: {
            format: 'svg',
            scale: 1,
        }
    }

    


    return (
        <>
        {layout && (
            <Plot data={tableData} layout={layout} config={config} className="w-full aspect-square"/>
        )}
        </>
    )
}