import { useEffect, useState } from "react"
import ChevronLeft from '../../../assets/chevron-left-black.svg';
import ChevronRight from '../../../assets/chevron-right-black.svg';
import { Config } from "plotly.js";
import Plot from "react-plotly.js";




export default function ConfPredVis({ data }: {
    data: {data: Plotly.Data[], layout: Partial<Plotly.Layout>, methods: string[]}[],
}) {
    const [ methods, setMethods ] = useState<string[]>([]);
    const [ plotData, setPlotData ] = useState<{data: Plotly.Data[], layout: Partial<Plotly.Layout>, methods: string[]}[]>([]);
    const [ index, setIndex ] = useState(0);

    const config: Partial<Config> = {
        scrollZoom: true,
        displaylogo: false,
        displayModeBar: true,
        responsive: true,
        modeBarButtonsToRemove: ['toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian'],
        toImageButtonOptions: {
            format: 'svg',
            scale: 1,
        }
    }

    useEffect(() => {
        if (data.length > 0) {
            setPlotData(data);
            setMethods(data[0].methods);
        }
    }, [data])



    const toggleIndex = (e:any, type: 'prev' | 'next') => {
        e.preventDefault();

        if (type === 'next') {
            if (index !== plotData.length - 1) {
                setIndex(index + 1);
            }
        } else if (type === 'prev') {
            if (index !== 0) {
                setIndex(index - 1);
            }
        }
    };


    return (
        <div className={`w-full rounded bg-white border border-[#CBCEDC] flex flex-col gap-6 h-full p-8`}>
            <div className={`w-full flex justify-between items-center`}>
                <div className="flex flex-col gap-[2px] justify-start items-start">
                    <p className='text-[1.5rem] font-semibold text-nowrap'>Prediction Results</p>
                </div>
                {methods.length > 1 && (
                    <div className="flex justify-end items-stretch gap-4">
                        <div className='flex justify-center items-center rounded bg-darker-background shadow-inner'>
                            <button onClick={(e:any) => toggleIndex(e, 'prev')} className={`${index === 0 ? 'border-quaternary' : 'border-secondary'} border-t border-b border-l darken-icons flex justify-center items-center px-3 py-1 rounded-tl rounded-bl h-full relative toggle-btn`}>
                                {index > 0 && <div className='absolute top-0 left-0 right-0 bottom-0' />}
                                <img src={ChevronLeft} alt='left-arrow' className='w-[14px]' />
                            </button>
                            <button onClick={(e:any) => toggleIndex(e, 'next')} className={`${index > 0 ? 'border-quaternary' : 'border-secondary'} border darken-icons flex justify-center items-center px-3 py-1 rounded-tr rounded-br h-full relative toggle-btn`}>
                                {index < plotData.length-1 && <div className='absolute top-0 left-0 right-0 bottom-0' />}
                                <img src={ChevronRight} alt='right-arrow' className='w-[14px]' />
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {plotData.length > 0 && plotData.map((plot, i) => (
                <>
                {i === index && (
                    <Plot data={plot.data} layout={plot.layout} config={config} className="w-full h-[600px]"/>
                )}
                </>
            ))}
        </div>
    )
}