import { ReactNode, MouseEvent } from 'react';
import Lock from '../../assets/lock.svg';
import Restart from '../../assets/refresh.svg';

export default function FlowCard({ children, label, number, isLocked, reset, isKetcher, isClassification } : { 
    children: ReactNode, 
    label: string, 
    number: number, 
    isLocked: boolean, 
    reset?: (e: MouseEvent<HTMLButtonElement>) => void,
    isKetcher?: boolean,
    isClassification?: boolean,
}) {

    
    return (
        <div className={`${isKetcher === undefined ? 'w-full' : isKetcher ? 'grow' : 'w-1/3'} ${isClassification !== undefined ? !isClassification ? 'h-[70vh]' : 'grow' : 'h-[70vh]'} relative min-w-[400px] flex flex-col rounded bg-white overflow-hidden border border-[#CBCEDC]`}>
            {isLocked && <div data-testid='overlay' className='absolute z-20 top-0 bottom-0 left-0 right-0 bg-quaternary/[0.3]'></div>}
            <div className='flex justify-start items-center gap-3 p-4 bg-gradient-to-r from-secondary to-tertiary'>
                <div className='whiten rounded-full border border-white w-[28px] h-[28px] flex justify-center items-center text-white font-medium'>
                    {isLocked ? <img src={Lock} className='w-[18px]' alt='locked-icon'/> : number}
                </div>
                <p className="font-medium text-[18px] text-white">{label}</p>
                <hr className="bg-white grow"/>
            </div>
            <div className={`p-4 h-full overflow-y-auto`}>
                {children}
            </div>
            {reset && (
                <>
                <div className='w-full h-[60px]'></div>
                <button onClick={reset} className='absolute bottom-0 left-0 right-0 w-full p-3 flex justify-center items-center gap-3 border-2 border-grad-one text-secondary bg-grad-one text-[14px] font-medium rounded-b hover:bg-secondary hover:text-white hover:border-secondary lighten transition-all'>
                    <img src={Restart} className='w-[18px]' alt='restart-icon'/>
                    Restart
                </button>
                </>
            )}
        </div>
    )
}