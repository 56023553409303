import { ReactNode, ChangeEvent, MouseEvent } from 'react';
import Green from '../../assets/greenStar.svg';
import Red from '../../assets/redStar.svg';
import Active from '../../assets/circle-check.svg';
import Inactive from '../../assets/circle-x.svg';

export type GridType = 'structure' | 'user'

export default function GridItemBase({ 
    activeField,
    title, 
    children, 
    optionButton,
    item, 
    selectedRows, 
    editSelectedRows, 
    isChecked,
    gridType,
    action,
} : {
    title?: string,
    children?: ReactNode,
    optionButton?: ReactNode,
    item?: any,
    isChecked: boolean,
    activeField?: {[key:string]: string, name: string, type: string} | null,
    selectedRows?: string[],
    editSelectedRows?: (e:ChangeEvent<HTMLInputElement>) => void,
    gridType: GridType,
    action?: {
        findSelectedMethod?: (e:any, name: string) => void,
    }
}) {


    const checkIfRowIsSelected = (item:any) => {
        if (selectedRows !== undefined) {
            if (selectedRows.includes(item.id ? item.id : item.name)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const performAction = (e:MouseEvent<HTMLButtonElement>, item: any) => {
        if (action) {
            if (action.findSelectedMethod && item.method_name !== undefined) {
                action.findSelectedMethod(e, item.method_name);
            }
        }
    }


    return (
        <div className='w-full rounded bg-white p-4 relative border border-[#CBCEDC]'>
            {checkIfRowIsSelected(item) && <div className="absolute top-0 right-0 left-0 bottom-0 bg-secondary bg-opacity-30 rounded"></div>}
            <div className='flex h-full flex-col gap-4 justify-between items-stretch'>
                {gridType === 'structure' ? (
                    <>
                    {(activeField || title || optionButton) && (
                        <div className={`flex items-center justify-between`}>
                            <div className={`flex justify-start items-center gap-3`}>
                                {isChecked && <input onChange={editSelectedRows} checked={checkIfRowIsSelected(item)}  name={item.id ? item.id : item.name} type="checkbox" className="accent-secondary relative z-20 w-[20px] aspect-square hover:cursor-pointer"/>}
                                {activeField && activeField.type !== 'continuous-value' && (
                                    <>
                                    {item.isActive ? (
                                        <img className="w-[20px]" src={Green} alt='active-icon'/>
                                    ) : (
                                        <img className="w-[20px]" src={Red} alt='inactive-icon'/>
                                    )}
                                    </>
                                )}
                                {title && <button disabled={action ? false : true} onClick={(e:any) => performAction(e, item)} className='text-highlight text-left disabled:text-primary hover:text-tertiary transition-all max-w-[400px] font-semibold'>{title.length >= 25 ? title.substring(0, 25) + '\u2026' : title}</button>}
                            </div>
                            <div>
                                {optionButton}
                            </div>
                        </div>
                    )}
                    </>
                ) : (
                    <div className="w-full h-[400px] relative bg-[url('https://st4.depositphotos.com/9998432/27431/v/450/depositphotos_274313380-stock-illustration-person-gray-photo-placeholder-woman.jpg')] bg-cover bg-center">
                        <div className={`absolute top-[12px] left-[12px] right-[12px] flex items-center justify-between`}>
                            <div className={`flex justify-start items-center gap-2`}>
                                {isChecked && <input onChange={editSelectedRows} checked={checkIfRowIsSelected(item)}  name={item.id} type="checkbox" className="accent-secondary relative z-20 w-[20px] aspect-square hover:cursor-pointer"/>}
                            </div>
                            {optionButton}
                        </div>
                        <img src={item.active_status ? Active : Inactive} alt='user-status' className="w-[64px] absolute bottom-[12px] right-[12px]" />
                    </div>
                )}
                    
                        
                {children}
            </div>
        </div>
    )
}