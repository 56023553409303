import { useEffect, useState } from "react";
import { hist_data } from "../../data/functions";
import { SingleClassificationModel } from "../../types/models.types";
import EmptyGraph from "./EmptyGraph";
import PlotlyGraphs from "./PlotlyGraphs";
import TruthTable from "./TruthTable";

export default function ModelGraphs({ type, methodPlot, allData }: {
    type: 'regression' | 'classification',
    allData: any,
    methodPlot?: { name: string, graphs: string[], index: number },
}) {
    const [ singleROC, setSingleRoc ] = useState<Plotly.Data[]>([]);


    useEffect(() => {
        if (methodPlot && methodPlot.graphs[methodPlot.index] === 'singleRoc') {
           setSingleRoc([{
            mode: 'lines',
            name: 'Fold',
            type: 'scatter',
            x: allData.fpr,
            y: allData.tpr,
           }])
        }
    }, [methodPlot, allData]);

    return (
        <>
        {type === 'regression' ? (
            <PlotlyGraphs isSquare={true} type='scatter' data={allData.cross_val_predict}/>
        ) : (
            <>
                {methodPlot && methodPlot.graphs[methodPlot.index] === 'roc' && <PlotlyGraphs isSquare={true} type='roc' data={allData.roc}/>}
                {methodPlot && methodPlot.graphs[methodPlot.index] === 'singleRoc' && <PlotlyGraphs isSquare={true} type='singleRoc' data={singleROC}/>}
                {methodPlot && methodPlot.graphs[methodPlot.index] === 'overlay' && (
                    <>
                    {!hist_data(allData) ? (
                        <EmptyGraph type="Histogram" />
                    ) : (
                        <PlotlyGraphs isSquare={true} type='histogram' data={hist_data(allData as SingleClassificationModel) as any} />
                    )}
                    </>
                )}
                {methodPlot && methodPlot.graphs[methodPlot.index] === 'truthTable' && (
                    <>
                    {allData.tp !== undefined && allData.tn !== undefined && allData.fn !== undefined && allData.fp !== undefined ? (
                        <TruthTable data={[
                        {name: 'True Positive', value: (allData as SingleClassificationModel).tp as number}, 
                        {name: 'False Positive', value: (allData as SingleClassificationModel).fp as number}, 
                        {name: 'False Negative', value: (allData as SingleClassificationModel).fn as number},
                        {name: 'True Negative', value: (allData as SingleClassificationModel).tn as number},
                    ]} />
                    ) : (
                        <EmptyGraph type="Truth" />
                    )}
                    </>
                ) }
            </>
        )}
        </>
    )
}