// assets
import Close from '../../assets/x-close.svg';

// components
import Select from 'react-select';

// functions + data
import { applyFilters } from '../../data/functions';


// types
import { MouseEvent, Dispatch, SetStateAction, ChangeEvent, useState, useEffect } from "react";
import { FilterType, FilterFieldsType } from "../../types/all.types";
import { metricsArr } from '../../data/models';
import { IconButton } from '@mui/material';



const opOptions = [
    {value: '<', label: '<'},
    {value: '<=', label: '<='},
    {value: '>', label: '>'},
    {value: '>=', label: '>='}
]


export interface SizeType {
    op: string,
    size: string,
}

export interface RecordType {
    op: string,
    quantity: string,
}

interface AccessType {
    authenticated: boolean,
    private: boolean,
}

const isSizeType = (obj:any): obj is SizeType => {
    if (obj.size !== undefined) {
        return true;
    } else {
        return false;
    }
}

const isRecordType = (obj:any): obj is RecordType => {
    if (obj.quantity !== undefined) {
        return true;
    } else {
        return false;
    }
}

const isAccessType = (obj:any): obj is AccessType => {
    if (obj.authenticated !== undefined || obj.private !== undefined) {
        return true;
    } else {
        return false;
    }
}

export default function FilterPopup({ togglePopup, items, setFilteredItems, defaultFilter, fieldsObj, searchInput, itemsFilter, setItemsFilter, applyFilter, isNested }: { 
    togglePopup: (e:MouseEvent<HTMLButtonElement>) => void,
    items?: any[], 
    setFilteredItems?: Dispatch<SetStateAction<any[]>>, 
    defaultFilter: FilterType,
    fieldsObj: FilterFieldsType ,
    searchInput: string,
    itemsFilter: FilterType,
    setItemsFilter: Dispatch<SetStateAction<FilterType>>, 
    applyFilter?: (e:any, filterObj: FilterType) => void,
    isNested?: boolean,
}) {

    const [ filter, setFilter ] = useState<FilterType>(defaultFilter);

    useEffect(() => {
        setFilter(itemsFilter);

    }, [itemsFilter]);




    const handleMultiSelect = (e:any, name: string) => {
        setFilter({...filter, [name]: e.map((val: any) => val.value)});
    };

    const handleOp = (e:any, name: string) => {
        setFilter({...filter, [name]: {...(filter[name] as RecordType | SizeType), op: e.value}})
    }


    console.log(itemsFilter)


    return (
        <>

            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded p-8  max-w-[600px] w-full'>
                    <div className='popup-max-height overflow-y-auto'>
                        <div className='flex flex-col gap-8 justify-start items-stretch'>

                            <div className="w-full flex justify-between items-center">
                                <p className='text-primary font-semibold text-[2rem]'>Filter Table</p>
                                <IconButton
                                    onClick={togglePopup}
                                >
                                    <img src={Close} className="w-[18px]" alt='clear-search'/>
                                </IconButton>
                            </div>
                            
                            {Object.keys(filter).map(key => {
                                if (Array.isArray(filter[key])) {
                                    return (
                                        <div className='grow flex flex-col justify-start items-stretch'>
                                            <p className='text-left text-[16px] font-semibold text-secondary mb-2 capitalize'>{key.split('_').join(' ')}</p>
                                            <Select maxMenuHeight={100} menuPortalTarget={isNested ? document.getElementById('nested-overlay') : document.getElementById('overlay')} onChange={(e:any) => handleMultiSelect(e, key)} isMulti className='text-left text-[14px] rounded' value={!(filter[key] as string[]).length ? null : (filter[key] as string[]).map(p => ({value: p, label: p}))} options={fieldsObj ? (fieldsObj[key] as string[]).map(p => ({value: p, label: p})) : [{value:'', label: ''}]} />
                                        </div>
                                    )
                                } else if (isSizeType(filter[key])) {
                                    return (
                                        <div className='flex flex-col justify-start items-stretch'>
                                            <p className='text-left text-[14px] font-semibold text-secondary mb-3'>{key.split('_').join(' ')}</p>
                                            <div className='flex justify-center gap-3 text-left'>
                                                <div className='flex flex-col justify-start'>
                                                    <label className='text-[12px] font-semibold text-quaternary'>Operator</label>
                                                    <Select maxMenuHeight={100} menuPortalTarget={isNested ? document.getElementById('nested-overlay') : document.getElementById('overlay')} onChange={(e:any) => handleOp(e, 'size')} value={{value: (filter[key] as SizeType).op, label: (filter[key] as SizeType).op}} options={opOptions} className='text-left text-[14px] rounded' />
                                                </div>
                                                <div className='grow flex flex-col justify-start'>
                                                    <label className='text-[12px] font-semibold text-quaternary'>Size</label>
                                                    <input onChange={((e:ChangeEvent<HTMLInputElement>) => setFilter({...filter, [key]: {...(filter[key] as SizeType), size: e.target.value}}))} value={(filter[key] as SizeType).size} className='text-left text-[14px] rounded border border-primary p-2 h-[37px]' placeholder='Bytes'/>
                                                </div>
                                            </div> 
                                        </div>
                                    )
                                } else if (isRecordType(filter[key]) ) {
                                    return (
                                        <div className='flex flex-col justify-start items-stretch'>
                                            <p className={`text-left text-[16px] font-semibold text-secondary mb-3 ${metricsArr.includes(key) ? 'uppercase' : 'capitalize'}`}>{key.split('_').join(' ')}</p>
                                            <div className='flex justify-center gap-3 text-left'>
                                                <div className='flex flex-col justify-start'>
                                                    <label className='text-[12px] font-semibold text-quaternary'>Operator</label>
                                                    <Select maxMenuHeight={100} menuPortalTarget={isNested ? document.getElementById('nested-overlay') : document.getElementById('overlay')} onChange={(e:any) => handleOp(e, key)} value={{value: (filter[key] as RecordType).op, label: (filter[key] as RecordType).op}} options={opOptions} className='text-left text-[14px] rounded' />
                                                </div>
                                                <div className='grow flex flex-col justify-start'>
                                                    <label className='text-[12px] font-semibold text-quaternary'>Quantity</label>
                                                    <input onChange={((e:ChangeEvent<HTMLInputElement>) => setFilter({...filter, [key]: {...(filter[key] as RecordType), quantity: e.target.value}}))} value={(filter[key] as RecordType).quantity} className='text-left text-[14px] rounded border border-primary p-2 h-[37px]' placeholder='Quantity'/>
                                                </div>
                                            </div>  
                                        </div>
                                    )
                                } else if (isAccessType(filter[key])) {
                                    return (
                                    <div className='flex flex-col justify-start items-stretch'>
                                        <p className='text-left text-[16px] font-semibold text-secondary mb-3 capitalize'>Access Type</p>
                                        <div className='flex flex-col justify-center gap-3 text-left mb-3'>
                                        {fieldsObj && fieldsObj[key].map((n:any) => (
                                            <div className='flex justify-start gap-3'>
                                                <input onChange={(e:ChangeEvent<HTMLInputElement>) => setFilter({...filter, [key]: { ...(filter[key] as AccessType), [n]: e.target.checked }})} type='checkbox' className='accent-secondary' checked={(filter[key] as AccessType)[n as keyof AccessType]}/>
                                                <p>{n}</p>
                                            </div>   
                                        ))}
                                        </div>  
                
                                    </div>
                                    )
                                } else {
                                    return <></>
                                }
                            })}
                            </div>
                
                            <div className='w-full gap-3 flex justify-center items-center mt-8'>
                                <button onClick={(e:MouseEvent<HTMLButtonElement>) => {items && setFilteredItems && applyFilters(filter, items, setFilteredItems, searchInput); togglePopup(e); applyFilter && applyFilter(e, filter)}} className='py-2 bg-tertiary border border-tertiary text-white rounded grow hover:bg-secondary hover:border-secondary transition-all'>Done</button>
                                <button onClick={(e:MouseEvent<HTMLButtonElement>) => {setFilter(defaultFilter); setFilteredItems && items && setFilteredItems(items); togglePopup(e); applyFilter && applyFilter(e, defaultFilter)}} className='py-2 px-4 border border-quaternary rounded text-quaternary hover:bg-quaternary hover:text-white transition-all'>Clear Filter</button>
                            </div>

                        </div>
                    </div>
                </div>
            
        </>
    )
}