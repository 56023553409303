
import { CircularProgress } from "@mui/material";
import NoImg from '../../../../assets/noImg.svg';
import ScreenshotButton from "../../../../components/Buttons/ScreenshotButton";
import SelectedMoleculeInfo from "./SelectedMoleculeInfo";
import useRenderMolecule from "../../../../hooks/useRenderMolecule";

export default function SelectedMolecule({ selectedMolecule }: {
    selectedMolecule: { molecule_id?: string, molecule_smile?: string, set_type: string, name: string, color: string } | null,
}) {

    const { isNotFound, src } = useRenderMolecule({ id: selectedMolecule ? selectedMolecule.molecule_id : '', size: 150  });


    return (
        <div className="w-full rounded border-2 border-quaternary overflow-hidden">
            <div className={`bg-white w-full relative text-center`}>
                {src ? (
                    <div className="w-full relative">
                        <div className="absolute top-[12px] right-[12px] z-20">
                            <ScreenshotButton hasText={false} width="18px" identifier={selectedMolecule && selectedMolecule.molecule_smile ? selectedMolecule.molecule_smile : 'molecule'}/>
                        </div>
                        <div className='w-full p-8 relative'>
                            <img src={src} alt='chemical-structure' className='w-full object-cover' />
                        </div>
                    </div>
                ) : isNotFound ? (
                        <div className='w-full p-4 relative'>
                            <img src={NoImg} alt='no-structure' className='w-full object-cover' />
                        </div>
                ) : !selectedMolecule ? (
                    <div className="w-full flex justify-center items-center aspect-square">
                        <p className="text-quaternary font-semibold text-[18px] max-w-[300px]">Select a point to view molecule information</p>
                    </div>
                ) : selectedMolecule && !selectedMolecule.molecule_id ? (
                    <div className="w-full flex justify-center items-center aspect-square">
                        <p className="text-quaternary font-semibold text-[18px] max-w-[300px]">You do not have access to this molecule's information</p>
                    </div>
                ) : (
                    <div className="w-full flex justify-center items-center text-[#D9D9D9]">
                        <CircularProgress size={80} color='inherit'/>
                    </div>
                )}
            </div>
            {selectedMolecule && selectedMolecule.molecule_smile && (
                <SelectedMoleculeInfo smiles={selectedMolecule.molecule_smile} setType={selectedMolecule.set_type} color={selectedMolecule.color} name={selectedMolecule.name}/>
            )}
        </div>
    )
}