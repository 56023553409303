import { useState, useEffect, MouseEvent } from "react";
import { VisualizationGroupType } from "../../types/predictions.types";
import { Config } from "plotly.js";
import API from '../../api';
import { download } from "../../data/functions";

const trainingSetColors = [
    '#ff7f0e',  // safety orange
    '#2ca02c',  // cooked asparagus green
    '#d62728',  // brick red
    '#9467bd',  // muted purple
    '#8c564b',  // chestnut brown
    '#e377c2',  // raspberry yogurt pink
    '#7f7f7f',  // middle gray
    '#bcbd22',  // curry yellow-green
    '#17becf'   // blue-teal
]

const useSingleResultVisualizations = ({ group, setPopupMessage }: {
    group: VisualizationGroupType,
    setPopupMessage: (message: string, isSuccess: boolean) => void,
}) => {
    const [ index, setIndex ] = useState(0);
    const [ plotData, setPlotData ] = useState<any[]>([]);

    const [ selectedMolecule, setSelectedMolecule ] = useState<{molecule_id?: string, molecule_smile?: string, set_type: string, name: string, color: string} | null>(null);
    const [ selectedPoint, setSelectedPoint ] = useState<{traceIndex: number | null, pointIndex: number | null}>({traceIndex: null, pointIndex: null});
    const [ traceVisibility, setTraceVisibility ] = useState<boolean[]>([]);

    const [ isDownloadingPlot, setIsDownloadingPlot ] = useState(false);
    const [ isDownloadingAll, setIsDownloadingAll ] = useState(false);


    const toggleIndex = (e:MouseEvent<HTMLButtonElement>, type: 'prev' | 'next') => {
        e.preventDefault();

        if (type === 'next') {
            if (index !== plotData.length - 1) {
                setIndex(index + 1);
            }
            setSelectedMolecule(null);
        } else if (type === 'prev') {
            if (index !== 0) {
                setIndex(index - 1);
            }
            setSelectedMolecule(null);
            setSelectedPoint({traceIndex: null, pointIndex: null});

            const method = Object.keys(group.visualization_methods).map(m => m)[0];
            setTraceVisibility(Object.keys(group.visualization_methods[method as keyof object].visualization_result).map(m => true));
        }

    };

    const getMolecule = (e:any) => {
        const point = e.points[0];
        setSelectedMolecule(point.customdata);

        setSelectedPoint({ traceIndex: point.curveNumber, pointIndex: point.pointIndex })
    };

    const toggleTraceVisibility = (index: number) => {
        const newVisibility = [...traceVisibility];
        newVisibility[index] = !newVisibility[index]; // Toggle the visibility
        setTraceVisibility(newVisibility);
    }


    const config: Partial<Config> = {
        scrollZoom: true,
        displaylogo: false,
        displayModeBar: true,
        responsive: true,
        modeBarButtonsToRemove: ['toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian'],
        toImageButtonOptions: {
            format: 'svg',
            scale: 1,
        }
    };

    const layout_reg: () => Partial<Plotly.Layout> = function() {
    
        return {
          responsive: true,
          autosize: true,
          showlegend:false,
          margin: {
            l: 30,
            r: 10,
            b: 30,
            t: 60,
            pad: 0
          },
          modebar: {
            color: '#555555',
            activecolor: '#315FB5',
          },
        }
    };

    useEffect(() => {
        if (group) {
            const method = Object.keys(group.visualization_methods).map(m => m)[0];
            setTraceVisibility(Object.keys(group.visualization_methods[method as keyof object].visualization_result).map(m => true));
        }
    }, [group]);
    
    useEffect(() => {
        let data: any[] = [];
        group.visualization_methods.forEach(method => {
            let resultTrace = {};
            let modelCount = 0;
            let modelTraces: any[] = [];
            Object.keys(method.visualization_result).forEach((vis) => {
                let obj = method.visualization_result[vis];
                let moleculeInfo: any[] = [];

                obj.x.forEach((x, i) => {
                    let molObj = {};
                    if (obj.molecule_ids) {
                        (molObj['molecule_id' as keyof object] as string) = obj.molecule_ids[i];
                    }
                    if (obj.molecule_smiles) {
                        (molObj['molecule_smile' as keyof object] as string) = obj.molecule_smiles[i];
                    }
                    (molObj['set_type' as keyof object] as string) = vis.includes('Test') ? 'test' : 'train';
                    (molObj['name' as keyof object] as string) = vis;
                    (molObj['color' as keyof object] as string) = trainingSetColors[modelCount];
                    moleculeInfo.push(molObj)
                });

                if (vis.includes('Test')) {
                    resultTrace = {
                        x: obj.x,
                        y: obj.y,
                        mode: 'markers',
                        type: 'scatter',
                        name: 'Test',
                        marker: { color: '#1f77b4' },
                        selectedpoints: selectedPoint.traceIndex === 0 ? [selectedPoint.pointIndex] : null,
                        selected: {
                            marker: { size: 12, color: '#FF00FF' },  // Style for selected points
                        },
                        visible: traceVisibility[0] ? true : 'legendonly',
                        customdata: moleculeInfo,
                    }
                } else {
                    let modelTrace = {};

                    modelTrace = {
                        x: obj.x,
                        y: obj.y,
                        mode: 'markers',
                        type: 'scatter',
                        name: 'Train',
                        marker: { color: trainingSetColors[modelCount] },
                        selectedpoints: selectedPoint.traceIndex === modelCount+1 ? [selectedPoint.pointIndex] : null,
                        selected: {
                            marker: { size: 12, color: '#FF00FF' },  // Style for selected points
                        },
                        visible: traceVisibility[modelCount+1] ? true : 'legendonly',
                        customdata: moleculeInfo,
                    }

                    modelTraces.push(modelTrace);

                    modelCount += 1;
                }
            });

            data.push({
                plotInfo: method.visualization_method,
                plotData: ([resultTrace, modelTraces]).flat(),
            })

        });

        setPlotData(data);
    }, [group, selectedPoint, traceVisibility]);

    const exportVisData = async (e: MouseEvent<HTMLButtonElement>, plots: {id: string, name: string}[]) => {
        e.preventDefault();

        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            const promises = plots.map(async (plot) => {
                return API.get(`resultset_visualization/${plot.id}/download`, { headers: { 'X-Auth': authKey }, responseType: 'blob' }).then(res => res.data).then(data => download(`${group.fingerprint.name}-${plot.name}.xlsx`, data)).catch(err => console.log(err))
            });

            if (plots.length > 1) {
                setIsDownloadingAll(true)
            } else {
                setIsDownloadingPlot(true);
            }

            try {
                await Promise.all(promises);
                setPopupMessage('The data visualizations you selected were successfully exported.', true);
            } catch (err:any) {
                console.log(err);
                setPopupMessage('We encountered an issue downloading the plot data you selected. Please try again.', false);
            }

            setIsDownloadingAll(false);
            setIsDownloadingPlot(false);
        }
    };

    return {
        selectedMolecule,
        isDownloadingAll,
        isDownloadingPlot,
        toggleIndex,
        index,
        plotData,
        getMolecule,
        toggleTraceVisibility,
        traceVisibility,
        config,
        layout_reg,
        exportVisData,
    }

};

export default useSingleResultVisualizations;