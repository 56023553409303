// hooks
import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import useLayout from "../../../hooks/useLayout";
import usePagination from "../../../hooks/usePagination";
import usePopup from "../../../hooks/usePopup";
import useSelection from "../../../hooks/useEnableSelection";

// assets
import Info from '../../../assets/info-circle.svg';
import ProtocolIcon from '../../../assets/alert-square.svg';

// components
import Grid from "../../../components/Grid/Grid";
import SinglePageHeader from "../../../components/Page/SinglePageHeader";
import AdditionalInfoSideMenu from "../../../components/Popups/AdditionalInfoSideMenu";
import Pagination from "../../../components/Page/Pagination";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import ToggleView from "../../../components/Page/ToggleView";
import Button from "../../../components/Buttons/Button";
import TableControls from "../../../components/Table/TableControls";
import ChemicalPopup from "../../../components/Popups/ChemicalPopup";
import DeletePopup from "../../../components/Popups/DeletePopup";
import DataRepresentationBox from "../../../components/Data/DataRepresentationBox";
import NumberLine from "../../../components/Data/NumberLine";
import ReadacrossPopup from "../../ReadAcross/components/ReadacrossPopup";
import ThresholdSlider from "../../../components/Data/ThresholdSlider";
import { getThresholdValue } from "../../../data/functions";
import { regressionMethodsArr } from "../../../data/models";
import SnackBar from "../../../components/Alerts/SnackBar";
import EmptyTable from "../../../components/Table/EmptyTable";
import useSingleResult from "../../../hooks/pageHooks/useSingleResult";
import { SideInfo } from "../../../types/all.types";
import useMessage from "../../../hooks/useMessage";
import GridItemBase from "../../../components/Grid/GridItemBase";
import SingleCompoundOptions from "../../../components/Dropdowns/SingleCompoundOptions";
import ImageCell from "../../../components/Data/ImageCell";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import OptionsDropdownBase from "../../../components/Dropdowns/OptionsDropdownBase";
import IndividualResultsetOptions from "../components/IndividualResultsetOptions";
import Overlay from "../../../components/Popups/Overlay";
import useModifyTableItems from "../../../hooks/useModifyTableItems";
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";
import CreateVisButton from "../../../components/Buttons/CreateVisButton";
import CreateVisPopup from "../components/CreateVis/CreateVisPopup";
import Visualizations from "../components/Visualizations/Visualizations";
import SuccessPopup from "../../../components/Popups/SuccessPopup";

import PlotlyGraphs from "../../../components/Data/PlotlyGraphs";
import ConfPredVis from "../components/ConfPredVis";
import useResultVisualizations from "../../../hooks/pageHooks/useResultVisualizations";
import PageTabs from "../../../components/Page/PageTabs";
import TableInformationButton from "../../../components/Buttons/TableInformationButton";
import TableLegend from "../components/TableLegend";
import Validations from "../components/Validations";

const metricsList = ['DL', 'ad-score', 'ada', 'avg', 'bnb', 'knn', 'lreg', 'max', 'rf', 'svc', 'adar', 'br', 'min', 'rfr', 'svr', 'svc'];
const classificationMetrics = ['DL', 'ad-score', 'bnb', 'knn', 'lreg', 'svc', 'rf', 'ada'];


export default function SingleResultsetPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const [ isLoading, setIsLoading ] = useState(true);
    const [ threshold, setThreshold ] = useState(0.5);
    const [ isClassification, setIsClassification ] = useState(false);

    const [ selectedPlots, setSelectedPlots ] = useState<string[]>([]);
    const [ isReadacrossFlow, setIsReadacrossFlow ] = useState(false);
    const [ selectedTab, setSelectedTab ] = useState('Results');
    const [ tabs, setTabs ] = useState<string[]>([]);



    const {
        open,
        setOpen,
        message,
        isSuccess,
        setPopupMessage
    } = useMessage();
    
    const {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        info,
        routes,
        headers,
        setHeaders,
        activityColumn,
        infoCells,
        predictionCells,
        limits,
        singleResult,
        deleteResults,
        downloadResultset,
        updateResultset,
        modelNames, 
        changeTab,
        itemIdentifier,
        isConformal,
        plotData,
        tab,
        setTab,
    } = useSingleResult({id, setPopupMessage});

    const {
        visualizations,
        isVisLoading,
        deleteSingleVisualizations,
        deleteAllVis,
        exportAllVis,
    } = useResultVisualizations({id, setPopupMessage, rs: singleResult ? singleResult.name.split('.')[0] : 'prediction'})

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedRecords, count: 50});

    const {
        selectHeaders,
        setSortedColumn,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyRecords, setHeaders, searchInput, selectedHeader, setSelectedHeader })

    const { 
        isSideMenuOpen, 
        toggleSideMenu, 
        togglePopup,
        isPopupOpen,
        setPopupType,
        popupType
    } = usePopup();

    const { 
        toggleView, 
        isGrid 
    } = useLayout();

    const { 
        isChecked, 
        toggleChecked,
        addSingleItem,
        deselectItems,
        selectedRows
    } = useSelection({allItems: undefined});


    useEffect(() => {
        if (!orderedRecords.length) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [orderedRecords]);



    useEffect(() => {
        if (singleResult) {
            const predictField = singleResult.fields_mapping.find(f => f.type === 'predicted-value' && f.name.split('/')[0] === activityColumn);
            if (predictField) {
                const method = predictField.name.split('/')[1];
                if (regressionMethodsArr.includes(method)) {
                    setIsClassification(false);
                } else {
                    setIsClassification(true);
                }
            }
        }
        
    }, [singleResult, activityColumn]);

    

    

    const [ moleculeID, setMoleculeID ] = useState('');
    const [ popupInfo, setPopupInfo ] = useState<SideInfo[]>([])

    useEffect(() => {
        if (moleculeID) {
            const record = modifiedRecords.find(r => r.id === moleculeID);
            if (record) {
                let info: SideInfo[] = [];
                Object.keys(record).forEach(key => {
                    if (record[key] && key !== 'id' && !classificationMetrics.includes(key)) {
                        info.push({
                            label: key,
                            value: record[key],
                        })
                    }
                })

                setPopupInfo(info)
            }

            
        }
    }, [moleculeID, modifiedRecords]);

    useEffect(() => {
        if (singleResult) {
            if (singleResult.valid_stats && singleResult.valid_stats.length > 0) {
                setTabs(['Results', 'Validations', 'Visualizations'])
            } else {
                setTabs(['Results', 'Visualizations'])
            }
        }
    }, [singleResult]);



    const goBack = (e:any) => {
        setPopupType('chemical');
        setIsReadacrossFlow(false);
    }



    return (
        <div className="flex flex-col justify-start items-stretch gap-4">
            

                <SinglePageHeader isLoading={isLoading} label={singleResult ? singleResult.name : 'Single Prediction'} routes={routes} stats={singleResult && singleResult.records_number ? {records_number: singleResult.records_number} : undefined} statsLabel="Resultset Records">
                    <OptionsDropdownBase isLoading={isLoading}>
                        <IndividualResultsetOptions exportAllVis={exportAllVis} visualizations={visualizations} downloadFunc={downloadResultset} toggleDeletePopup={togglePopup} setPopupType={setPopupType} id={id} threshold={threshold} handleClose={() => {}}/>
                    </OptionsDropdownBase>
                    <CreateVisButton togglePopup={togglePopup} setPopupType={setPopupType}/>
                    <Button isLoading={isLoading} togglePopup={toggleSideMenu}>
                        <img src={Info} alt='info-logo' className="w-[16px]"/>
                        <p className='font-medium text-nowrap'>Show Details</p>
                    </Button>
                </SinglePageHeader>


                <PageTabs items={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

                <>
                {selectedTab === 'Results' ? (
                <>
                {
                (!isConformal) ? (
                <div className='mb-6'>
                    <DataRepresentationBox isLoading={isLoading} label='Prediction Values'>
                        <div className='w-full flex justify-center items-center gap-10'>
                        <PlotlyGraphs isSquare={false} type="box" data={plotData as any} isClassification={isClassification}/>
                        </div>
                    </DataRepresentationBox>
                </div>
                ) : (
                    <div className='mb-6'>
                        <ConfPredVis data={plotData as any}/>
                    </div>
                )}

                {modelNames.length > 1 && (
                    <PageTabs items={modelNames} selectedTab={tab} changeTab={changeTab} setSelectedTab={setTab} />
                )}
    
                {modelNames.map(name => {
                    if (name === tab) {
                        return (

                            <div style={{maxHeight: 'calc(100vh - 70px)'}} className="h-full overflow-hidden flex flex-col justify-start items-stretch gap-4">
                            <TableControls applySearch={applySearch} identifier={itemIdentifier ? itemIdentifier : undefined} setInput={setSearchInput} searchInput={itemIdentifier ? searchInput : undefined}>
                                <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                                <TableInformationButton togglePopup={togglePopup} setPopupType={setPopupType} />
                                {singleResult && singleResult.protocol && (
                                    <button onClick={(e:any) => navigate(`/protocols/${singleResult.protocol!.$oid}?rsID=${id}`)} className={` hover:bg-opacity-100 bg-grad-one bg-opacity-30 transition-all text-primary flex justify-center items-center gap-3 py-2 px-5 rounded text-nowrap`}>
                                        <img src={ProtocolIcon} className="w-[20px]" alt='go-to-protocol'/>
                                        <p className='font-medium hidden lg:block'>Protocol</p>
                                    </button>
                                )}
                                <ToggleView toggleView={toggleView} isGrid={isGrid} />
                            </TableControls>

                            {singleResult && isConformal && (
                                <ThresholdSlider threshold={threshold} setThreshold={setThreshold}/>
                            )}

                            <>
                            {isGrid ? (
                                <>
                                    <Grid isLoading={isLoading} noResults={!paginatedItems.length ? true : false}>
                                        {paginatedItems.length > 0 ? (
                                            <>
                                            {paginatedItems.map(item => {
                                                let title = '';
                                                const h = headers.find(header => header.isRequired && header.label !== 'Structure');
                                                if (h) {
                                                    title = item[h.value];
                                                }
                                                return (
                                                    <GridItemBase
                                                        title={title}
                                                        optionButton={
                                                            <IndividualOptionsDropdownBase isGrid={true}>
                                                                <SingleCompoundOptions handleClose={() => {}} addSingleItem={addSingleItem} togglePopup={togglePopup} setPopupType={setPopupType} moleculeID={item.id}/>
                                                            </IndividualOptionsDropdownBase>
                                                        }
                                                        isChecked={false}
                                                        gridType="structure"
                                                        item={item}
                                                    >
                                                        <button onClick={(e:any) => {togglePopup(e); setPopupType('chemical'); setMoleculeID(item.id)}}>
                                                            <ImageCell type="grid" id={item.id} />
                                                        </button>
                                                        <div>
                                                            <div className='flex flex-col gap-1 justify-center items-stretch'>
                                                            <>
                                                            {Object.keys(item).filter(key => metricsList.includes(key)).map(key => {
                                                                const limitsObj = limits.find(limit => limit.value === key);
                                                                if (limitsObj && !Array.isArray(item[key])) {
                                                                    return (
                                                                        <NumberLine label={key} max={limitsObj.max} min={limitsObj.min} value={Number((Math.round(Number(item[key as keyof object]) * 100) / 100 as number).toFixed(2))} />
                                                                    )
                                                                } else {
                                                                    const { color, label } = getThresholdValue(item[key], threshold);

                                                                    return (
                                                                        <div className="w-full p-1 flex flex-col gap-1 justify-center items-start">
                                                                            <p className="font-semibold text-secondary uppercase">{key}</p>
                                                                            <div style={{backgroundColor: color}} className="py-1 px-3 w-full text-center text-[12px] rounded-full font-medium">
                                                                                {label}
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                            </>
                                                            </div>
                                                        </div>      
                                                    </GridItemBase>
                                                )
                                            })}
                                            </>
                                        ) : (
                                            <EmptyTable isGrid={true} searchInput={searchInput} subject="This file contains no records"/>
                                        )}
                                        
                                    </Grid>
                                    {paginatedItems.length > 0 && (
                                        <Pagination count={50} total={orderedRecords.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                                        firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                                    )}
                                </>
                            ) : (
                                <>
                                {isLoading || paginatedItems.length > 0 ? (
                                    <>
                                        <TableBase
                                            isLoading={isLoading} 
                                            isPopupOpen={false}
                                            isChecked={false}
                                            hasOptions={isLoading ? false : true}
                                            headers={headers} 
                                            selectedHeader={selectedHeader} 
                                            setSortedColumn={setSortedColumn}
                                            prediction={{
                                                infoCells,
                                                predictionCells,
                                                activityColumn
                                            }}
                                        >
                                            {paginatedItems.length > 0 && paginatedItems.map(item => (
                                                <TableRowBase 
                                                    isChecked={false}
                                                    action={{togglePopup, setPopupType,setMoleculeID}}
                                                    item={item} 
                                                    headers={headers} 
                                                    threshold={singleResult && isConformal ? threshold : undefined} 
                                                    limits={limits}
                                                    rowType={singleResult && isConformal ? 'conformal' : isClassification ? 'color' : 'base'}
                                                >
                                                    <IndividualOptionsDropdownBase>
                                                        <SingleCompoundOptions handleClose={() => {}} addSingleItem={addSingleItem} togglePopup={togglePopup} setPopupType={setPopupType} moleculeID={item.id}/>
                                                    </IndividualOptionsDropdownBase>
                                                </TableRowBase>
                                            ))}
                                        </TableBase>
                                        {paginatedItems.length > 0 && (
                                            <Pagination count={50} total={orderedRecords.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                                            firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                                        )}
                                    </>
                                ) : (
                                    <EmptyTable searchInput={searchInput} subject="This file contains no records"/>
                                )}
                                
                                </>
                                
                                )}
                            </>
                            
                        </div>
                        )
                    } else {
                        return null;
                    }
                       
                    })}

                </>
                ) : selectedTab === 'Visualizations' ? (
                    <Visualizations visualizations={visualizations} isLoading={isVisLoading} setPopupType={setPopupType} togglePopup={togglePopup} setSelectedPlots={setSelectedPlots} setPopupMessage={setPopupMessage}/>
                ) : (
                    <Validations isLoading={isLoading} valid_stats={singleResult && singleResult.valid_stats ? singleResult.valid_stats : []}/>
                )}
                </>

                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  

       
            {isSideMenuOpen && (
                <AdditionalInfoSideMenu id={id} updateFunc={updateResultset} info={info} label='Result Details' togglePopup={toggleSideMenu} />

            )}
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup isChecked={isChecked} toggleChecked={toggleChecked} deleteFunc={deleteResults} items={id ? [id] : []} path="/predictions" togglePopup={togglePopup} label='this resultset'/>
                    ) : popupType === 'readacross' ? (
                        <ReadacrossPopup goBack={isReadacrossFlow ? goBack : undefined} setIsFlow={setIsReadacrossFlow} togglePopup={togglePopup} deselectItems={deselectItems} items={selectedRows} />
                    ) : popupType === 'chemical' ? (
                        <ChemicalPopup setIsReadacrossFlow={setIsReadacrossFlow} info={popupInfo} isConfPred={singleResult && singleResult.conf_pred ? true : false} setPopupType={setPopupType} addSingleItem={addSingleItem} togglePopup={togglePopup} limits={limits}  id={moleculeID} records={modifiedRecords}/>
                    ) : popupType === 'success' ? (
                        <SuccessPopup togglePopup={togglePopup} type="visualizations"/>
                    ) : popupType === 'delete-vis' ? (
                        <DeletePopup isChecked={false} toggleChecked={() => {}} deselectItems={() => setSelectedPlots([])} deleteFunc={deleteSingleVisualizations} items={selectedPlots} togglePopup={togglePopup} label={`${selectedPlots.length > 1 ? 'these visualizations' : 'this visualization'}`}/>
                    ) : popupType === 'delete-all-vis' ? (
                        <DeletePopup isChecked={false} toggleChecked={() => {}} deleteFunc={deleteAllVis} items={id ? [id] : []} togglePopup={togglePopup} label='all visualizations for this resultset'/>
                    ) : popupType === 'table-info' ? (
                        <TableLegend togglePopup={togglePopup} isConformal={isConformal}/>
                    ) : (
                        <CreateVisPopup togglePopup={togglePopup} setPopupType={setPopupType}/>
                    )}
                </Overlay>
            )}

        </div>
    )
}