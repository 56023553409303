
import CopyIcon from '../../assets/copy.svg';
import DarkCopyIcon from '../../assets/copy-06.svg';
import { MouseEvent, useState } from 'react';
import { Tooltip } from '@mui/material';
import CheckIcon from '../../assets/darkCheck.svg';

export default function CopySmilesButton ({ identifier, setPopupMessage, iconOnly, customWidth }: {
    identifier: string,
    setPopupMessage?: (message: string, isSuccess: boolean) => void,
    iconOnly: boolean,
    customWidth?: string,
}) {

    const [ isCopied, setIsCopied ] = useState(false);

    const showCheck = async () => {
        setIsCopied(true);

        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    }

    const copyToClipboard = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (identifier) {
            if (!navigator.clipboard) {
                // Clipboard API not available, fallback method
                fallbackCopyToClipboard(identifier);
                return;
            }
            navigator.clipboard.writeText(identifier).then(function() {
                console.log('Copied to clipboard successfully!');
                setPopupMessage && setPopupMessage('Copied to clipboard successfully!', true);
                showCheck();
            }, function(err) {
                console.error('Could not copy text: ', err);
                setPopupMessage && setPopupMessage('There was an error copying to clipboard', false)
            });
        }
        
    }
    
    function fallbackCopyToClipboard(text: string) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
    
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
    
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
            setPopupMessage && setPopupMessage('Copied to clipboard successfully!', true)
            showCheck();
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            setPopupMessage && setPopupMessage('There was an error copying to clipboard', false)
        }
    
        document.body.removeChild(textArea);
    }

    return (
        <>
        {iconOnly ? (
            <Tooltip title="Copy Smiles" placement='bottom'>
                <button className='highlight flex justify-center items-center' onClick={copyToClipboard}>
                    {isCopied ? <img style={{width: customWidth ? customWidth : '16px'}} src={CheckIcon} alt='check-icon' className='aspect-square' /> : <img style={{width: customWidth ? customWidth : '16px'}} src={DarkCopyIcon} alt='copy-icon' className="aspect-square" />}
                </button>
            </Tooltip>
        ) : (
            <button onClick={copyToClipboard} className="flex justify-center items-center gap-2 border border-secondary py-2 px-4 rounded text-secondary hover:text-white hover:bg-secondary transition-all lighten">
                <img src={CopyIcon} alt='copy-icon' className="w-[16px]" />
                <p className="font-medium text-[14px]">Copy SMILES</p>
            </button>
        )}
        </>
        
    )
}