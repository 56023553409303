import { useState, useEffect } from "react";
import API from '../api';

const useRenderMolecule = ({ id, size, hasMoleculeID }: {
    id?: string,
    size: number,
    hasMoleculeID?: boolean,
}) => {
    const [ isNotFound, setIsNotFound ] = useState(false);
    const [ src, setSrc ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);


    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
            if (authKey) {

                const fetchImageBlob = async (id: string) => {
                    setIsLoading(true);

                    try {
                        const res = await API.get(`render/${id}?size=${size}`,  { headers: { 'X-Auth': authKey }, responseType: 'blob' });
                        const data = await res.data;
                        const blob = new Blob([data], { type: 'image/svg+xml' });
                        const blobUrl = URL.createObjectURL(blob);
                        setSrc(blobUrl);
                        setIsNotFound(false);
                    } catch (err:any) {
                        console.log(err);
                        setSrc('');
                        setIsNotFound(true);
                    };

                    setIsLoading(false);

                }


                const fetchImageFromSmiles = async (id: string) => {
                    setIsLoading(true);

                    try {
                        const res = await API.get(`rendersmiles/${id}?size=150`, { headers: { 'X-Auth': authKey }, responseType: 'blob' })
                        const data = await res.data;
                        const blob = new Blob([data], { type: 'image/svg+xml' });
                        const blobUrl = URL.createObjectURL(blob);
                        setSrc(blobUrl);
                        setIsNotFound(false);
                    } catch(err:any) {
                        console.log(err);
                        setSrc('');
                        setIsNotFound(true);
                    }

                    setIsLoading(false);
                    
                }

                if (id) {
                    if (hasMoleculeID !== undefined && !hasMoleculeID) {
                        fetchImageFromSmiles(id);
                    } else {
                        fetchImageBlob(id);
                    }
                } else {
                    setSrc('');
                }
                
            }
        
    }, [id, hasMoleculeID, size]);

    return {
        isNotFound, 
        src,
        isLoading,
    }
}

export default useRenderMolecule;