import { Header, Limit } from "../../types/all.types";
// import { useState, useEffect } from "react";
import { getDomainColor, getThresholdValue } from "../../data/functions";

const methods = ['DL', 'ada', 'bnb', 'knn', 'lreg', 'rf', 'svc', 'xgb'];

const getModeColor = (modeLabel: string)  => {
    if (modeLabel === 'Out of Domain') {
        return { color: '#e0e0e0', label: 'Out of Domain' }
    } else if (modeLabel === 'Inconclusive') {
        return { color: '#FFC061', label: 'Inconclusive' }
    } else if (modeLabel === 'Inactive') {
        return { color: '#FF8484', label: 'Inactive' }
    } else {
        return { color: '#7BC960', label: 'Active' }
    }
}

export default function ConformalRow({ item, threshold, limits, headerObj, itemKey }: {
    item: any,
    itemKey: string,
    headerObj: Header,
    limits?: Limit[],
    threshold: number,
}) {


    const getMode = (item:any, threshold: number) => {
        let arr: string[] = [];
        let frequency: {[key:string]: number} = {};

        Object.keys(item).forEach(key => {
            if (methods.includes(key)) {
                const { label } = getThresholdValue(item[key], threshold);
                arr.push(label);
            }
        });

        arr.forEach(field => {
            const label = Object.keys(frequency).find(key => key === field);
            if (!label) {
                frequency[field as keyof object] = 1;
            } else {
                frequency[label as keyof object] = frequency[label as keyof object]+1;
            }
        })


        let maxValue = 0;
        let maxKey = null;
        let duplicates: string[] = [];

        for (const [key, value] of Object.entries(frequency)) {
            if (value > maxValue) {
                maxValue = value;
                maxKey = key;
            }
        }

        for (const [key, value] of Object.entries(frequency)) {
            if (value === maxValue) {
                duplicates.push(key);
            }
        };

        

        if (maxKey && duplicates.length <= 1) {
            return getModeColor(maxKey);
        } else if (maxKey && duplicates.length > 1) {
            if (duplicates.includes('Out of Domain')) {
                return { color: '#e0e0e0', label: 'Out of Domain' }
            } else if (duplicates.includes('Inconclusive')) {
                return { color: '#FFC061', label: 'Inconclusive' }
            } else if (duplicates.includes('Inactive')) {
                return { color: '#FF8484', label: 'Inactive' }
            } else {
                return { color: '#7BC960', label: 'Active' }
            }
        } else {
            return { color: '#e0e0e0', label: 'Out of Domain' }
        }
    }


    


    if (methods.includes(headerObj.label)) {
        const { color, label } = getThresholdValue(item[itemKey], threshold);
        return (
            <td style={{backgroundColor: `${color}`}} className={`text-primary py-4 px-6 text-center ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{label}</td>
        )
    } else if (headerObj.label === 'mode') {
        const { color, label } = getMode(item, threshold);
        return (
            <td style={{backgroundColor: `${color}`}} className={`text-primary py-4 px-6 text-center ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{label}</td>
        )
    } else if (headerObj.label === 'duplicate') {
        return (
            <td className={`py-4 px-6 text-center relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'} bg-none`}>
                {item[itemKey] === '1.00' ? 'True' : 'False'}
            </td>
        )
    } else if (headerObj.label === 'ad-score') {
        const { bg, text } = getDomainColor({ value: 'ad-score', min: 0, max: 1, isReversed: false }, item[itemKey]);
            return (
                <td style={{ backgroundColor:  bg, color: text}} className={`py-4 px-6 text-center relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                    {item[itemKey]}
                </td>
            )
    } else {
        return (
            <td className={`py-4 px-6 text-center text-nowrap break-words ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{item[itemKey] === undefined ? 'N/A' : item[itemKey]}</td>
        )
    }
}