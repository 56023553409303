// hooks
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// data

// functions
import { getPredictions } from "../../../store/slices/predictions.slice";
import { selectPredictions } from "../../../store/selectors/predictions.selector";
import API from '../../../api';

// types
import { Prediction } from "../../../types/predictions.types";


// components
import MakePredictionDropdown from "../components/MakePredictionDropdown"
import PageHeader from "../../../components/Page/PageHeader"
import SeparatePredictions from "./SeparatePredictions";
import { useNavigate } from "react-router-dom";
import usePrivileges from "../../../hooks/usePrivileges";
import useMethods from "../../../hooks/useMethods";
import PageTabs from "../../../components/Page/PageTabs";

export default function Predictions() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const predictions = useSelector(selectPredictions);
    const { regressionMethods, classificationMethods } = useMethods();
    const { canPredict } = usePrivileges();
    const [ classificationPredictions, setClassificationPredictions ] = useState<Prediction[]>([]);
    const [ regressionPredictions, setRegressionPredictions ] = useState<Prediction[]>([]);
    const [ multiPredictions, setMultiPredictions ] = useState<Prediction[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ selectedTab, setSelectedTab ] = useState('Classification');

    

    useEffect(() => {

        const fetchResults = async () => {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {

                setIsLoading(true);

                try {

                    const response = await API.get(`resultsets`, { headers: { 'X-Auth': authKey } });
                    const data: Prediction[] = await response.data;
                    dispatch(getPredictions(data));


                } catch (err: any) {

                    console.log(err);
                    navigate('/500');
                    
                }

                setIsLoading(false);
                
            } else {
                navigate('/401');
            }
            
        }

        fetchResults();
        //eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (predictions && predictions.length > 0) {
            let classification: Prediction[] = [];
            let regression: Prediction[] = [];
            let multi: Prediction[] = [];

            predictions.forEach(prediction => {
                let mapping = prediction.fields_mapping;
                let modelArr: string[] = [];
                let methodArr: string[] = [];

                mapping.forEach(m => {
                    if (m.type === 'predicted-value') {
                        modelArr.push(m.name.split('/')[0])
                        methodArr.push(m.name.split('/')[1]);
                    }
                })

                if (!methodArr.length) return;

                let noDuplicates = Array.from(new Set(modelArr));
                // let ad = mapping.find(map => map.type === 'ad-score');
                if (noDuplicates.length > 1) {
                    let hasRegression = false;
                    let hasClassification = false;

                    for (let m of regressionMethods) {
                        if (methodArr.includes(m)) {
                            hasRegression = true;
                            break;
                        }
                    };

                    for (let m of classificationMethods) {
                        if (methodArr.includes(m)) {
                            hasClassification = true;
                            break;
                        }
                    };

                    if (hasClassification && hasRegression) {
                        multi.push(prediction)
                    } else if (hasClassification) {
                        classification.push(prediction);
                    } else {
                        regression.push(prediction);
                    }
                    
                } else {
                    let isRegression = false;
                    for (let m of regressionMethods) {
                        if (methodArr.includes(m)) {
                            regression.push(prediction);
                            isRegression = true;
                            break;
                        }
                    };

                    if (!isRegression) {
                        for (let m of classificationMethods) {
                            if (methodArr.includes(m)) {
                                classification.push(prediction);
                                break;
                            }
                        }
                    }
                }
            });



            setClassificationPredictions(classification);
            setRegressionPredictions(regression);  
            setMultiPredictions(multi)
        }
    }, [predictions, classificationMethods, regressionMethods]);



    return (
        <div style={{maxHeight: 'calc(100vh - 70px)'}} className="h-full overflow-hidden flex flex-col justify-start items-stretch gap-4">

            <PageHeader label='Predictions'>
                {canPredict && <MakePredictionDropdown />}
            </PageHeader>

            <PageTabs items={['Classification', 'Regression', 'Mixed']} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>

            {selectedTab === 'Classification' ? (
                <SeparatePredictions isLoading={isLoading} predictions={classificationPredictions} isRegression={false} isMulti={false}/>
            ) : selectedTab === 'Regression' ? (
                <SeparatePredictions isLoading={isLoading} predictions={regressionPredictions} isRegression={true} isMulti={false}/>
            ) : (
                <SeparatePredictions isLoading={isLoading} predictions={multiPredictions} isRegression={false} isMulti={true}/>
            )}
        </div>
    )
}