import { getDomainColor, getPredictionColor, getTimezoneOffset } from "../../data/functions";
import { Header, Limit } from "../../types/all.types";
import Active from '../../assets/green-check.svg';
import Inactive from '../../assets/red-x.svg';

export default function ColorRows({ item, itemKey, limits, isSelected, headerObj}: {
    item: any,
    itemKey: string,
    isSelected: boolean,
    headerObj: Header,
    limits?: Limit[],
}) {



    if (!isNaN(Number(item[itemKey]))) {
        const limitsObj = limits && limits.find(l => l.value === itemKey);
        if (!limitsObj) {
            if (typeof item[itemKey] === 'boolean') {
                return (
                    <td className={`py-4 px-6 relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                        {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                        <div className="flex justify-center items-center w-full">
                        {item[itemKey] === true ? (
                            <img src={Active} alt='active-status-icon' />
                        ) : (
                            <img src={Inactive} alt='inactive-status-icon'/>
                        )} 
                        </div>
                    </td>
                )
            } else {
                return (
                    <td className={`py-4 px-6 text-center bg-none relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                        {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                        {item[itemKey]}
                    </td>
                )
            }
            
        } else if (headerObj.label === 'ad-score') {
            const { bg, text } = getDomainColor(limitsObj, item[itemKey]);
            return (
                <td style={{ backgroundColor:  bg, color: text}} className={`py-4 px-6 text-center relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey]}
                </td>
            )
        } else if (headerObj.label === 'duplicate') {                            
            return (
                <td className={`py-4 px-6 text-center relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'} bg-none`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey] === '1.00' ? 'True' : 'False'}
                </td>
            )

        } else if (['mae', 'rmse'].includes(itemKey)) {
            return (
                <td className={`text-primary py-4 px-6 text-center bg-[#e0e0e0] relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey]}
                </td>
            )
        } else {
            const bg = getPredictionColor(limitsObj, item[itemKey]);
            return (
                <td style={{ backgroundColor:  bg}} className={`py-4 px-6 text-center relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey]}
                </td>
            )
        }
    } else {
        return (
            <td className={`py-4 px-6 text-center text-nowrap break-words relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                {item[itemKey]} {headerObj.value === 'date_created' ? getTimezoneOffset(item[itemKey]) : ''}
            </td>
        )
        
    }
}