import { MouseEvent, useState, useEffect, Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { PopupType } from "../usePopup";


import { setSelectedFile } from "../../store/slices/files.slice";
import { addDataset, setSelectedDataset } from "../../store/slices/datasets.slice";
import { cleanFileName } from "../../data/functions";

import { CreateModelForm } from "../../types/models.types";

import API from '../../api';
import { CreateDatasetForm, Dataset } from "../../types/datasets.types";
import { FieldMappingForm } from "../../types/all.types";
import { FieldMappingType } from "./useCreateDatasetHandlers";


const defaultForm: CreateModelForm = {
    ds_ids: [],
    name: '',
    label_fields: [],
    descriptors: [
        {
            provider: 'RDKit',
            name: 'ECFP',
            params: {
                Radius: '3',
                Bits: '1024',
            }
        }
    ],
    methods: [],
    hyper_params: {
        DL: {
        dropout: 0.5,
        beta: 0.01,
        }
    },
    test_set_size: 0,
    conf_pred: false,
    resample_method: null,

};

const useCreateNewModelHandler = ({ setPopupType, togglePopup, selectedType, datasetForm, fieldsMapping, activityColumn, setPopupMessage }: {
    setPopupType: Dispatch<SetStateAction<PopupType>>,
    togglePopup: (e:any) => void,
    selectedType: string,
    datasetForm: CreateDatasetForm,
    fieldsMapping: FieldMappingForm,
    activityColumn: string,
    setPopupMessage: (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {
    const dispatch = useDispatch();
   const { search } = useLocation();
   const [ query, setQuery ] = useState('');
   const [ prevQuery, setPrevQuery ] = useState('');
   const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
   const [ form, setForm ] = useState<CreateModelForm>(defaultForm);
   const [ isDisabled, setIsDisabled ] = useState(true);
   const [ isLoading, setIsLoading ] = useState(false);

   
   
    const resetValues = () => {
        dispatch(setSelectedFile(null));
        dispatch(setSelectedDataset(null));
        setForm(defaultForm);
    }

   useEffect(() => {
    const queryArr = search.slice(1).split('&');
    const q = queryArr[0].split('=')[1];
    setQuery(q);

    if (prevQuery && prevQuery !== q) {
        resetValues();
    }

    setPrevQuery(q);


    setRoutes([
        {
            label: 'Models',
            path: 'models',
        },
        {
            label: 'Train New Model',
            path: `models/create-new-model?dataset=${q}`,
        }
    ])
    //eslint-disable-next-line
   }, [search]);

   useEffect(() => {
    if (!form.name || !form.descriptors.length || !form.hyper_params.DL.beta || !form.hyper_params.DL.dropout|| !form.methods.length || !form.label_fields.length) {
        setIsDisabled(true);
    } else {
        setIsDisabled(false);
    }
   }, [form]);

   const resetFile = (e:MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setSelectedFile(null));
    setForm(defaultForm);
   }

   const resetDataset = (e:MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setSelectedDataset(null));
    setForm(defaultForm);
   };

   const resetUploadedDataset = (e:MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setSelectedDataset(null));
    setForm(defaultForm);
   };

const handleDatasetCreation = async (e:MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);


    let fields: FieldMappingForm = {
        'chem-name': fieldsMapping['chem-name'],
        'chem-id': fieldsMapping['chem-id'],
        'continuous-value': fieldsMapping['continuous-value'],
        'single-class-label': fieldsMapping['single-class-label'],
        'split-on-value': fieldsMapping['split-on-value'],
    };

    (fieldsMapping['non-activity'] as FieldMappingType[]).forEach((field, i) => {
        fields[`non-activity-${i}` as keyof object] = {...field};
    })

    const values = ['continuous-value', 'single-class-label', 'split-on-value'];
    let mapping: FieldMappingType[] = [];
    Object.keys(fields).forEach(key => {
        if (fields[key] !== null) {
            if (values.includes(key)) {
                mapping.push({ ...fields[key], name: activityColumn } as FieldMappingType)
            } else {
                mapping.push(fields[key] as FieldMappingType)
            }
            
        }
    }) 

    const newForm: CreateDatasetForm = {...datasetForm, name: cleanFileName(datasetForm.name),  fields_mapping: mapping};
    const authKey = localStorage.getItem('X-Auth');

    if (authKey) {
        try {

            const response = await API.post('datasets', newForm, { headers: { 'X-Auth': authKey } });
            const data: Dataset = await response.data[0];

            if (data.records_number > 0) {

                dispatch(addDataset(data));
                const status = await handleSubmitAfterDatasetCreation(e, data._id.$oid);
                if (status === 200) {
                    togglePopup(e);
                    setPopupType('success');
                }

            } else {

                try {
                    const getResponse = await API.get(`datasets/${data._id.$oid}`, { headers: { 'X-Auth': authKey } });
                    const fetchedDataset: Dataset = await getResponse.data;
    

                    if (fetchedDataset.records.length) {
    
                        dispatch(addDataset(data));
                        const status = await handleSubmitAfterDatasetCreation(e, data._id.$oid);
                        if (status === 200) {
                            togglePopup(e);
                            setPopupType('success');
                        }
    
                    } else {
                        setPopupMessage('The dataset you created contains no records. Please try again.', false);
                    }
                } catch (err:any) {
                    console.log(err);
                    setPopupMessage('We encountered an issue retrieving your dataset. Please try again.', false);
                }

                

                

            }
            

        } catch (err: any) {
            console.log(err);
            setPopupMessage('We encountered an issue creating your dataset. Please try again.', false);

        }

       
    }

    setIsLoading(false);
    setIsDisabled(false);

};

const handleSubmitAfterDatasetCreation = async (e: any, id: string) => {
    const newForm: CreateModelForm = {...form, ds_ids: [id]};

    const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {

                const response = await API.post('models', {...newForm, name: cleanFileName(newForm.name)}, { headers: { 'X-Auth': authKey } });
                return response.status;
                

            } catch (err:any) {

                console.log(err);
                setPopupMessage('We encountered an issue training your model. Please try again.', false);

            }
        }
}

   const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);

        

        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {

                const response = await API.post('models', {...form, name: cleanFileName(form.name)}, { headers: { 'X-Auth': authKey } });
                const status = response.status;
                if (status === 200) {
                    togglePopup(e);
                    setPopupType('success');
                }

            } catch (err:any) {

                console.log(err);
                setPopupMessage('We encountered an issue training your model. Please try again.', false);

            }
        }

        setIsLoading(false);
        setIsDisabled(false);
   };



    const next = (e:MouseEvent<HTMLButtonElement>) => {
        if (selectedType === 'new-file') {
            setPopupType('upload-file');
        } else if (selectedType === 'existing-file') {
            setPopupType('existing-file');
        } else if (selectedType === 'compound-list') {
            setPopupType('upload-file');
        }
    };

    const goBack = (e:MouseEvent<HTMLButtonElement>) => {
        setPopupType('select-file-type');
    };

    return {
        query,
        routes, 
        isDisabled,
        isLoading,
        togglePopup,

        next,
        goBack,
        resetFile,
        resetDataset,
        resetUploadedDataset,
        resetValues,
        handleSubmit,
        handleDatasetCreation,

        form, 
        setForm,

    }
};

export default useCreateNewModelHandler;