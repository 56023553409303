import { useState, useEffect, Dispatch, SetStateAction } from "react";

import { defaultResultsFilter, defaultResultsFilterFields, predictionsHeader, setupResults } from "../../data/predictions";
import API from '../../api';


import { FilterFieldsType, FilterType, Header } from "../../types/all.types";
import { filterItems, searchItems, sortByColumn } from "../../data/functions";
import { useDispatch } from "react-redux";
import { ParsedPrediction, Prediction } from "../../types/predictions.types";
import { deleteExistingResults, getPredictions, updateExistingResult } from "../../store/slices/predictions.slice";
import { download } from "../../data/functions";
import { useNavigate } from "react-router-dom";


const useResults = ({ searchInput, filterObj, parsedPredictions, setPopupMessage }: { 
    searchInput: string, 
    filterObj?: FilterType, 
    parsedPredictions?: ParsedPrediction[], 
    setPopupMessage?: (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {
    const [ allResults, setAllResults ] = useState<ParsedPrediction[]>([]);
    const [ modifiedResults, setModifiedResults ] = useState<ParsedPrediction[]>([]);
    const [ allResultsIDs, setAllResultsIDs ] = useState<string[]>([]);


    const [ headers, setHeaders ] = useState<Header[]>([]);

    const [ filterFields, setFilterFields ] = useState<FilterFieldsType>(defaultResultsFilterFields);
    const [ filter, setFilter ] = useState<FilterType>(defaultResultsFilter);


    const [ isLoading, setIsLoading ] = useState(true);
    const navigate = useNavigate();




    const dispatch = useDispatch();
    


    useEffect(() => {
        setIsLoading(true);

        const fetchResults = async () => {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {

                try {

                    const response = await API.get(`resultsets`, { headers: { 'X-Auth': authKey } });
                    const data: Prediction[] = await response.data;
                    dispatch(getPredictions(data));
                    const preds = setupResults(data);

                    setAllResults(preds);
                    setModifiedResults(preds);

                } catch (err: any) {

                    console.log(err);
                    navigate('/500');
                    
                }
                
            } else {
                navigate('/401');
            }
            
        }

        if (parsedPredictions === undefined) {
            fetchResults();
        } else {
            setAllResults(parsedPredictions);
            setModifiedResults(parsedPredictions);
        }

        setIsLoading(false);
        //eslint-disable-next-line
    }, [dispatch, parsedPredictions]);


    useEffect(() => {
        if (allResults.length > 0) {
            const records = allResults.map(file => file.records);
            const created_by = allResults.map(file => file.created_by);
            const access = allResults.map(file => file.access);
            setFilterFields({
                record: Array.from(new Set(records)).filter(a => a),
                created_by: Array.from(new Set(created_by)).filter(a => a),
                access: Array.from(new Set(access)).filter(a => a),
            })
        }
      
        
    }, [allResults]);

    useEffect(() => {
        if (modifiedResults) {
            setAllResultsIDs(modifiedResults.map(result => result.id))
        }
    }, [modifiedResults])


    const modifyResults = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>, filter?: FilterType) => {
        const searchArr = searchItems(searchInput, allResults);
        const sortedArr = sortByColumn(selectedHeader, headers, false, searchArr, updateHeaders);
        const filteredArr = filterItems(filter!, sortedArr);
        setModifiedResults(filteredArr);
    }


    const updateResultPermissions = async (ids: string[], owner: string, access: string) => {
        const authKey = localStorage.getItem('X-Auth');

        const promises = ids.map(async (id) => {
            const aclObj = {owner, access, read: [], write: []};
            return API.put(`resultsets/${id}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                .then(() => API.get(`resultsets/${id}`,  { headers: { 'X-Auth': authKey } }))
                .then(res => dispatch(updateExistingResult(res.data)));
        });

        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage(`The ${ids.length > 1 ? 'results you selected were' : 'result you selected was'} updated successfully!`, false);
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error updating ${ids.length > 1 ? 'these results' : 'this result'}`, false);
            }
        }
    };

    const deleteResults = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`resultsets/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingResults(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage(`The ${ids.length > 1 ? 'results you selected were' : 'result you selected was'} deleted successfully`, false);
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error deleting ${ids.length > 1 ? 'these resultsets' : 'this resultset'}`, false)
            }
        }
    }

    const downloadResultset = async (filename: string, id: string, ext:string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`resultsets/${id}/download?format=0.8-allStats-${ext}`, { headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                download(filename, data);
                setPopupMessage && setPopupMessage('The result you selected was successfully exported!', true);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error downloading this resultset`, false)
            }
        }
    }
    



    return {
        allResults,
        modifiedResults,
        modifyResults,
        filterFields,
        filter,
        setFilter,
        headers,
        setHeaders,
        deleteResults,
        downloadResultset,
        isLoading,
        updateResultPermissions,
        allResultsIDs,

    }

}

export default useResults;