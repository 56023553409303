import { Dispatch, ReactNode, SetStateAction, MouseEvent } from "react";
import ChevronLeft from '../../assets/chevron-left-black.svg';
import ChevronRight from '../../assets/chevron-right-black.svg';
import { useNavigate } from "react-router-dom";
import Camera from '../../assets/camera.svg';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
import { selectTheme } from "../../store/selectors/auth.selector";

export default function DataRepresentationBox({children, isFirst, setIsFirst, label, isLoading, path, isDashboard, downloadFunc }: {
    children: ReactNode,
    isFirst?: boolean,
    setIsFirst?: Dispatch<SetStateAction<boolean>>,
    label: string,
    isLoading?: boolean,
    path?: string,
    isDashboard?: boolean,
    downloadFunc?: (e:MouseEvent<HTMLButtonElement>) => void,
}) {
    
    const navigate = useNavigate();
    const theme = useSelector(selectTheme);


    return (
        <div className={`w-full rounded ${theme === 'dark' ? 'bg-dark-accent' : 'bg-white'} border border-[#CBCEDC] flex flex-col gap-4 h-full ${!isDashboard && 'p-8'}`}>
            <div className={`w-full flex justify-between items-center ${isDashboard && 'px-8 pt-8 pb-4'}`}>
                <p className='text-[1.5rem] font-semibold text-nowrap'>{label}</p>
                {isFirst !== undefined && setIsFirst !== undefined && (
                    <div className='flex justify-center items-center rounded bg-darker-background shadow-inner'>
                        <button disabled={isFirst ? true : false} onClick={(e:any) => setIsFirst(true)} className={`${isFirst ? 'border-quaternary' : 'border-secondary'} border-t border-b border-l darken-icons flex justify-center items-center px-3 py-1 rounded-tl rounded-bl h-full relative toggle-btn`}>
                            {!isFirst && <div className='absolute top-0 left-0 right-0 bottom-0' />}
                            <img src={ChevronLeft} alt='left-arrow' className='w-[14px]' />
                        </button>
                        <button disabled={isFirst? false : true} onClick={(e:any) => setIsFirst(false)} className={`${!isFirst ? 'border-quaternary' : 'border-secondary'} border darken-icons flex justify-center items-center px-3 py-1 rounded-tr rounded-br h-full relative toggle-btn`}>
                            {isFirst && <div className='absolute top-0 left-0 right-0 bottom-0' />}
                            <img src={ChevronRight} alt='right-arrow' className='w-[14px]' />
                        </button>
                    </div>
                )}
                {path && (
                    <button onClick={(e:any) => navigate(path)} className="transition-all lighten flex gap-2 justify-start items-center py-2 px-4 border border-tertiary rounded text-tertiary hover:text-white hover:bg-tertiary font-medium">
                        {/* <img src={Redirect} className="w-[16px]" alt='redirect-icon'/> */}
                        <p>View All</p>
                    </button>
                )}
                {downloadFunc && (
                    <button onClick={(e:MouseEvent<HTMLButtonElement>) => downloadFunc(e)} className="lighten transition-all py-2 px-4 rounded border-2 border-secondary text-secondary hover:bg-secondary hover:text-white font-semibold flex justify-center items-center gap-2">
                        <p>Screenshot</p>
                        <img src={Camera} alt='screenshot-icon' className="w-[16px]"/>
                    </button>
                )}
            </div>
            {isLoading !== undefined && isLoading ? (
                <Skeleton/>
            ) : (
                <>{children}</>
            )}
        </div>
    )
}