

// components
import EnableSelectionButton from "../../../components/Buttons/EnableSelectionButton";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import JobRows from '../../../components/Table/JobTableRows';
import PageHeader from "../../../components/Page/PageHeader";
import Pagination from '../../../components/Page/Pagination';
import FilterPopup from '../../../components/Popups/FilterPopup';
import DeletePopup from "../../../components/Popups/DeletePopup";
import EmptyTable from "../../../components/Table/EmptyTable";



// functions + data
import { defaultJobsFilter } from "../../../data/jobs";

// hooks
import { useState } from "react"
import useEnableSelection from "../../../hooks/useEnableSelection";
import usePagination from '../../../hooks/usePagination';
import usePopup from '../../../hooks/usePopup';

// types
import TableControls from '../../../components/Table/TableControls';
import FilterButton from '../../../components/Buttons/FilterButton';
import useJobs from "../../../hooks/pageHooks/useJobs";
import SnackBar from "../../../components/Alerts/SnackBar";
import useMessage from "../../../hooks/useMessage";
import TableBase from "../../../components/Table/TableBase";
import Overlay from "../../../components/Popups/Overlay";
import useModifyTableItems from "../../../hooks/useModifyTableItems";



export default function JobQueue() {
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');

    const {
        open,
        setOpen,
        message,
        setPopupMessage,
        isSuccess,
    } = useMessage();

    const {
        groupData,
        modifiedJobs,
        modifyJobs,
        filterFields,
        filter,
        setFilter,
        headers,
        setHeaders,
        associatedItems,
        isLoading,
        deleteJobs,
        selectAllGroups,
        selectAllItemsInGroup,
        selectGroupForDeletion,
        selectSingleItemInGroup,
        deselectItems,
        allGroupsAreSelected,
        selectedGroups,
        selectSingleJobForDeletion,
    } = useJobs({setPopupMessage, searchInput, selectedHeader: !selectedHeader ? undefined : selectedHeader});

    const {
        selectHeaders,
        setSortedColumn,
        applyFilter,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyJobs, setHeaders, filter, setFilter, searchInput, selectedHeader, setSelectedHeader })
    

    const { 
        handleChange, 
        isChecked, 
        toggleChecked, 
    } = useEnableSelection({allItems: undefined});


    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedJobs, count: 50});

    const { 
        setPopupType,
        popupType,
        togglePopup,
        isPopupOpen
    } = usePopup();





    return (
        <div style={{maxHeight: 'calc(100vh - 70px)'}} className="h-full overflow-hidden flex flex-col justify-start items-stretch gap-4">


            <PageHeader label='Job Queue'></PageHeader>

            <>
            {/* Search Bar & Buttons Component */}
            <TableControls applySearch={applySearch} identifier="Name" toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={searchInput} selectedRows={selectedGroups}>
                <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                <FilterButton filter={filter} toggleFilterPopup={togglePopup} setPopupType={setPopupType}/>
                <EnableSelectionButton isChecked={isChecked} handleChange={handleChange} />
            </TableControls>

            <>
                {isLoading || paginatedItems.length > 0 ? (
                    <>
                    <TableBase
                        isLoading={isLoading}
                        isChecked={isChecked}
                        isPopupOpen={false}
                        selectAllRows={selectAllGroups} 
                        allAreSelected={allGroupsAreSelected} 
                        hasOptions={isLoading ? false : true} 
                        headers={headers} 
                        selectedHeader={selectedHeader} 
                        setSortedColumn={setSortedColumn}
                    >
                        {paginatedItems && paginatedItems.map(group => {
                            const obj = associatedItems.find(item => item.groupID === group.id);
                            const selectedGroup = selectedGroups.find(g => g.groupID === group.id);
                            if (obj) {    
                                return (
                                    <JobRows 
                                    setGroupForDeletion={selectGroupForDeletion} 
                                    selectSingleJobForDeletion={selectSingleJobForDeletion}
                                    togglePopup={togglePopup} 
                                    setPopupType={setPopupType}
                                    selectedGroup={selectedGroup} 
                                    selectAllItemsInGroup={selectAllItemsInGroup} 
                                    selectSingleItemInGroup={selectSingleItemInGroup} 
                                    groupData={group} 
                                    associatedItems={obj.items} 
                                    headers={headers} 
                                    isChecked={isChecked} 
                                    />
                                )
                            } else return null;
                        })}
                    </TableBase>
                    {paginatedItems.length > 0 && (
                        <Pagination count={50} total={groupData.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                        firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                    )}
                    </>
                ) : (
                    <EmptyTable subject="Your job queue is currently empty" searchInput={searchInput} />
                )}
            </>
            
            </>

            <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/> 
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup isChecked={isChecked} toggleChecked={toggleChecked} groups={selectedGroups} deselectItems={deselectItems} deleteFunc={deleteJobs} togglePopup={togglePopup} label={`${selectedGroups.length > 1 ? 'these jobs' : 'this job'}`}/>
                    ) : (
                        <FilterPopup itemsFilter={filter} setItemsFilter={setFilter} searchInput={searchInput} togglePopup={togglePopup} applyFilter={applyFilter} defaultFilter={defaultJobsFilter} fieldsObj={filterFields} />
                    )}
                </Overlay>
            )}
    
        </div>
    )
}