import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { VisualizationGroupType, VisualizationType } from "../../../../types/predictions.types";
import VisualizationBox from "./VisualizationBox";
import NoVisualization from '../../../../assets/noVisualizations.png';
import DataRepresentationBox from "../../../../components/Data/DataRepresentationBox";
import { PopupType } from "../../../../hooks/usePopup";

export default function Visualizations({ visualizations, isLoading, setPopupType, togglePopup, setSelectedPlots, setPopupMessage }: {
    visualizations: VisualizationType[],
    isLoading: boolean,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
    togglePopup: (e:any) => void,
    setSelectedPlots: Dispatch<SetStateAction<string[]>>,
    setPopupMessage: (message: string, isSuccess: boolean) => void,
}) {
    const [ visualizationGroups, setVisualizationGroups ] = useState<VisualizationGroupType[]>([]);


    useEffect(() => {
        if (visualizations.length > 0) {
            let groups: VisualizationGroupType[] = [];
            visualizations.forEach(visualization => {
                if (!visualization.fingerprint.params.nBits) {
                    const existingGroupByFingerprint = groups.find(g => g.fingerprint.name === visualization.fingerprint.name);
                    if (!existingGroupByFingerprint) {
                        groups.push({
                            fingerprint: visualization.fingerprint,
                            resultset: visualization.resultset,
                            resultset_name: visualization.resultset_name,
                            visualization_methods: [{
                                unique_id: visualization.unique_id,
                                _id: visualization._id,
                                visualization_method: visualization.visualization_method,
                                visualization_result: visualization.visualization_result,
                            }]
                        })
                    } else {
                        groups = groups.map(group => {
                            if (group.fingerprint.name === visualization.fingerprint.name) {
                                return {
                                    ...group,
                                    visualization_methods: group.visualization_methods.concat({
                                        unique_id: visualization.unique_id,
                                        _id: visualization._id,
                                        visualization_method: visualization.visualization_method,
                                        visualization_result: visualization.visualization_result
                                    })
                                }
                            } else {
                                return {...group}
                            }
                        })
                    } 
                } else {
                    const existingGroupWithParams = groups.find(g => 
                        g.fingerprint.name === visualization.fingerprint.name 
                        && g.fingerprint.params.nBits === visualization.fingerprint.params.nBits 
                        && g.fingerprint.params.radius === visualization.fingerprint.params.radius
                        && g.fingerprint.params.useChirality === visualization.fingerprint.params.useChirality
                    );

                    if (!existingGroupWithParams) {
                        groups.push({
                            fingerprint: visualization.fingerprint,
                            resultset: visualization.resultset,
                            resultset_name: visualization.resultset_name,
                            visualization_methods: [{
                                unique_id: visualization.unique_id,
                                _id: visualization._id,
                                visualization_method: visualization.visualization_method,
                                visualization_result: visualization.visualization_result,
                            }]
                        })
                    } else {
                        groups = groups.map(group => {
                            if (group.fingerprint.name === visualization.fingerprint.name) {
                                return {
                                    ...group,
                                    visualization_methods: group.visualization_methods.concat({
                                        unique_id: visualization.unique_id,
                                        _id: visualization._id,
                                        visualization_method: visualization.visualization_method,
                                        visualization_result: visualization.visualization_result
                                    })
                                }
                            } else {
                                return {...group}
                            }
                        })
                    } 
                }
                
            });

            setVisualizationGroups(groups);
        } else {
            setVisualizationGroups([]);
        }
    }, [visualizations]);
    
    return (
        <>
            <div className="flex flex-col gap-6">
            {isLoading ? (
                <DataRepresentationBox isLoading={isLoading} label=""><div></div></DataRepresentationBox>
            ) : visualizationGroups.length > 0 ? visualizationGroups.map(group => (
                <VisualizationBox group={group} setPopupType={setPopupType} togglePopup={togglePopup} setSelectedPlots={setSelectedPlots} setPopupMessage={setPopupMessage}/>
            )) : (
                <div className="w-full h-[500px] flex flex-col justify-center items-center gap-10 p-6">
                    <img src={NoVisualization} className="w-[200px]" alt='no-visualization'/>
                    <p className="max-w-[300px] text-center font-semibold text-quaternary text-[18px]">This resultset currently has no visualizations.</p>
                </div>
            )}
            </div>
        </>
    )
}