import { ReactNode, useState, ReactElement } from 'react';
import React from 'react';
import Dots from '../../assets/dots-vertical.svg';
import Menu from '@mui/material/Menu';

export default function IndividualOptionsDropdownBase({ children, isGrid }: { 
    children: ReactNode,
    isGrid?: boolean,
}) {
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

 

    

    return (
        <div>
            <div className={`${!isGrid && 'py-4 px-6'}`}><button onClick={handleClick} className='transition-all highlight rounded py-1'><img src={Dots} className='w-[24px]'  alt='individual-options-icon'/></button></div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    marginTop: '8px',
                    '& .MuiMenu-list': {
                        padding: 0,
                    }
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                { children = React.Children.map(children, el => {
                    return React.cloneElement(el as ReactElement, { handleClose })
                })}
            </Menu>
        </div>

    )
}