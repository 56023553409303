import { useSelector } from "react-redux";
import { selectMetadata } from "../store/selectors/auth.selector";
import { useEffect, useState } from "react";


const useMethods = () => {
    const metadata = useSelector(selectMetadata);

    const [ regressionMethods, setRegressionMethods ] = useState<string[]>([]);
    const [ classificationMethods, setClassificationMethods ] = useState<string[]>([]);

    useEffect(() => {
        if (metadata) {
            let regression: string[] = [];
            let classification: string[] = [];

            Object.keys(metadata.methods).forEach(k => {
                if (k.endsWith('r')) {
                    regression.push(k);
                } else {
                    classification.push(k);
                }
            });

            setRegressionMethods(regression);
            setClassificationMethods(classification);
        }
    }, [metadata]);

    return {
        regressionMethods,
        classificationMethods,
    }


}

export default useMethods;