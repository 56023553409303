import { useState } from "react";
import usePagination from "../../../hooks/usePagination";
import DeletePopup from '../../../components/Popups/DeletePopup';
import useEnableSelection from "../../../hooks/useEnableSelection";

import { defaultFilesFilter } from "../../../data/files";

import EnableSelectionButton from "../../../components/Buttons/EnableSelectionButton";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import PageHeader from "../../../components/Page/PageHeader";
import Pagination from "../../../components/Page/Pagination";
import UploadFile from "../components/UploadFile";
import CreateDatasetPopup from "../../Datasets/components/CreateDatasetPopup";

import FilterPopup from "../../../components/Popups/FilterPopup";
import TableControls from "../../../components/Table/TableControls";
import FilterButton from "../../../components/Buttons/FilterButton";
import useFile from "../../../hooks/pageHooks/useFile";
import EmptyTable from "../../../components/Table/EmptyTable";
import SnackBar from "../../../components/Alerts/SnackBar";
import usePrivileges from "../../../hooks/usePrivileges";
import useMessage from "../../../hooks/useMessage";
import { useSelector } from "react-redux";
import { selectApplication, selectTheme } from "../../../store/selectors/auth.selector";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";

import Plus from '../../../assets/plus.svg';
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";
import IndividualFileOptions from "../components/IndividualFileOptions";
import usePopup from "../../../hooks/usePopup";
import Overlay from "../../../components/Popups/Overlay";
import ModifyPermissionPopup from "../../../components/Popups/ModifyPermissionsPopup";
import useModifyTableItems from "../../../hooks/useModifyTableItems";


export default function Files() {
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const { canCreate } = usePrivileges();
    const application = useSelector(selectApplication);
    const theme = useSelector(selectTheme);

    const {
        popupType,
        setPopupType,
        togglePopup,
        isPopupOpen,
    } = usePopup();

    const {
        open,
        setOpen,
        message,
        isSuccess,
        setPopupMessage
    } = useMessage();

    const { 
        filterFields,
        allFileIDs,
        isLoading,
        deleteFiles,
        downloadFiles,
        downloadFile,
        updateFilePermissions,
        headers,
        setHeaders,
        filter,
        setFilter,
        modifiedFiles,
        modifyFiles
    } = useFile({ setPopupMessage });

    const {
        selectHeaders,
        setSortedColumn,
        applyFilter,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyFiles, setHeaders, filter, setFilter, searchInput, selectedHeader, setSelectedHeader })


    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedFiles, count: 50});


    const { 
        handleChange, 
        isChecked, 
        selectedRows, 
        editSelectedRows,
        selectAllRows,
        allAreSelected,
        addSingleItem,
        deselectItems,
        toggleChecked
    } = useEnableSelection({allItems: allFileIDs});
    

    

    return (
        <div style={{maxHeight: 'calc(100vh - 70px)'}} className="h-full overflow-hidden flex flex-col justify-start items-stretch gap-4">

            <PageHeader label='Files'>
                {canCreate && <button id='upload-file-button' onClick={(e:any) => {togglePopup(e); setPopupType('upload-file')}} className="flex justify-center items-center gap-3 py-3 px-5 rounded bg-tertiary hover:bg-secondary transition-all text-white whiten">
                    <img className="w-[18px]" src={Plus} alt="add-icon" />
                    <p className="font-medium">Upload File</p>
                </button>}
            </PageHeader>

            

                <TableControls applySearch={applySearch} identifier="Name" deselectItems={deselectItems} toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={searchInput} selectedRows={selectedRows} downloadFunc={downloadFiles} toggleCheck={toggleChecked}>
                    <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                    <FilterButton filter={filter} toggleFilterPopup={togglePopup} setPopupType={setPopupType} />
                    <EnableSelectionButton isChecked={isChecked} handleChange={handleChange} />
                </TableControls>
                
                {isLoading || paginatedItems.length > 0 ? (
                    <>
                        <TableBase
                            isLoading={isLoading}
                            isChecked={isChecked} 
                            selectAllRows={selectAllRows} 
                            allAreSelected={allAreSelected} 
                            hasOptions={isLoading ? false : true} 
                            headers={headers} 
                            selectedHeader={selectedHeader} 
                            setSortedColumn={setSortedColumn}
                            isPopupOpen={false}
                        >
                            {paginatedItems.length > 0 && paginatedItems.map(item => (
                                <TableRowBase
                                    selectedRows={selectedRows} 
                                    editSelectedRows={editSelectedRows} 
                                    item={item} headers={headers} 
                                    isChecked={isChecked}
                                    action={{path: 'files'}}
                                    rowType="base"
                                >
                                    <IndividualOptionsDropdownBase>
                                        <IndividualFileOptions setPopupType={setPopupType} togglePopup={togglePopup} handleClose={() => {}} application={application} downloadFunc={downloadFile} id={item.id} addSingleItem={addSingleItem}/>
                                    </IndividualOptionsDropdownBase>
                                </TableRowBase>
                            ))}
                            
                        </TableBase>
                        {paginatedItems.length > 0 && (
                            <Pagination count={50} total={modifiedFiles.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                            firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                        )}
                    </>
                ) : (
                    <EmptyTable searchInput={searchInput} message="Upload a new file" subject="No files found" />
                )}
            
            <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  

        {isPopupOpen && (
            <Overlay togglePopup={togglePopup} >
                {popupType === 'upload-file' ? (
                    <UploadFile togglePopup={togglePopup} setPopupMessage={setPopupMessage}/>
                ) : popupType === 'create-dataset' ? (
                    <CreateDatasetPopup isFlow={false} togglePopup={togglePopup} setPopupMessage={setPopupMessage}/>
                ) : popupType === 'filter' ? (
                    <FilterPopup applyFilter={applyFilter} itemsFilter={filter} setItemsFilter={setFilter} searchInput={searchInput} togglePopup={togglePopup} defaultFilter={defaultFilesFilter} fieldsObj={filterFields} />
                ) : popupType === 'delete' ?(
                    <DeletePopup isChecked={isChecked} toggleChecked={toggleChecked} deselectItems={deselectItems} deleteFunc={deleteFiles} items={selectedRows} togglePopup={togglePopup} label={`${selectedRows.length > 1 ? 'these files' : 'this file'}`}/>
                ) : (
                    <ModifyPermissionPopup toggleChecked={toggleChecked} deselectItems={deselectItems} updateFunc={updateFilePermissions} items={selectedRows} togglePopup={togglePopup} />
                )}
            </Overlay>
        )}
        </div>
    )
}