import { Dispatch, SetStateAction } from 'react';
import Plus from '../../assets/plus.svg';
import { PopupType } from '../../hooks/usePopup';

export default function CreateVisButton({ togglePopup, setPopupType }: { 
    togglePopup: (e:any) => void, 
    setPopupType: Dispatch<SetStateAction<PopupType>>,
}) {

    return (
        <button onClick={(e:any) => {togglePopup(e); setPopupType('visualizations')}} className={`whiten text-white bg-[#5943BB] hover:bg-[#362484] transition-all flex justify-center items-center gap-3 py-3 px-5 rounded`}>
            <img src={Plus} className='w-[16px]' alt='add-icon'/>
            <p className='font-medium text-nowrap'>Create Visualization</p>
        </button>
    )
}