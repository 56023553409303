import CopySmilesButton from "../../../../components/Buttons/CopySmilesButton"
import { capitalize } from "../../../../data/functions";
import TruncatedText from "../../../../components/Page/TruncatedText";

export default function SelectedMoleculeInfo({ smiles, setType, name, color }: {
    smiles: string,
    setType: string,
    name: string,
    color: string,
}) {


    return (
        <>
            <div className="w-full flex justify-between items-center gap-4 bg-[#e8e8e8] py-4 px-5">
                <TruncatedText alignment="left" text={smiles}>
                    <p className="font-semibold text-primary max-w-[300px] truncate">{smiles}</p>
                </TruncatedText>
                <CopySmilesButton identifier={smiles} iconOnly={true}/>
            </div>
            <div className="w-full flex justify-start items-start gap-4 bg-darker-background py-4 px-5">
                <div style={{backgroundColor: setType === 'test' ? '#1f77b4' : color}} className="w-[20px] h-[20px] aspect-square"></div>
                <p className="font-medium break-words truncate">{capitalize(setType)} - {name.replace('(Train)', '')}</p>
            </div>
        </>
    )
}