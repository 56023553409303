import { ChangeEvent } from "react"

export default function EnableSelectionButton({ isChecked, handleChange }: { isChecked: boolean, handleChange: (e:ChangeEvent<HTMLInputElement>) => void }) {

    return (
        <label className={`hover:cursor-pointer transition-all bg-grad-one hover:brightness-90 flex justify-center items-center gap-2 py-3 px-5 rounded text-nowrap`}>
            <p className="font-medium transition-all hidden lg:block">{isChecked ? 'Disable' : 'Enable'} Selection</p>
            <label htmlFor="toggle-enable-selection" className="inline-flex items-center cursor-pointer">
                <input id='toggle-enable-selection' type="checkbox" checked={isChecked} onChange={handleChange} className="sr-only peer" />
                <div className=" relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-tertiary"></div>
            </label>
        </label>
    )
}