// import Camera from '../../assets/camera.svg';
import { MouseEvent } from 'react';
import ScreenshotIcon from '../../assets/screenshot.svg';
import { Tooltip } from '@mui/material';

export default function ScreenshotButton({ identifier, width, hasText, downloadFunc }: {
    identifier: string,
    width: string,
    hasText: boolean,
    downloadFunc?: (e:any) => void,
}) {
    const screenshot = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const div = document.getElementById('structure-img');
        if (div) {
            const img = div.firstElementChild as HTMLImageElement;
            if (img) {
                const imgSrc = img.src;

                const newImg = new Image();
                newImg.onload = () => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.width = 400;
                    canvas.height = 400;

                    if (context) {
                        context.drawImage(img, 0, 0, 400, 400);
                        const href = canvas.toDataURL();
                        const anchor = document.createElement('a');
                        anchor.download = `${identifier}.png`;
                        anchor.href = href;
                        anchor.click();
                        anchor.remove();
                        
                    }
                }
                newImg.src = imgSrc;
            }
        }
    }

    return (
        <Tooltip title={hasText ? '' : 'Screenshot'} placement='bottom'>
        {hasText ? (
            <button onClick={(e:MouseEvent<HTMLButtonElement>) => { downloadFunc ? downloadFunc(e) : screenshot(e) }} className="flex justify-center items-center gap-2 lighten border border-secondary py-2 px-4 rounded text-secondary hover:text-white hover:bg-secondary">
                <img style={{width}} src={ScreenshotIcon} alt='screenshot-icon' />
                <p className="font-medium text-[14px]">Screenshot</p>
            </button>

        ) : (
            <button onClick={(e:MouseEvent<HTMLButtonElement>) => { downloadFunc ? downloadFunc(e) : screenshot(e) }} className="highlight flex justify-center items-center gap-2 transition-all">
                <img style={{width}} src={ScreenshotIcon} alt='screenshot-icon' />
            </button>
        )}
        </Tooltip>
    )
}