import { useState,  MouseEvent } from "react";
import usePagination from "../../../hooks/usePagination";
import useLayout from "../../../hooks/useLayout";
import usePopup from "../../../hooks/usePopup";
import useEnableSelection from "../../../hooks/useEnableSelection";

import Plus from '../../../assets/plus.svg';
import { defaultUserFilter } from "../../../data/users";

import EnableSelectionButton from "../../../components/Buttons/EnableSelectionButton";
import PageHeader from "../../../components/Page/PageHeader";
import Pagination from "../../../components/Page/Pagination";
import ToggleView from "../../../components/Page/ToggleView";
import SingleUserMenu from "../components/SingleUserMenu";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import Grid from "../../../components/Grid/Grid";
import DeletePopup from "../../../components/Popups/DeletePopup";
import TableControls from "../../../components/Table/TableControls";
import useUsers from "../../../hooks/pageHooks/useUsers";
import FilterPopup from "../../../components/Popups/FilterPopup";
import FilterButton from "../../../components/Buttons/FilterButton";
import EmptyTable from "../../../components/Table/EmptyTable";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import AddEditUserPopup from "../components/AddEditUserPopup";
import GridItemBase from "../../../components/Grid/GridItemBase";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../store/selectors/auth.selector";
import Overlay from "../../../components/Popups/Overlay";
import useModifyTableItems from "../../../hooks/useModifyTableItems";
import useMessage from "../../../hooks/useMessage";
import SnackBar from "../../../components/Alerts/SnackBar";

const permissions = {
    admin: {
        color: '#47AD24',
        label: 'All Permissions',
    },
    train: {
        color: '#2429AD',
        label: 'Train Models'
    },
    predict: {
        color: '#AD248E',
        label: 'Make Predictions'
    },
    create: {
        color: '#FFA461',
        label: 'Create Files & Datasets'
    }
    
}


export default function UserDirectory() {
    const [ isAdd, setIsAdd ] = useState(false);
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const theme = useSelector(selectTheme);

    const {
        open,
        setOpen,
        message,
        isSuccess,
        setPopupMessage
    } = useMessage();

    const {
        allUsers,
        modifiedUsers,
        modifyUsers,
        filter,
        setFilter,
        headers,
        setHeaders,
        filterFields,
        allUserIDs,
        deleteUsers,
        isLoading
    } = useUsers({setPopupMessage});

    const {
        selectHeaders,
        setSortedColumn,
        applyFilter,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyUsers, setHeaders, filter, setFilter, searchInput, selectedHeader, setSelectedHeader })

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedUsers, count: 50});

    
    const { 
        toggleView, 
        isGrid 
    } = useLayout();

    const { 
        isPopupOpen,
        togglePopup,
        popupType,
        setPopupType
    } = usePopup();

    const { 
        handleChange, 
        isChecked, 
        selectedRows, 
        editSelectedRows,
        allAreSelected,
        selectAllRows,
        toggleChecked,
        deselectItems,
        addSingleItem
    } = useEnableSelection({allItems: allUserIDs});

    

    const toggleAddUser = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        togglePopup(e);
        setPopupType('add-user');
        setIsAdd(true);
    }


    return (
        <div style={{maxHeight: 'calc(100vh - 70px)'}} className="h-full overflow-hidden flex flex-col justify-start items-stretch gap-4">
      

            <PageHeader label='User Directory'>
                <button onClick={toggleAddUser} className="flex justify-center items-center gap-3 py-3 px-5 rounded bg-tertiary hover:bg-secondary transition-all text-white whiten">
                    <img className="w-[18px]" src={Plus} alt="add-icon" />
                    <p className="font-medium">Add New User</p>
                </button>
            </PageHeader>

            <>
            {/* Search Bar & Buttons Component */}
            <TableControls identifier="Name" applySearch={applySearch} toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={searchInput} selectedRows={selectedRows}>
                    <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                    <FilterButton filter={filter} toggleFilterPopup={togglePopup} setPopupType={setPopupType} />
                    <EnableSelectionButton isChecked={isChecked} handleChange={handleChange} />
                    <ToggleView toggleView={toggleView} isGrid={isGrid} />
            </TableControls>

            {/* Table + Grid Components */}
            <>
            {isGrid ? (
                <Grid isLoading={isLoading} noResults={!paginatedItems.length ? true : false}>
                    {paginatedItems.length > 0 ? (
                        <>
                        {paginatedItems.map(user => {
                            
                            return (
                                <GridItemBase
                                    gridType="user"
                                    selectedRows={selectedRows}
                                    editSelectedRows={editSelectedRows}
                                    item={user}
                                    isChecked={isChecked}
                                    optionButton={<SingleUserMenu 
                                        setIsAdd={setIsAdd}
                                        isGrid={true} 
                                        id={user.id} 
                                        addSingleItem={addSingleItem} 
                                        togglePopup={togglePopup} 
                                        setPopupType={setPopupType}
                                    />}
                                >
                                    <div className="p-4 gap-4 grow flex flex-col justify-between items-stretch"> 
                                       <div>
                                            <p className='font-semibold text-[18px] text-primary'>{user.name}</p>
                                            <p className='font-medium text-[14px] text-secondary mb-4'>{user.company}</p>
                                            <p className="font-medium text-[14px] text-primary mb-4 break-words">{user.email}</p>
                                       </div>
                                      <div className="flex justify-start items-start gap-2 flex-wrap">
                                            {user.privileges.map((p:string) => {
                                                const { color, label } = permissions[p as keyof object];
                                                return (
                                                <div style={{color: `${color}`, borderColor: `${color}`}} className='rounded border px-3 py-1'>
                                                    <p className='font-medium text-[14px] text-nowrap'>{label}</p>
                                                </div>
                                                )
                                                
                                            })}
                                        </div>
                                    </div>
                                </GridItemBase>
                            )
                            
                           
                        })}
                        </>
                    ) : (
                        <EmptyTable isGrid={true} subject="No users found" searchInput={searchInput} />
                    )}
                    
                </Grid>
            ) : (
                <>
                

                {isLoading || paginatedItems.length > 0 ? (
                    <>
                        <TableBase
                        isPopupOpen={false}
                        isLoading={isLoading} 
                        isChecked={isChecked} 
                        selectAllRows={selectAllRows} 
                        allAreSelected={allAreSelected} 
                        hasOptions={isLoading ? false : true} 
                        headers={headers} 
                        selectedHeader={selectedHeader} 
                        setSortedColumn={setSortedColumn}
                        >
                            {paginatedItems.length > 0 && paginatedItems.map(item => (
                                
                                <TableRowBase 
                                headers={headers}
                                item={item}
                                isChecked={isChecked}
                                editSelectedRows={editSelectedRows}
                                selectedRows={selectedRows}
                                rowType='base'
                                >
                                    <SingleUserMenu setIsAdd={setIsAdd} isGrid={false} id={item.id} addSingleItem={addSingleItem} setPopupType={setPopupType} togglePopup={togglePopup}/>
                                </TableRowBase>
                            ))}

                        </TableBase>

                        {paginatedItems.length > 0 && (
                            <Pagination count={50} total={allUsers.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                        firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                        )}
                        
                    </>
                ) : (
                    <EmptyTable subject="No users found" searchInput={searchInput} />
                )}
                  
                </> 
            )}
                
            </>
            
            </>
            <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  

       
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup isChecked={isChecked} toggleChecked={toggleChecked} deselectItems={deselectItems} deleteFunc={deleteUsers} items={selectedRows} togglePopup={togglePopup} label={`${selectedRows.length > 1 ? 'these users' : 'this user'}`}/>
                    ) : popupType === 'filter' ? (
                        <FilterPopup applyFilter={applyFilter} itemsFilter={filter} setItemsFilter={setFilter} searchInput={searchInput} togglePopup={togglePopup} defaultFilter={defaultUserFilter} fieldsObj={filterFields} />
                    ) : (
                        <AddEditUserPopup setPopupMessage={setPopupMessage} toggleUserPopup={togglePopup} isAdd={isAdd} deselectItems={deselectItems} items={selectedRows}/>
                    )}
                </Overlay>
            )}

        </div>
    )
}