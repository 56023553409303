import { Dispatch, SetStateAction } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function SnackBar({ open, setOpen, message, isSuccess }: { 
    open: boolean, 
    setOpen: Dispatch<SetStateAction<boolean>>, 
    message: string,
    isSuccess: boolean,
}) {


  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        {isSuccess ? (
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%', backgroundColor: '#47AD24', fontSize: '14px' }}
          >
            {message}
          </Alert>
        ) : (
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%', backgroundColor: '#FF8484', fontSize: '14px' }}
          >
            {message}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}