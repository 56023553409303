import SinglePageHeader from "../../../components/Page/SinglePageHeader"
import { MouseEvent, useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addReadacross } from "../../../store/slices/readacross.slice";
import KetcherCard from "../../Predictions/components/Cards/KetcherCard";
import ReadAcrossSettings from "../components/ReadAcrossSettings";
import { ReadAcrossForm, ReadAcrossType } from "../../../types/readacross.types";
import { CircularProgress } from "@mui/material";
import API from '../../../api';
import { cleanFileName } from "../../../data/functions";
import usePopup from "../../../hooks/usePopup";
import ErrorPopup from "../../../components/Popups/ErrorPopup";
import Overlay from "../../../components/Popups/Overlay";

const defaultForm: ReadAcrossForm = {
    dataset_for_ra: [],
    desc: '',
    filter: '',
    mol_id: '',
    num_neighbors: 10,
    result_name: '',
    smiles: 'True',
    toxref: true,
}


export default function CreateNewReadAcross() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ smiles, setSmiles ] = useState('');
    const [ form, setForm ] = useState<ReadAcrossForm>(defaultForm);
    const [ isDisabled, setIsDisabled ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ selectedDatasets, setSelectedDatasets ] = useState<{value: string, label: string}[]>([]);
    const [ errorMessage, setErrorMessage ] = useState<string | null>(null);

    const {
        togglePopup,
        isPopupOpen,
    } = usePopup();

    useEffect(() => {
        setRoutes([
            {
                label: 'Read-Across',
                path: 'readacross'
            },
            {
                label: 'Generate Read-Across',
                path: 'readacross/create-readacross'
            }
        ])
    }, []);

    const reset = () => {
        setSmiles('');
        setForm(defaultForm);
    }

    const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);

        if (Number(form.num_neighbors) > 15) {
            setErrorMessage('Analog number must be no greater than 15');
            setIsLoading(false);
            setIsDisabled(false);
            return;
        }

        setErrorMessage(null);

        const body: ReadAcrossForm = {
            ...form,
            dataset_for_ra: selectedDatasets.map(opt => opt.value),
            mol_id: smiles, 
            result_name: cleanFileName(form.result_name)
        }

        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {

                const response = await API.post('readacross', body, { headers: { 'X-Auth': authKey } });
                const data: ReadAcrossType = await response.data;
                dispatch(addReadacross(data));
                reset();
                const id = data._id.$oid;
                navigate(`/readacross/${id}`);

            } catch(err:any) {
                togglePopup(e);
            }
        }

        setIsDisabled(false);
        setIsLoading(false);
        
    }

    useEffect(() => {
        if (!smiles || !form.desc || !form.filter || !form.num_neighbors || !form.result_name) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [form, smiles]);

    

    return (
        <>
       
    
            <SinglePageHeader label='Generate Read-Across' routes={routes}>
                
            </SinglePageHeader>
    
            <div className='w-full table-container overflow-x-scroll py-2'>
                <div className='w-full flex justify-start items-stretch gap-4'>
                    <KetcherCard number={1} setSmiles={setSmiles} smiles={smiles}/>
                    <ReadAcrossSettings errorMessage={errorMessage} form={form} setForm={setForm} selectedDatasets={selectedDatasets} setSelectedDatasets={setSelectedDatasets}/>
                </div>
            </div>
    
            <div className='mt-4 w-full flex justify-end items-center'>
                <button disabled={isDisabled} onClick={handleSubmit} className='flex justify-center items-center gap-2 max-w-[150px] w-full py-2 px-4 rounded text-white text-[14px] border border-secondary bg-secondary disabled:border-quaternary/[0.3] disabled:bg-quaternary/[0.3]'>
                {isLoading ? 
                    <>
                    <CircularProgress size={16} color='inherit'/>
                    <p>Running</p>
                    </>
                    : 
                    <p>Run</p>
                }
                </button>
            </div>
            
    
            
       
        {isPopupOpen && 
            <Overlay togglePopup={togglePopup}>
                <ErrorPopup togglePopup={togglePopup} resetValues={reset} type='datasets' />
            </Overlay>
        }
        </>
    )
}