import Trashcan from '../../../../assets/trash.svg';
import Triangle from '../../../../assets/triangle-right.svg';
import Field from '../../../../components/Form/Field';
import Select from 'react-select';
import VisMethodBox from './VisMethodBox';
import { useState, MouseEvent, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { VisForm } from './CreateVisPopup';

const fingerprintOptions = [
    {value: 'ECFP', label: 'ECFP'},
    {value: 'FCFP', label: 'FCFP'},
    {value: 'MACCS', label: 'MACCS'},
]

const bitsOptions = [
    {value: '512', label: '512'},
    {value: '1024', label: '1024'},
    {value: '2048', label: '2048'},
];

const radiusOptions = [
    {value: '2', label: '2'},
    {value: '3', label: '3'},
    {value: '4', label: '4'},
]

const methodOptions = [
    {value: 'TSNE', label: 'TSNE'},
    {value: 'PCA', label: 'PCA'},
    {value: 'UMAP', label: 'UMAP'},
]

export default function VisBox({ visForm, setVisFormList, visFormList, index }: {
    visForm: VisForm,
    setVisFormList: Dispatch<SetStateAction<VisForm[]>>,
    visFormList: VisForm[],
    index: number,
}) {

    const removeVis = (e:MouseEvent<HTMLButtonElement>, index: number) => {
        e.preventDefault();
        setVisFormList(visFormList.filter((form, i) => i !== index));

    }

    const toggleVis = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setVisFormList(visFormList.map((form, i) => {
            if (i === index) {
                return {
                    ...form,
                    isCollapsed: !form.isCollapsed,
                }
            } else {
                return {...form}
            }
        }))
    }

    const handleFingerprintSelection = (e:any) => {
        setVisFormList(visFormList.map((form, i) => {
            if (i === index) {
               return {
                    ...form,
                    fingerprint: {
                        name: e.value as 'ECFP' | 'FCFP' | 'MACCS',
                        params: (e.value === 'ECFP' || e.value === 'FCFP') ? {
                            nBits: 1024,
                            radius: 3,
                            useChirality: false,
                        } : undefined,
                    }
                }
            } else {
                return {...form}
            }
        }))
    };

    const handleFingerprintParams = (e:any, name: string) => {
        setVisFormList(visFormList.map((form, i) => {
            if (i === index) {
                return {
                    ...form,
                    fingerprint: {
                        ...form.fingerprint,
                        params: {
                            ...form.fingerprint.params!,
                            [name]: e.value
                        }
                    }
                }
            } else {
                return {...form}
            }
        }))
    }

    const handleChirality = (e: ChangeEvent<HTMLInputElement>) => {
        setVisFormList(visFormList.map((form, i) => {
            if (i === index) {
                return {
                    ...form,
                    fingerprint: {
                        ...form.fingerprint,
                        params: {
                            ...form.fingerprint.params!,
                            useChirality: e.target.checked,
                        }
                    }
                }
            } else {
                return {...form}
            }
        }))
    };

    const handleMethodSelection = (e:any) => {
        const methodArr: any[] = e.map((val:any) => val.value);
        const filteredFormMethods = visForm.visualization_methods.filter(method => methodArr.includes(method.name));
        const methodsToAdd = methodArr.filter(m => !filteredFormMethods.map(method => method.name).includes(m));
        const methodParams:any[] = methodsToAdd.map(m => ({
            name: m,
            params: m === 'TSNE' ? {
                perplexity: 30,
                method: 'barnes_hut'
            } : m === 'PCA' ? {
                whiten: false,
                svd_solver: 'auto',
            } : {
                n_neighbors: 15,
                metric: 'euclidean',
                init: 'spectral',
            }
        }))

        setVisFormList(visFormList.map((form, i) => {
            if (i === index) {
                
                return {
                    ...form,
                    visualization_methods: methodParams.length > 0 ? filteredFormMethods.concat(methodParams) : filteredFormMethods,
                }
            } else {
                return {...form}
            }
        }))
    };

    const handleParamStringInput = (e:ChangeEvent<HTMLInputElement>, ind: number) => {
        setVisFormList(visFormList.map((form, i) => {
            if (i === index) {
                return {
                    ...form,
                    visualization_methods: form.visualization_methods.map((visMethod, i) => {
                        if (i === ind) {
                            return {
                                ...visMethod,
                                params: {
                                    ...visMethod.params,
                                    [e.target.name]: e.target.value 
                                }
                            }
                        } else {
                            return {...visMethod}
                        }
                    })
                }
            } else {
                return {...form}
            }
        }))
    };

    const handleParamBoolInput = (e:ChangeEvent<HTMLInputElement>, ind: number) => {
        setVisFormList(visFormList.map((form, i) => {
            if (i === index) {
                return {
                    ...form,
                    visualization_methods: form.visualization_methods.map((visMethod, i) => {
                        if (i === ind) {
                            return {
                                ...visMethod,
                                params: {
                                    ...visMethod.params,
                                    [e.target.name]: e.target.checked 
                                }
                            }
                        } else {
                            return {...visMethod}
                        }
                    })
                }
            } else {
                return {...form}
            }
        }))
    } 

    const handleParamSelectionInput = (e:any, name: string, ind: number) => {
        setVisFormList(visFormList.map((form, i) => {
            if (i === index) {
                return {
                    ...form,
                    visualization_methods: form.visualization_methods.map((visMethod, i) => {
                        if (i === ind) {
                            return {
                                ...visMethod,
                                params: {
                                    ...visMethod.params,
                                    [name]: e.value
                                }
                            }
                        } else {
                            return {...visMethod}
                        }
                    })
                }
            } else {
                return {...form}
            }
        }))
    } 

    return (
        <form data-testid={`vis-box-${index+1}`} className={`vis-box flex flex-col justify-start items-stretch ${!visForm.isCollapsed ? 'border-highlight' : 'border-quaternary'} border-2 rounded overflow-hidden`}>
            <div className='p-4 flex justify-between items-center gap-4 bg-[#e8e8e8]'>
                <button onClick={toggleVis} className={`flex justify-start items-center gap-2 ${!visForm.isCollapsed && 'highlight-icon'}`}>
                    <img src={Triangle} className={`w-[14px] ${!visForm.isCollapsed ? 'rotate-0' : 'rotate-[-90deg]'}`} alt='expand-collapse-box' />
                    <p className={`font-bold ${!visForm.isCollapsed ? 'text-highlight' : 'text-quaternary'}`}>Visualization #{index + 1}</p>
                </button>
                {index > 0 && (
                    <button onClick={(e:any) => removeVis(e, index)} className='highlight px-2 flex justfiy-center items-center gap-2'>
                        <img src={Trashcan} className='w-[18px]' alt='remove-icon' />
                        <p className='font-medium text-[12px]'>Delete</p>
                    </button>
                )}
            </div>
            {!visForm.isCollapsed && (
                <div className='p-8 flex flex-col gap-8 justify-start items-stretch'>

                    <Field label='Fingerprint' isRequired={true}>
                        <Select name='fingerprint' inputId='fingerprint' onChange={handleFingerprintSelection} value={{value: visForm.fingerprint.name as string, label: visForm.fingerprint.name as string}} options={fingerprintOptions} className='text-left text-[14px] rounded w-full' placeholder='Fingerprint'/>
                    </Field>

                    {(visForm.fingerprint.name === 'ECFP' ||  visForm.fingerprint.name === 'FCFP') && (
                        <div className="flex justify-between items-center gap-6 w-full">

                            <div className="grow w-full">
                                <Field label={`${visForm.fingerprint.name === 'ECFP' ? 'ECFP' : 'FCFP'} Bits`} isRequired={true}>
                                    <Select name={`${visForm.fingerprint.name === 'ECFP' ? 'ecfp-bits' : 'fcfp-bits'}`} inputId={`${visForm.fingerprint.name === 'ECFP' ? 'ecfp-bits' : 'fcfp-bits'}`} onChange={(e:any) => handleFingerprintParams(e, 'nBits')} value={visForm.fingerprint.params ? {value: visForm.fingerprint.params.nBits.toString(), label: visForm.fingerprint.params.nBits.toString()} : null} options={bitsOptions} className='text-left text-[14px] rounded w-full' placeholder='Bits'/>
                                </Field>
                            </div>

                            <div className="grow w-full">
                                <Field label={`${visForm.fingerprint.name === 'ECFP' ? 'ECFP' : 'FCFP'} Radius`} isRequired={true}>
                                    <Select name={`${visForm.fingerprint.name === 'ECFP' ? 'ecfp-radius' : 'fcfp-radius'}`} inputId={`${visForm.fingerprint.name === 'ECFP' ? 'ecfp-radius' : 'fcfp-radius'}`} onChange={(e:any) => handleFingerprintParams(e, 'radius')} value={visForm.fingerprint.params ? {value: visForm.fingerprint.params.radius.toString(), label: visForm.fingerprint.params.radius.toString()} : null} options={radiusOptions} className='text-left text-[14px] rounded w-full' placeholder='Radius'/>
                                </Field>
                            </div>


                            <div className='flex flex-col gap-1 justify-center items-start'>
                                <div className="flex justify-start items-center gap-1">
                                    <p className={`text-[12px] font-semibold text-tertiary required`}>Use Chirality</p>
                                </div>
                                <div className="flex justify-start items-stretch gap-2">
                                    <div className='rounded border border-primary flex justify-between items-stretch h-[38px]'>
                                        <label className='flex justify-start items-center gap-2 py-3 px-3 bg-white grow rounded hover:cursor-pointer'>
                                            <p className="text-[14px]">{visForm.fingerprint.params && visForm.fingerprint.params.useChirality ? 'True' : 'False'}</p>
                                            <label htmlFor="useChirality" className="inline-flex items-center cursor-pointer">
                                                <input id='useChirality' onChange={handleChirality} checked={visForm.fingerprint.params && visForm.fingerprint.params.useChirality} type="checkbox" name='useChirality' className="sr-only peer" />
                                                <div className=" relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-tertiary"></div>
                                            </label>
                                        </label>
                                        
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}

                    <Field label='Visualization Methods' isRequired={true}>
                        <Select menuPortalTarget={document.getElementById('create-vis-popup')} name='visualization-methods' inputId='visualization-methods' onChange={handleMethodSelection} options={methodOptions} value={visForm.visualization_methods.map(m => ({value: m.name as string, label: m.name as string}))} isMulti className='text-left text-[14px] rounded w-full' placeholder='Visualization Methods'/>
                    </Field>

                    <div className='flex flex-col gap-10 justify-start items-stretch mt-2'>
                        {visForm.visualization_methods.map((method, i) => (
                            <VisMethodBox method={method} index={i} handleParamStringInput={handleParamStringInput} handleParamBoolInput={handleParamBoolInput} handleParamSelectionInput={handleParamSelectionInput}/>
                        ))}
                    </div>

                </div>
            )}
        </form>
    )
}