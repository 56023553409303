//components
import CreateModelDropdown from "../components/CreateModelDropdown"
import PageHeader from "../../../components/Page/PageHeader"

import { useSelector } from "react-redux";
import usePrivileges from '../../../hooks/usePrivileges';
import { selectApplication } from '../../../store/selectors/auth.selector';
import ModelTemplate from './ModelTemplate';
import PageTabs from '../../../components/Page/PageTabs';
import { useState } from 'react';

export default function Models() {
    const { canTrain } = usePrivileges();
    const application = useSelector(selectApplication);
    const [ selectedTab, setSelectedTab ] = useState('Classification');


    return (
        <div style={{maxHeight: 'calc(100vh - 70px)'}} className="h-full overflow-hidden flex flex-col justify-start items-stretch gap-4">

            <PageHeader label='Models'>
                {canTrain && application && (application).includes('assay-central') && <CreateModelDropdown />}
            </PageHeader>
            
            <PageTabs items={['Classification', 'Regression']} setSelectedTab={setSelectedTab} selectedTab={selectedTab}/>

            {selectedTab === 'Classification' ? (
                <ModelTemplate application={application} isClassification={true} isPopup={false}/>
            ) : (
                <ModelTemplate application={application} isClassification={false} isPopup={false}/>
            )}
        </div>
    )
}