import { ReactNode } from "react";
import InfoMessage from "../Alerts/InfoMessage";

export default function Field({ label, children, isRequired, message }: { 
    label: string,
    children: ReactNode,
    isRequired: boolean,
    message?: string,
 }) {

    return (
        <div id={label.replace(' ','-')} className='w-full flex flex-col gap-1 justify-center items-start'>
            <div className="flex justify-start items-center gap-1">
                <label htmlFor={label.replace(' ','-').toLocaleLowerCase()} className={`text-[12px] font-semibold text-tertiary ${isRequired && 'required'}`}>{label}</label>
                {message && <InfoMessage message={message}/>}
            </div>
            {children}
        </div>
    )
}