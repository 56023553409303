

import Dots from '../../../assets/dots-vertical.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Dispatch, SetStateAction, useState } from 'react';
import { PopupType } from '../../../hooks/usePopup';

const sx = {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: '#35424A',
    padding: '12px 20px',
}



export default function JobDropdown({ toggleDelete, groupID, setGroupForDeletion, setPopupType, selectSingleJobForDeletion, jobID }: { 
    toggleDelete: (e: any) => void, 
    groupID: string, 
    setGroupForDeletion: (e:any, groupID: string) => void,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
    selectSingleJobForDeletion?: (e:any, groupID: string, jobID: string) => void,
    jobID?: string,
}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const setDeletion = (e:any) => {
        if (selectSingleJobForDeletion && jobID) {
            selectSingleJobForDeletion(e, groupID, jobID);
        } else {
            setGroupForDeletion(e, groupID);
        }
        toggleDelete(e);
        setPopupType('delete');
    }
    

    return (
        <div>
            <div className='py-4 px-6 relative z-20'><button onClick={handleClick} className='highlight rounded py-1'><img src={Dots} className='w-[24px]'  alt='individual-options-icon'/></button></div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    marginTop: '8px',
                    '& .MuiMenu-list': {
                        padding: 0,
                    }
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem sx={sx} onClick={(e:any) => {setDeletion(e); handleClose()}}>Delete</MenuItem>
            </Menu>
        </div>

    )
}