import { Dispatch, SetStateAction } from "react"

export default function PageTabs({ items, selectedTab, setSelectedTab, changeTab }: {
    items: string[],
    selectedTab: string,
    setSelectedTab: Dispatch<SetStateAction<string>>,
    changeTab?: (name: string) => void,

}) {
    return (
        <div className="max-w-full overflow-x-scroll min-h-[36px]">
        <div className="w-full border-b border-[#CBCEDC] flex justify-start items-stretch">
            {items.map((item) => (
                <button onClick={(e:any) => { item !== selectedTab && ( changeTab ? changeTab(item) : setSelectedTab(item) ) }} className={`rounded-tl rounded-tr py-2 px-4 text-primary ${item !== selectedTab ? 'bg-none font-medium hover:text-highlight' : 'bg-[#CBCEDC] font-semibold text-nowrap'}`}>{item}</button>
            ))}
        </div>
       </div>
    )
}