import { useState, useEffect } from "react";

import { DatasetProtocol, ParsedProtocol } from "../../types/protocol.types";

import API from '../../api';
import { Header } from "../../types/all.types";
import { capitalize, download } from "../../data/functions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectDatasets } from "../../store/selectors/datasets.selector";
import { selectPredictions } from "../../store/selectors/predictions.selector";

const protocolHeaders: Header[] = [
    {
        label: 'Record',
        value: 'record',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: true,
    },
    {
        label: 'Original Structure',
        value: 'original_structure',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: false,
    },
    {
        label: 'File Fields',
        value: 'file_fields',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: false,
    },
    {
        label: 'Issues',
        value: 'issues',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: false,
    },
    {
        label: 'Dataset Fields',
        value: 'dataset_fields',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: false,
    },
    {
        label: 'Standardized Structure',
        value: 'standardized_structure',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: false,
    }
]

const useProtocol = ({ id, itemID, type, searchInput, setPopupMessage }: {
    id?: string,
    itemID?: string,
    type?: string,
    setPopupMessage?: (popupMessage: string, isSuccessMessage: boolean) => void,
    searchInput: string,
}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ protocol, setProtocol ] = useState<DatasetProtocol | null>(null);
    
    const [ records, setRecords ] = useState<ParsedProtocol[]>([]);
    const [ sortedRecords, setSortedRecords ] = useState<ParsedProtocol[]>([]);
    const [ filteredRecords, setFilteredRecords ] = useState<ParsedProtocol[]>([]);
    const navigate = useNavigate();

    const datasets = useSelector(selectDatasets);
    const results = useSelector(selectPredictions);

    const getType = (type: string) => {
        let selectedType = '';
        switch(type) {
            case 'dsID':
                selectedType = 'datasets';
                break;
            case 'rsID':
                selectedType = 'predictions';
                break;
            default: 
                selectedType = 'datasets';
                break;
        }   

        return selectedType;

    };

    const getName = (type: string, id: string) => {
        let name = '';
        switch(type) {
            case 'dsID':
                const ds = datasets.find(d => d._id.$oid === id);
                if (ds) {
                    name = ds.name;
                } else {
                    name = 'Single Dataset';
                }
                break;
            case 'rsID': 
                const rs = results.find(r => r._id.$oid === id);
                if (rs) {
                    name = rs.name;
                } else {
                    name = 'Single Resultset';
                }
                break;
        };
        return name;
    }

    const setupProtocol = (protocol: DatasetProtocol) => {
        if (protocol) {

            const itemType = getType(type ? type : '');
            const name = getName(type ? type : '', itemID ? itemID : '');

            setRoutes([
                {
                    label: capitalize(itemType),
                    path: itemType,
                },
                {
                    label: name,
                    path: itemID ? `${itemType}/${itemID}` : itemType,
                },
                {
                    label: protocol.name ? protocol.name : 'Protocol',
                    path: protocol._id.$oid ? `protocols/${protocol._id.$oid}` : '',
                }
            ]);

            if (protocol.records.length > 0) {
                const parsedProtocol: ParsedProtocol[] = protocol.records.map(record => ({
                    record: record.ord,
                    original_structure: record.mol_org.$oid,
                    file_fields: record.file_fields,
                    issues: record.issues,
                    dataset_fields: Object.keys(record.ds_fields).length > 0 ? record.ds_fields : record.file_fields,
                    standardized_structure: record.mol_std ? record.mol_std.$oid : '',
                }))

                const ordered: ParsedProtocol[] = parsedProtocol.sort((a,b) => {
                    if ((a.record) < (b.record)) {
                        return -1
                    } else if ((a.record) > (b.record)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                setRecords(ordered);
                setSortedRecords(ordered);
                setFilteredRecords(ordered);
            }
            

        }
    }


    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            if (id) {

                const fetchProtocol = async () => {
                    setIsLoading(true);
    
                    try {
    
                        const response = await API.get(`protocols/${id}`, { headers: { 'X-Auth': authKey }});
                        const data: DatasetProtocol = await response.data;
                        setProtocol(data);
                        setupProtocol(data);
    
                    } catch (err:any) {
    
                        console.log(err);
                        
    
                    }
    
                    setIsLoading(false);
                    
                }
        
                fetchProtocol();
    
            }
        } else {
            navigate('/401');
        }
        
        //eslint-disable-next-line
    }, [id, itemID]);

    useEffect(() => {
        if (sortedRecords.length > 0 && protocol) {
            const newRecords = sortedRecords.filter(record => (record.record).toString().includes(searchInput));
            setFilteredRecords(newRecords);
        }
    }, [searchInput, sortedRecords, protocol]);

    const downloadProtocol = async (filename: string, id: string, ext:string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`protocols/${id}/download?format=${ext}`, { headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                download(filename, data);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage('There was an error downloading this protocol', false)
            }
        }
    }


    return {
        isLoading, 
        records,
        routes,
        protocolHeaders,
        protocol,
        downloadProtocol,
        filteredRecords,
        sortedRecords,
        setSortedRecords,
        setFilteredRecords,
    }
}

export default useProtocol;