import { Limit } from "../../../types/all.types";
import { SingleRegressionModel } from "../../../types/models.types";
import NumberLine from "../../../components/Data/NumberLine";
import ModelGraphs from "../../../components/Data/ModelGraphs";

export default function RegressionGraphs({ item, limits }: {
    item: SingleRegressionModel,
    limits: Limit[],
}) {
    return (
        <>
        <ModelGraphs type="regression" allData={item} methodPlot={{ name: item.method_name, index: 0, graphs: ['scatter'] }} />
        <div>
            <div className='flex flex-col gap-1 justify-center items-stretch'>
            {Object.keys(item.metrics).filter(key => ['mae', 'mgd', 'mpd', 'r2', 'rmse'].includes(key)).map(key => {
                const limitsObj = limits.length && limits.find(limit => limit.value === key);
                if (limitsObj) {
                    return (
                        <NumberLine label={key} max={limitsObj.max} min={limitsObj.min} value={Number((Math.round(Number(item[key as keyof object]) * 100) / 100 as number).toFixed(2))} />
                    )
                } else {
                    return null;
                }
            })}
            </div>
        </div>
        </>
    )
}