import { SingleClassificationModel } from "../../../types/models.types";
import { classificationLimits } from "../../../data/models";
import NumberLine from "../../../components/Data/NumberLine";
import ModelGraphs from "../../../components/Data/ModelGraphs";



export default function ClassificationGraphs({ obj, item, }: {
    obj?: {
        name: string;
        graphs: string[],
        index: number
    },
    item: SingleClassificationModel,

}) {

    return (
        <>
            <ModelGraphs allData={item} type="classification" methodPlot={obj}/>
            <div>
                <div className='flex flex-col gap-1 justify-center items-stretch'>
                {Object.keys(item).filter(key => ['acc', 'auc', 'cohens_kappa', 'f1score', 'mcc', 'specificity', 'precision', 'recall'].includes(key)).map(key => {
                    const limitsObj = classificationLimits.find(limit => limit.value === key);
                    if (limitsObj) {
                        return (
                            <NumberLine label={key} max={limitsObj.max} min={limitsObj.min} value={Number((Math.round(Number(item[key as keyof object]) * 100) / 100 as number).toFixed(2))} />
                        )
                    } else {
                        return null;
                    }
                })}
                </div>
            </div>   
        </>
    )
}