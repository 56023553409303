import { useEffect, useState } from "react";
import { VisualizationType } from "../../types/predictions.types";
import API from '../../api';
import { download } from "../../data/functions";

const useResultVisualizations = ({ id, setPopupMessage, rs }: {
    id?: string,
    setPopupMessage: (message: string, isSuccess: boolean) => void,
    rs: string,
}) => {
    const [ visualizations, setVisualizations ] = useState<VisualizationType[]>([]);
    const [ isVisLoading, setIsVisLoading ] = useState(false);

    const fetchVisualizations = async (auth: string) => {
        setIsVisLoading(true);

        try {
            const response = await API.get(`resultsets/visualize/${id}?molecule_info=all`, { headers: { "X-Auth": auth } });
            const data = await response.data;
            setVisualizations(data);
        } catch (err:any) {
            console.log(err);
        }

        setIsVisLoading(false);
    };

    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey && id) {
            fetchVisualizations(authKey);
        }
        //eslint-disable-next-line
    }, [id]);

    const deleteSingleVisualizations = async (plots: string[]) => {

        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            const promises = plots.map(async (uniqueId) => {
                return API.delete(`resultset_visualization/${uniqueId}`, { headers: { 'X-Auth': authKey} }).then(res => console.log(res.status)).catch(err => console.log(err))
            });

            try {
                await Promise.all(promises);
                await fetchVisualizations(authKey);
                setPopupMessage('The visualizations you selected were deleted successfully', true);

            } catch (err:any) {
                console.log(err);
                setPopupMessage('We encountered an issue deleting these visualization. Please try again.', false)
            }

        }
        
    };

    const deleteAllVis = async (rs_ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            const promises = rs_ids.map(async (rs_id) => {
                return API.delete(`resultsets/visualize/${rs_id}`, { headers: { 'X-Auth': authKey} }).then(res => console.log(res.status)).catch(err => console.log(err));
            })

            try {
                await Promise.all(promises);
                await fetchVisualizations(authKey);
                setVisualizations([]);
                setPopupMessage('You have successfully deleted all visualizations for this resultset', true);
            } catch (err:any) {
                console.log(err);
                setPopupMessage('We encountered an issue deleting all visualizations for this resultset. Please try again.', false);
            }
        }
    };

    const exportAllVis = async (rs_id: string) => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {
                const response = await API.get(`resultsets/visualize/${rs_id}/download`, { headers: { 'X-Auth': authKey}, responseType: 'blob' });
                const data = response.data;
                const rs_name = rs ? rs : 'prediction';
                download(`${rs_name}-vis.xlsx`, data);
                setPopupMessage('All visualizations for this resultset were exported successfully.', true);
                
            } catch(err:any) {
                console.log(err);
                setPopupMessage('We encountered an issue exporting all visualizations for this resultset. Please try again.', false);
            }
        }
    };

    return {
        visualizations,
        isVisLoading,
        deleteSingleVisualizations,
        deleteAllVis,
        exportAllVis,

    }

};

export default useResultVisualizations;