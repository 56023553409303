import { useRef } from "react"
import { CircularProgress } from "@mui/material";
import useLazyLoad from "../../hooks/useLazyLoad";
import NoImg from '../../assets/noImg.svg';
import useRenderMolecule from "../../hooks/useRenderMolecule";

export default function ImageCell({
    id, 
    hasMoleculeID, 
    type,
}: {
    id:string, 
    hasMoleculeID?: boolean, 
    type: 'table' | 'grid' | 'popup',
}) {
    const containerRef = useRef<HTMLDivElement>(null);
    const isVisible = useLazyLoad(containerRef, {threshold: 0.1});
    const { isNotFound, src } = useRenderMolecule({ id, size: 150, hasMoleculeID });

    
    if (type === 'table') {
        return (
        <div ref={containerRef}>
        {src && isVisible ? (
            <div className="min-w-[150px] w-full"><img src={src} alt='chemical-structure-img' className="w-[150px] h-full object-cover"/></div>
        ) : isNotFound ? (
            <div className="min-w-[150px] w-full"><img src={NoImg} alt='no-img' className="w-[150px] h-full object-cover"/></div>
        ) : (
            <div className="min-w-[150px] p-4 text-[#D9D9D9]"><CircularProgress size={60} color='inherit'/></div>

        )}
        </div>
        )
    } else if (type === 'grid') {
        return (
            <div ref={containerRef} className={`${id ? 'rounded bg-white' : 'bg-none'} w-full relative text-center`}>
                {src && isVisible ? (
                        <div className='w-full p-4 relative'>
                            <div className='w-full h-full hover:bg-secondary bg-opacity-10 absolute top-0 right-0 left-0 bottom-0 rounded z-10'></div>
                            <img src={src} alt='chemical-structure' className='w-full object-cover' />
                        </div>
                ) : isNotFound ? (
                        <div className='w-full p-4 relative'>
                            <div className='w-full h-full hover:bg-secondary bg-opacity-10 absolute top-0 right-0 left-0 bottom-0 rounded z-10'></div>
                            <img src={NoImg} alt='no-structure' className='w-full object-cover' />
                        </div>
                ) : (
                    <div className="w-full flex justify-center items-center text-[#D9D9D9]"><CircularProgress size={80} color='inherit'/></div>
                )}
            </div>
        )
    } else {
        return (
            <div ref={containerRef}>
            {src && isVisible ? (
                <div className="w-full max-h-[600px]"><img src={src} alt='chemical-structure-img' className="w-[150px] h-full object-cover"/></div>
            ) : isNotFound ? (
                <div className="w-full max-h-[600px]"><img src={NoImg} alt='no-img' className="w-[150px] h-full object-cover"/></div>
            ) : (
                <div className="w-full aspect-square flex justify-center items-center text-[#D9D9D9]"><CircularProgress size={60} color='inherit'/></div>

            )}
            </div>
        )
    }

    
}