import API from '../../api';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { AllModels, CompleteModel, Model, ModelWithMethods } from '../../types/models.types';
import { useDispatch } from 'react-redux';
import { FilterFieldsType, FilterType, Header } from '../../types/all.types';
import { getCompleteModels } from '../../store/slices/models.slice';
import { allModelHeaders, defaultAllModelsFilter, defaultAllModelsFilterFields } from '../../data/models';
import { filterItems, searchItems, sortByColumn } from '../../data/functions';

const useModelsWithMethods = () => {

    const [ isLoading, setIsLoading ] = useState(false);
    const [ models, setModels ] = useState<Model[]>([]);
    const [ parsedModels, setParsedModels ] = useState<AllModels[]>([]);
    const [ modifiedModels, setModifiedModels ] = useState<AllModels[]>([]);
    const [ allModelNames, setAllModelNames ] = useState<string[]>([]);
    const [ filterFields, setFilterFields ] = useState<FilterFieldsType>(defaultAllModelsFilterFields);
    const [ filter, setFilter ] = useState<FilterType>(defaultAllModelsFilter);

    const [ headers, setHeaders ] = useState<Header[]>([]);

    const dispatch = useDispatch();


    const fetchPaginatedModels = async (
        auth: string, 
    ) => {
        

        setIsLoading(true);

        try {

            const response = await API.get(`models?aggr=max`, {
                headers: { 'X-Auth': auth } 
            });

            const data = await response.data;

            setModels(data);

        } catch (err:any) {
            console.log(err, 'error');
        }

        setIsLoading(false);

    };


    const fetchAllModels = async (
        auth: string, 
    ) => {
           
        try {

            const response = await API.get(`models?aggr=max`, {
                headers: { 'X-Auth': auth } 
            });

            const methodResponse = await API.get('models', {
                headers: { 'X-Auth': auth } 
            })

            const data = await response.data;
            const methodData = await methodResponse.data;

            let modelArr: CompleteModel[] = [];
                    
            data.forEach((m: CompleteModel) => {
                const match  = methodData[m.name as keyof ModelWithMethods];
                const modifiedModel: CompleteModel = { ...m, methodIds: match.method_ids};
                modelArr.push(modifiedModel);
            })

            dispatch(getCompleteModels(modelArr));


        } catch (err:any) {
            console.log(err, 'error');
        }

    };


    useEffect(() => {
        
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            fetchAllModels(authKey);
            fetchPaginatedModels(authKey);
        }

        setHeaders(allModelHeaders);

         // eslint-disable-next-line
    }, []);



    useEffect(() => {
        if (models.length > 0) {
            const arr: AllModels[] = models.map(m => ({
                name: m.name,
                n: m.n,
                model_type: m.auc ? 'Classification' : 'Regression',
                created_by: m.created_by,
                creation_time: new Date(m.creation_time).toLocaleString('en-US', {timeZone: 'EST'}),
            }))

            
    
            setParsedModels(arr);
            setModifiedModels(arr);
            
        }
    }, [models]);





    useEffect(() => {
        if (modifiedModels.length > 0) {
            setAllModelNames(modifiedModels.map(model => model.name));
            setFilterFields({
                n: [],
                'model_type': ['Classification', 'Regression'],
            })
        }
    }, [modifiedModels]);



    const modifyModels = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>, filter?: FilterType) => {
        const searchArr = searchItems(searchInput, parsedModels);
        const sortedArr = sortByColumn(selectedHeader, headers, false, searchArr, updateHeaders, true);
        const filteredArr = filterItems(filter!, sortedArr);
        setModifiedModels(filteredArr);
    }



    return {
        parsedModels,
        modifiedModels,
        modifyModels,
        headers,
        setHeaders,
        isLoading,
        allModelNames,
        filterFields,
        setFilter,
        filter,
    }

};

export default useModelsWithMethods;