import Plot from 'react-plotly.js';
import { VisualizationGroupType } from "../../../../types/predictions.types";
import { Dispatch, SetStateAction } from "react";
import ChevronRight from '../../../../assets/chevron-right-black.svg';
import ChevronLeft from '../../../../assets/chevron-left-black.svg';
import SelectedMolecule from "./SelectedMolecule";
import TrashIcon from '../../../../assets/trash.svg';
import GreenTrashIcon from '../../../../assets/green-trashcan.svg';
import ExportIcon from '../../../../assets/download.svg';
import PurpleExportIcon from '../../../../assets/purpleDownload.svg';
import { capitalize } from "../../../../data/functions";
import { PopupType } from "../../../../hooks/usePopup";
import { CircularProgress } from "@mui/material";
import { Tooltip } from "@mui/material";
import useSingleResultVisualizations from "../../../../hooks/pageHooks/useSingleResultVisualizations";


export default function VisualizationBox({ group, setPopupType, togglePopup, setSelectedPlots, setPopupMessage }: {
    group: VisualizationGroupType,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
    togglePopup: (e:any) => void,
    setSelectedPlots: Dispatch<SetStateAction<string[]>>,
    setPopupMessage: (message: string, isSuccess: boolean) => void,
}) {
    const {
        selectedMolecule,
        isDownloadingAll,
        isDownloadingPlot,
        toggleIndex,
        index,
        plotData,
        getMolecule,
        toggleTraceVisibility,
        traceVisibility,
        config,
        layout_reg,
        exportVisData,
    } = useSingleResultVisualizations({ group, setPopupMessage })


    return (
        <div className={`w-full rounded bg-white border border-[#CBCEDC] flex flex-col gap-6 h-full p-8`}>
            <div className={`w-full flex justify-between items-center`}>
                <div className="flex flex-col gap-[2px] justify-start items-start">
                    <p className='text-[1.5rem] font-semibold text-nowrap'>{group.fingerprint.name}</p>
                    {group.fingerprint.params.nBits && <p className="font-semibold text-quaternary">Bits: {group.fingerprint.params.nBits} / Radius: {group.fingerprint.params.radius} / Use Chirality: {group.fingerprint.params.useChirality ? 'True' : 'False'}</p>}
                </div>
                {group.visualization_methods.length > 1 && (
                    <div className="flex justify-end items-stretch gap-4">
                        <button onClick={(e:any) => exportVisData(e, group.visualization_methods.map(method => ({id: method.unique_id, name: method.visualization_method.name})))} className="flex justify-center items-center gap-2 border border-[#362484] py-2 px-4 rounded text-[#362484] hover:text-white hover:bg-[#362484] transition-all lighten">
                            {isDownloadingAll ? <CircularProgress color="inherit" size={12}/> : <img src={PurpleExportIcon} className="w-[18px]"  alt='export-all-icon'/>}
                            <p className="font-medium text-[14px]">Export All Data</p>
                        </button>
                        <button onClick={(e:any) => {togglePopup(e); setPopupType('delete-vis'); setSelectedPlots(group.visualization_methods.map(method => method.unique_id))}} className="flex justify-center items-center gap-2 border border-secondary py-2 px-4 rounded text-secondary hover:text-white hover:bg-secondary transition-all lighten">
                            <img src={GreenTrashIcon} className="w-[18px]"  alt='delete-all-icon'/>
                            <p className="font-medium text-[14px]">Delete All Plots</p>
                        </button>
                        <div className='flex justify-center items-center rounded bg-darker-background shadow-inner'>
                            <button onClick={(e:any) => toggleIndex(e, 'prev')} className={`${index === 0 ? 'border-quaternary' : 'border-secondary'} border-t border-b border-l darken-icons flex justify-center items-center px-3 py-1 rounded-tl rounded-bl h-full relative toggle-btn`}>
                                {index > 0 && <div className='absolute top-0 left-0 right-0 bottom-0' />}
                                <img src={ChevronLeft} alt='left-arrow' className='w-[14px]' />
                            </button>
                            <button onClick={(e:any) => toggleIndex(e, 'next')} className={`${index > 0 ? 'border-quaternary' : 'border-secondary'} border darken-icons flex justify-center items-center px-3 py-1 rounded-tr rounded-br h-full relative toggle-btn`}>
                                {index < plotData.length-1 && <div className='absolute top-0 left-0 right-0 bottom-0' />}
                                <img src={ChevronRight} alt='right-arrow' className='w-[14px]' />
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {plotData.length > 0 && plotData.map((data,i) => (
            <>
            {i === index && (
            <div className="w-full flex justify-between items-start gap-6">
                <div className="grow">
                    <Plot onClick={getMolecule} layout={layout_reg()} data={data.plotData} config={config} className='w-full aspect-square'/>
                </div>
                <div className="w-[400px] flex flex-col justify-start items-stretch gap-4">
                    <div className="w-full rounded border-2 border-quaternary overflow-hidden">
                        <div className="w-full flex justify-between items-center gap-4 bg-[#e8e8e8] py-4 px-5">
                            <p className="font-semibold text-primary">{data.plotInfo.name}</p>
                            <div className="flex justify-end items-center gap-4">
                                <Tooltip title='Export Plot' placement="bottom">
                                    <button onClick={(e:any) => exportVisData(e, [{id: group.visualization_methods[i].unique_id, name: group.visualization_methods[i].visualization_method.name}])} className='highlight flex justfiy-center items-center gap-2'>
                                        {isDownloadingPlot ? <CircularProgress color="inherit" size={12}/> : <img src={ExportIcon} className="w-[18px]" alt='export-icon' />}
                                    </button>
                                </Tooltip>
                                <Tooltip title='Delete Plot' placement="bottom">
                                    <button onClick={(e:any) => {togglePopup(e); setPopupType('delete-vis'); setSelectedPlots([group.visualization_methods[i].unique_id])}} className='highlight flex justfiy-center items-center gap-2'>
                                        <img src={TrashIcon} className='w-[18px]' alt='remove-icon' />
                                    </button>
                                </Tooltip>
                                
                            </div>
                        </div>
                        <div className="w-full flex flex-col gap-4 py-4 px-5">
                            <div className='w-full flex flex-col gap-1 justify-center items-start'>
                                <label className={`text-[12px] font-semibold text-primary`}>Legend</label>
                                <div className="w-full flex flex-col bg-[#e8e8e8] rounded overflow-hidden">
                                    <button onClick={(e:any) => toggleTraceVisibility(0)} className={`w-full flex justify-start items-center gap-4 p-4 ${traceVisibility[0] ? 'opacity-100' : 'opacity-30'}`}>
                                        <div className={`w-[20px] aspect-square bg-[#1f77b4] `}></div>
                                        <p className={`font-medium truncate`}>Test - {data.plotData[0].customdata[0].name.replace('(Test)', '')}</p>
                                    </button>
                                    {data.plotData.filter((d:any) => d.customdata[0].set_type === 'train').length > 1 ? data.plotData.filter((d:any) => d.customdata[0].set_type === 'train').map((train:any, i: number) => (
                                        <button onClick={(e:any) => toggleTraceVisibility(i+1)} className={`w-full flex justify-start items-center gap-4 p-4 ${traceVisibility[i+1] ? 'opacity-100' : 'opacity-30'}`}>
                                            <div style={{backgroundColor: train.customdata[0].color}} className={`w-[20px] h-[20px] aspect-square`}></div>
                                            <p className={`font-medium break-words truncate`}>Train - {train.customdata[0].name.replace('(Train)', '')}</p>
                                        </button>
                                    )) : data.plotData.filter((d:any) => d.customdata[0].set_type === 'train').length !== 0 ? (
                                        <button onClick={(e:any) => toggleTraceVisibility(1)} className={`w-full flex justify-start items-center gap-4 p-4 ${traceVisibility[1] ? 'opacity-100' : 'opacity-30'}`}>
                                            <div className={`w-[20px] aspect-square bg-[#ff7f0e]`}></div>
                                            <p className={`font-medium truncate`}>Train - {data.plotData[1].customdata[0].name.replace('(Train)', '')}</p>
                                        </button>
                                    ) : (
                                        <></>
                                    )}
                                    
                                </div>         
                            </div>
                            {Object.keys(data.plotInfo.params).map(param => (
                                <div className='w-full flex flex-col gap-1 justify-center items-start'>
                                    <label className={`text-[12px] font-semibold text-primary`}>{capitalize(param)}</label>
                                    <div className="w-full rounded border border-primary focus:outline-tertiary p-3">
                                        {data.plotInfo.params[param] === true ? 'true' : data.plotInfo.params[param] === false ? 'false' : data.plotInfo.params[param]}
                                    </div>          
                                </div>
                            ))}
                        </div>
                    </div>
                    <SelectedMolecule selectedMolecule={selectedMolecule}/>
                </div>
            </div>
            )}
            </>
            ))}
            
        </div>
    )
}