import { Header } from "../../types/all.types";
import { ChangeEvent, ReactNode, useEffect} from "react";
import Ascending from '../../assets/sort-ascend.svg';
import Descending from '../../assets/sort-descend.svg';
import LoadingRow from "./LoadingRow";
import { useSelector } from "react-redux";
import { selectNav } from "../../store/selectors/nav.selector";
import { useLocation } from "react-router-dom";
import InfoMessage from "../Alerts/InfoMessage";


export default function TableBase({ 
    isLoading, 
    headers, 
    selectedHeader,
    children,
    isChecked,
    selectAllRows,
    allAreSelected,
    setSortedColumn,
    activeField,
    hasOptions,
    isPopupOpen,
    prediction,
    quantity,
}: {
    isLoading: boolean,
    headers: Header[],
    selectedHeader: string,
    children: ReactNode,
    isChecked: boolean,
    selectAllRows?: (e:ChangeEvent<HTMLInputElement>) => void,
    allAreSelected?: boolean,
    setSortedColumn: (e:any, label: string) => void,
    hasOptions: boolean,
    isPopupOpen: boolean,
    activeField?: {[key:string]: string, name: string, type: string} | null,
    prediction?: {
        activityColumn: string,
        infoCells: number,
        predictionCells: number,
    },
    quantity?: number,
}) {
    const isNavOpen = useSelector(selectNav);
    const { pathname } = useLocation();

    // changes max width of table depending on layout
    const changeTableWidth = () => {
        if (isNavOpen) {
            if (pathname.includes('dashboard')) {
                return 'dashboard-container-expanded';
            } else {
                return 'table-container-expanded';
            }
        } else if (isPopupOpen) {
            return 'mini-table-container';
        } else if (pathname.includes('dashboard')) {
            return 'dashboard-container';
        } else {
            return 'table-container';
        }
    }


    const selectionEnabled = (index: number) => {
        if (index === 0 && isChecked) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const tableContainer = document.getElementById('data-table');
    
        if (tableContainer) {
            
            const handleScroll = (event: Event) => {
                const container = event.target as HTMLElement;
                const stickyColumns = document.querySelectorAll('.frozen-column');
                
                if (stickyColumns.length > 0) {
                    const maxScrollLeft = container.scrollWidth - container.clientWidth;
    
                    if (container.scrollLeft >= maxScrollLeft) {
                        stickyColumns.forEach(column => column.classList.add('no-shadow'));
                    } else {
                        stickyColumns.forEach(column => column.classList.remove('no-shadow'));
                    }
                }
            };
    
            // Wait for the DOM to be fully updated before adding the event listener
            setTimeout(() => {
                tableContainer.addEventListener('scroll', handleScroll);
            }, 0);
    
            // Cleanup function to remove the listener
            return () => {
                tableContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    useEffect(() => {
        const tableContainer = document.getElementById('data-table');

        if (tableContainer) {
            const checkIfScrollable = () => {
                const stickyColumns = document.querySelectorAll('.frozen-column');
    
                if (stickyColumns.length > 0) {
                    const isScrollable = tableContainer.scrollWidth > tableContainer.clientWidth;

                    
                    if (isScrollable) {
                        // Add scroll event listener only if scrollable
                        stickyColumns.forEach(column => column.classList.remove('no-shadow'));
                    } else {
                        // Remove any existing shadows if not scrollable
                        stickyColumns.forEach(column => column.classList.add('no-shadow'));
                    }
                }
            };

            const resizeObserver = new ResizeObserver(() => {
                checkIfScrollable(); // Recheck scrollability whenever the size of the container changes
            });
    
            if (tableContainer) {
                resizeObserver.observe(tableContainer); // Start observing the table for size changes
            }

            return () => {
                if (tableContainer) {
                    resizeObserver.unobserve(tableContainer); // Stop observing
                }
            };
        }
        
    }, [])


    

    return (
        <>
            <div id='data-table' className={` w-full ${changeTableWidth()} transition-all grow overflow-y-auto overflow-x-auto bg-white rounded border border-[#CBCEDC]`}>
                <table className="w-full">
                    {prediction && !isLoading && (
                        <tr className=''>
                            <th colSpan={prediction.infoCells} className='font-semibold text-nowrap py-4 px-6 text-center bg-background'>Molecule Information</th>
                            <th colSpan={prediction.predictionCells} className='font-semibold text-nowrap py-4 px-6 text-center bg-[#C8C8C8]/[0.3]'>{prediction.activityColumn}</th>
                            {hasOptions && <th className='bg-[#C8C8C8]/[0.3]'><div className='w-[24px] h-[24px]'></div></th>}
                        </tr>
                    )}
                    <tr className='bg-gradient-to-r from-grad-one to-grad-two text-quaternary relative sticky top-0 z-30'>
                        {isLoading ? (
                            <th className='w-full py-4 px-6'><div className='h-[14px]'/></th>
                        ) : (
                        <>
                        
                        {headers.map((header:Header, i:number)=> {
                            if (header.isSelected) {
                                return (
                                    <th key={i} className={`text-left text-nowrap min-w-[150px] text-[14px]`}>
                                        <div className={`flex ${header.isRequired && i === 0 ? 'justify-start' : 'justify-center'} items-center py-4 px-6`}>
                                            {selectionEnabled(i) && <input onChange={selectAllRows} checked={allAreSelected} type="checkbox" className="accent-secondary mr-6 hover:cursor-pointer"/>}
                                            <button name={header.label} disabled={header.isSortable ? false : true} onClick={(e:any) => setSortedColumn(e, header.value)} className={`${header.isRequired && i === 0 ? 'justify-start' : 'justify-center'} flex items-center gap-3 w-full`}>
                                                <div className="flex justify-start items-start gap-1">
                                                    <p className={`${activeField && header.label === activeField.name ? 'text-[#FF8484]' : 'text-primary'}`}>{header.label}</p>
                                                    {header.label === 'duplicate' && <InfoMessage message="Compound is included in training set" />}
                                                </div>
                                                {selectedHeader === header.value && ( 
                                                    <>
                                                    {header.isAscending ? (
                                                    <img src={Ascending} alt='sort-ascending-icon' className='w-[18px]'/>
                                                    ) : (
                                                    <img src={Descending} alt='sort-descending-icon' className='w-[18px]'/>
                                                    )}
                                                    </>
                                                )}             
                                            </button> 
                                        </div>
                                    </th>
                                )
                            } else {
                                return null;
                            }
                        })}
                        </>
                        )}

                        {hasOptions && <th className='py-4 px-6 text-left text-nowrap bg-grad-two z-40 relative sticky right-0 frozen-column no-shadow'><div className='w-[24px] h-[24px]'></div></th>}

                    </tr>

                    <tbody>
                        {isLoading ? (
                            <>
                                {Array.from(Array(quantity ? quantity : 10).keys()).map((k,i) => (
                                    <LoadingRow />
                                ))}
                            </>
                        ) : (
                            <>{children}</>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}