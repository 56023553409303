import { MouseEvent } from 'react';
import { useState, useEffect } from 'react';

import ToggleArrows from './ToggleArrows';

export default function ChangePage({ controls, methodPlot, name }: {
    controls: {
        next: (e:MouseEvent<HTMLButtonElement>, name:string) => void,
        back: (e:MouseEvent<HTMLButtonElement>, name:string) => void,
    },
    methodPlot?: { name: string, index: number, graphs: string[] },
    name: string,
}) {

    const [ isBackDisabled, setIsBackDisabled ] = useState(true);
    const [ isNextDisabled, setIsNextDisabled ] = useState(false);
    // const [ graph, setGraph ] = useState<any>();

    useEffect(() => {
        if (methodPlot) {
            if (methodPlot.index === 0) {
                setIsNextDisabled(false);
                setIsBackDisabled(true);
            } else if (methodPlot.index === methodPlot.graphs.length-1) {
                setIsNextDisabled(true);
                setIsBackDisabled(false);
            } else {
                setIsNextDisabled(false);
                setIsBackDisabled(false);
            }
        }
       
            
    }, [methodPlot]);





    return (
        <ToggleArrows firstCondition={!isBackDisabled} secondCondition={!isNextDisabled} firstAction={(e) => controls.back(e, name)} secondAction={(e) => controls.next(e, name)}/>
    )
}