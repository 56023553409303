import { ReactNode } from "react";

export default function Overlay({ children, togglePopup }: {
    children: ReactNode,
    togglePopup: (e:any) => void,
}) {

    return (
        <div id='overlay' onClick={togglePopup} className='fixed top-0 right-0 left-0 bottom-0 bg-black/[0.3] z-50'>
            {children}
        </div>
    )
}