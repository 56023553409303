import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function ThresholdSlider({ threshold, setThreshold }: {
    threshold: number,
    setThreshold: React.Dispatch<React.SetStateAction<number>>,
}) {
  const [value, setValue] = React.useState(30);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setThreshold(newValue as number);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThreshold(event.target.value === '' ? 0 : Number(event.target.value));
  };

  const handleBlur = () => {
    if (threshold < 0) {
      setThreshold(0);
    } else if (threshold > 1) {
      setThreshold(1);
    }
  };

  return (
    <div className='rounded border border-[#CBCEDC] py-4 px-6 bg-white'>
    <p className='font-semibold'>Set Threshold</p>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs>
          <Slider
            value={typeof threshold === 'number' ? threshold : 0}
            onChange={handleSliderChange}
            step={0.01}
            min={0.01}
            max={0.99}
            aria-labelledby="input-slider"
            sx={{
                color: '#0C848F',
            }}
          />
        </Grid>
        <Grid item>
          <Input
            value={threshold}
            sx={{
                fontFamily: 'Montserrat',
                fontWeight: 600,
            }}
            size="small"
            style={{
              width: 50,
            }}
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 0.1,
              min: 0,
              max: 1,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
      </div>
  );
}