import { ChangeEvent } from "react";
import Field from "../../../../components/Form/Field";
import Select from 'react-select';

const tsneOptions = [
    {value: 'barnes_hut', label: 'Barnes Hut'},
    {value: 'exact', label: 'Exact'},
];

const pcaOptions = [
    {value: 'auto', label: 'Auto'},
    {value: 'full', label: 'Full'},
    {value: 'covariance_eigh', label: 'Covariance Eigh'},
    {value: 'arpack', label: 'Arpack'},
    {value: 'randomized', label: 'Randomized'},
];

const umapMetricOptions = [
    {value: 'euclidean', label: 'Euclidean'},
    {value: 'manhattan', label: 'Manhattan'},
    {value: 'chebyshev', label: 'Chebyshev'},
    {value: 'minkowski', label: 'Minkowski'},
    {value: 'canberra', label: 'Canberra'},
    {value: 'braycurtis', label: 'Braycurtis'},
    {value: 'mahalanobis', label: 'Mahalanobis'},
    {value: 'wminkowski', label: 'Wminkowski'},
    {value: 'seuclidean', label: 'Seuclidean'},
    {value: 'cosine', label: 'Cosine'},
    {value: 'correlation', label: 'Correlation'},
    {value: 'haversine', label: 'Haversine'},
    {value: 'hamming', label: 'Hamming'},
    {value: 'jaccard', label: 'Jaccard'},
    {value: 'dice', label: 'Dice'},
    {value: 'russelrao', label: 'Russelrao'},
    {value: 'kulsinski', label: 'Kulsinski'},
    {value: 'll_dirichlet', label: 'LL Dirichlet'},
    {value: 'hellinger', label: 'Hellinger'},
    {value: 'rogerstanimoto', label: 'Rogerstanimoto'},
    {value: 'sokalmichener', label: 'Sokalmichener'},
    {value: 'sokalsneath', label: 'Sokalsneath'}, 
    {value: 'yule', label: 'Yule'}
];

const umapInitOptions = [
    {value: 'spectral', label: 'Spectral'},
    {value: 'random', label: 'Random'},
    {value: 'pca', label: 'PCA'},
    {value: 'tswspectral', label: 'TSW Spectral'},
]

export default function VisMethodBox({ method, index, handleParamStringInput, handleParamBoolInput, handleParamSelectionInput }: {
    method: {
        name: 'TSNE' | 'PCA' | 'UMAP',
        params: {
            [key: string]: string | number | boolean,
        }
    },
    index: number,
    handleParamStringInput: (e: ChangeEvent<HTMLInputElement>, ind: number) => void,
    handleParamBoolInput: (e: ChangeEvent<HTMLInputElement>, ind: number) => void,
    handleParamSelectionInput: (e:any, name: string, ind: number) => void,
}) {



    return (
        <div className="w-full p-6 relative border-2 border-quaternary rounded">
            <div className="absolute top-[-12px] left-[12px] bg-background text-secondary font-bold text-[14px] px-1">{method.name}</div>
            {method.name === 'TSNE' ? (
                <div data-testid='tsne' className='flex justify-between items-center gap-6'>
                    <div className='grow'>
                        <Field label='Perplexity' isRequired={true}>
                            <input onChange={(e:any) => handleParamStringInput(e, index)} value={method.params.perplexity ? method.params.perplexity.toString() : ''} name='perplexity' className='text-left text-[14px] rounded border border-primary p-2 w-full' placeholder='Perplexity'/>
                        </Field>
                    </div>
                    <div className='grow'>
                        <Field label='Method' isRequired={true}>
                            <Select name='method' inputId="method" menuPortalTarget={document.getElementById('create-vis-popup')} onChange={(e:any) => handleParamSelectionInput(e, 'method', index)} options={tsneOptions} value={method.params.method ? {value: method.params.method, label: method.params.method} : null} className='text-left text-[14px] rounded w-full' placeholder='Method'/>                    
                        </Field>
                    </div>
                </div>
            ) : method.name === 'PCA' ? (
                <div data-testid='pca' className='flex justify-between items-center gap-6'>
                    <div className='flex flex-col gap-1 justify-center items-start'>
                        <div className="flex justify-start items-center gap-1">
                            <p className={`text-[12px] font-semibold text-tertiary required`}>Whiten</p>
                        </div>
                        <div className="flex justify-start items-stretch gap-2">
                            <div className='rounded border border-primary flex justify-between items-stretch h-[38px]'>
                                <label className='flex justify-start items-center gap-2 py-3 px-3 bg-white grow rounded hover:cursor-pointer'>
                                    <p className="text-[14px]">{method.params.whiten !== undefined && method.params.whiten ? 'True' : 'False'}</p>
                                    <label htmlFor="whiten" className="inline-flex items-center cursor-pointer">
                                        <input id='whiten' onChange={(e:any) => handleParamBoolInput(e, index)} checked={method.params.whiten ? method.params.whiten as boolean : false} type="checkbox" name='whiten' className="sr-only peer" />
                                        <div className=" relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-tertiary"></div>
                                    </label>
                                </label>
                                
                            </div>
                        </div>
                    </div>
                    <div className='grow'>
                        <Field label='SVD Solver' isRequired={true}>
                            <Select menuPortalTarget={document.getElementById('create-vis-popup')} name='svd-solver' inputId="svd-solver" options={pcaOptions} onChange={(e:any) => handleParamSelectionInput(e, 'svd_solver', index)} value={method.params.svd_solver ? {value: method.params.svd_solver, label: method.params.svd_solver} : null} className='text-left text-[14px] rounded w-full' placeholder='SVD Solver'/>                    
                        </Field>
                    </div>
                </div>
            ) : (
                <div data-testid='umap' className='flex justify-between items-center gap-6'>
                    <div className='w-1/4'>
                        <Field label='Nearest Neighbors' isRequired={true}>
                            <input onChange={(e:any) => handleParamStringInput(e, index)} value={method.params.n_neighbors ? method.params.n_neighbors.toString() : ''} name='n_neighbors' className='text-left text-[14px] rounded border border-primary p-2 w-full' placeholder='Nearest Neighbors'/>
                        </Field>
                    </div>
                    <div className='grow'>
                        <Field label='Metric' isRequired={true}>
                            <Select menuPortalTarget={document.getElementById('create-vis-popup')} name='metric' inputId="metric" options={umapMetricOptions} onChange={(e:any) => handleParamSelectionInput(e, 'metric', index)} value={method.params.metric ? {value: method.params.metric, label: method.params.metric} : null} className='text-left text-[14px] rounded w-full' placeholder='Metric'/>                    
                        </Field>
                    </div>
                    <div className='grow'>
                        <Field label='Init' isRequired={true}>
                            <Select menuPortalTarget={document.getElementById('create-vis-popup')} name='init' inputId="init" options={umapInitOptions} onChange={(e:any) => handleParamSelectionInput(e, 'init', index)} value={method.params.init ? {value: method.params.init, label: method.params.init} : null} className='text-left text-[14px] rounded w-full' placeholder='Init'/>                    
                        </Field>
                    </div>
                </div>
            )}
            
        </div>
    )
}