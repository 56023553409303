import { Tooltip } from '@mui/material';
import ReadacrossIcon from '../../assets/purple-beaker.svg';
import { Dispatch, SetStateAction } from 'react';
import { PopupType } from '../../hooks/usePopup';

export default function CreateReadacrossButton({ width, setPopupType, addSingleItem, id, setIsFlow }: { 
    width: string, 
    setPopupType: Dispatch<SetStateAction<PopupType>>,
    addSingleItem: (id: string) => void, 
    setIsFlow?: Dispatch<SetStateAction<boolean>>,
    id: string,
}) {
    return (
        <Tooltip title='Generate Read-Across' placement='bottom'>
            <button onClick={(e:any) => {addSingleItem(id); setPopupType('readacross'); setIsFlow && setIsFlow(true)}} className="flex justify-center items-center gap-2 highlight transition-all ">
                <img style={{width}} src={ReadacrossIcon} alt='readacross-icon'/>
                {/* <p className="font-medium text-[14px]">Generate Read-Across</p> */}
            </button>
        </Tooltip>
    )
}