import { Header } from "../../types/all.types";
import { Link } from "react-router-dom";
import { ChangeEvent, Dispatch, MouseEvent, ReactNode, SetStateAction } from "react";
import ChevronUp from '../../assets/chevron-up.svg';
import ChevronDown from '../../assets/chevron-down.svg';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { selectDatasets } from "../../store/selectors/datasets.selector";


import { useState } from "react";
import { useSelector } from "react-redux";
import { selectJobs } from "../../store/selectors/jobs.selector";
import { useNavigate } from "react-router-dom";

import { ParsedJobs, GroupData } from "../../types/jobs.types";
import JobDropdown from "../../routes/Job Queue/components/JobDropdown";
import { selectPredictions } from "../../store/selectors/predictions.selector";
import { selectModels } from "../../store/selectors/models.selector";
import { selectTheme } from "../../store/selectors/auth.selector";
import { PopupType } from "../../hooks/usePopup";
import { fileExists, getGroupStatusColor, getStatusColor, goToDataset, gotToResult } from "../../data/functions";
import ProgressCell from "./ProgressCell";
import TruncatedText from "../Page/TruncatedText";




export default function JobRows({ groupData, associatedItems, headers, togglePopup, isChecked, selectAllItemsInGroup, selectSingleItemInGroup, selectedGroup, isLoading, setGroupForDeletion, setPopupType, selectSingleJobForDeletion }: {
    groupData?: GroupData,
    associatedItems?: ParsedJobs[];
    headers: Header[],
    togglePopup: (e:MouseEvent<HTMLButtonElement>) => void,
    children?: ReactNode,
    isChecked: boolean,
    selectedGroup?: {groupName: string, items: string[]},
    selectAllItemsInGroup: (e:ChangeEvent<HTMLInputElement>, id: string) => void,
    setGroupForDeletion: (e:any, groupID: string) => void,
    selectSingleItemInGroup: (e:ChangeEvent<HTMLInputElement>, groupID: string, id: string) => void,
    isLoading?: boolean,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
    selectSingleJobForDeletion: (e:any, groupID: string, jobID: string) => void,
    
}) {
    const jobs = useSelector(selectJobs);
    const datasets = useSelector(selectDatasets);
    const results = useSelector(selectPredictions);
    const models = useSelector(selectModels);
    const navigate = useNavigate();
    const theme = useSelector(selectTheme);

    const [ isOpen, setIsOpen ] = useState(false);

    const toggleOpen = (e:any) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    }



    return (
        <>
        {isLoading ? (
            <tr className={`${theme === 'dark' ? 'dark-table-body' : 'table-body'} text-[14px]`}>
                <td className="py-4 px-6"><Skeleton /></td>
            </tr>
        ) : (
        <>
        {groupData && (
            <tr className={`${theme === 'dark' ? 'dark-table-body' : 'table-body'} text-[14px]`}>
            <>
            {headers.map(header => {
                if (header.isRequired) {
                    return (
                        <td className={` text-left hover:cursor-pointer relative py-4 px-4`}>
                            {selectedGroup && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                            <div className="flex justify-start items-center">
                            {isChecked && (<input onChange={(e:any) => {selectAllItemsInGroup(e, groupData.id)}} checked={selectedGroup === undefined ? false : true} name={groupData.id} type="checkbox" className="accent-secondary ml-6 relative z-20 hover:cursor-pointer"/>)}
                            <button onClick={toggleOpen} className="flex gap-3 justify-start items-center">
                                {isOpen ? (
                                    <img src={ChevronUp} className="w-[18px]" alt='close-menu-icon'/>
                                ) : (
                                    <img src={ChevronDown} className="w-[18px]" alt='open-menu-icon'/>
                                )}
                                <TruncatedText text={groupData[header.value as keyof object]} alignment="left">
                                    <p style={{wordWrap: 'break-word'}} className="max-w-[400px] truncate text-left">{groupData[header.value as keyof object]}</p>
                                </TruncatedText>
                            </button>
                            
                            </div>
                        </td>
                    )
                } else {
                    return (
                        <td className={`py-4 px-6 text-center relative ${header.isSelected ? 'table-cell' : 'hidden'}`}>
                            {selectedGroup && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                            {(groupData[header.value as keyof object] !== undefined && groupData[header.value as keyof object] !== '') ? (
                                <>
                                 {header.value === 'status' ? (
                                    <ProgressCell associatedItems={associatedItems} />
                                    // <div style={{backgroundColor: `${getGroupStatusColor(associatedItems).color}`}} className="min-w-[200px] rounded-full py-2 px-4 text-center text-primary">{getGroupStatusColor(associatedItems).status}</div>
                                ) : (header.value === 'dataset' || header.value === 'result') && getGroupStatusColor(associatedItems).status === 'Done' ? (
                                    <button style={{wordWrap: 'break-word'}} disabled={!fileExists(jobs, datasets, models, results, header.value, associatedItems ? associatedItems[0].id : groupData.id)} onClick={(e:any) => {header.value === 'dataset' ? navigate(goToDataset(jobs, associatedItems ? associatedItems[0].id : groupData.id)) : navigate(gotToResult(jobs, associatedItems ? associatedItems[0].id : groupData.id, associatedItems ? associatedItems[0].type : groupData.type))}} className={`text-highlight hover:text-tertiary transition-all disabled:text-primary max-w-[400px] truncate`}>{groupData[header.value as keyof object]}</button>
                                ) : (
                                    <p className="max-w-[400px] truncate">{groupData[header.value as keyof object]}</p>
                                )}
                                </>
                            ) : (
                                <p className="text-[#A9A9A9]">No value</p>
                            )}
                           
                            
                        </td>
                    )
                }
            })}
            </>
                <td className='text-right sticky right-0 cell frozen-column no-shadow'>
                    {selectedGroup && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    <JobDropdown setGroupForDeletion={setGroupForDeletion} groupID={groupData.id} toggleDelete={togglePopup} setPopupType={setPopupType}/>
                </td>
            </tr>
            )}
            {isOpen && associatedItems && associatedItems.length && (
                <>
                {associatedItems.map((item, i) => (
                    <tr className={`text-[14px] ${theme === 'dark' ? 'dark-table-inner-body' : 'table-body'}`}>
                    <>
                    {headers.map(header => {
                        if (header.isRequired) {
                            return (
                                <td className={`pl-[30px] py-4 px-6 text-left z-20 relative`}>
                                    
                                    {selectedGroup && selectedGroup.items.includes(item.id) && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                                    <div className="flex gap-3 justify-start items-center inner-cell">
                                    {isChecked && <input onChange={(e:ChangeEvent<HTMLInputElement>) => selectSingleItemInGroup(e, groupData ? groupData.id : '', item.id)} checked={selectedGroup ? ( selectedGroup.items.includes(item.id) ? true : false ) : false}  type="checkbox" className="accent-secondary mr-2 relative z-20 hover:cursor-pointer"/>}
                                    <TruncatedText alignment="left" text={item[header.value as keyof object]}>
                                        <Link to={`/jobs/${item.id}`} style={{wordWrap: 'break-word'}} className="text-highlight hover:text-tertiary transition-all max-w-[400px] truncate">{item[header.value as keyof object]}</Link>
                                    </TruncatedText>
                                    </div>
                                </td>
                            )
                        } else {
                            return (
                                <td className={`py-4 px-6 text-center text-nowrap relative z-20 ${header.isSelected ? 'table-cell' : 'hidden'}`}>
                                    {/* <div className="inner-shadow absolute top-0 left-0 right-0 bottom-0 z-10"></div> */}
                                    {selectedGroup && selectedGroup.items.includes(item.id) && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                                    {(item[header.value as keyof object] !== undefined && item[header.value as keyof object] !== '') ? (
                                        <>
                                        {header.value === 'status' ? (
                                            <div style={{backgroundColor: `${getStatusColor(item[header.value as keyof object])}`}} className="rounded-full py-2 px-4 text-center text-primary min-w-[200px]">{item[header.value as keyof object]}</div>
                                        ) : (header.value === 'dataset' || header.value === 'result') && item.status === 'Done' ? (
                                            <button disabled={!fileExists(jobs, datasets, models, results, header.value, item.id)} onClick={(e:any) => {header.value === 'dataset' ? navigate(goToDataset(jobs, item.id)) : navigate(gotToResult(jobs, item.id, item.type))}} className={`text-highlight hover:text-tertiary transition-all ${theme === 'dark' ? 'disabled:text-white' : 'disabled:text-primary'} max-w-[400px] truncate`}>{item[header.value as keyof object]}</button>
                                        ) : (
                                            <p className="max-w-[400px] truncate">{item[header.value as keyof object]}</p>
                                        )}
                                        </>
                                    ) : (
                                        <p className="text-[#A9A9A9]">No value</p>
                                    )}
                                    
                                </td>
                            )
                        }
                    })}
                    </>
                    <td className='text-right sticky right-0 cell frozen-column no-shadow z-20'>
                        {selectedGroup && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                        <JobDropdown setGroupForDeletion={setGroupForDeletion} selectSingleJobForDeletion={selectSingleJobForDeletion} jobID={item.id} groupID={groupData ? groupData.id : ''} toggleDelete={togglePopup} setPopupType={setPopupType}/>
                    </td>

                        {/* <td className='text-right'><JobDropdown groupID={groupData ? groupData.id : ''}  toggleDelete={togglePopup}/></td> */}
                    </tr>
                ))}
                </>
            )}
            </>
            )}
        </>
    )
}