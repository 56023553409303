import { TextField, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from '../../assets/search.svg';
import CloseIcon from '../../assets/x-close.svg';
import { ChangeEvent, Dispatch, SetStateAction } from "react";

export default function SearchBar({ searchInput, setSearchInput, identifier, applySearch }: {
    searchInput: string,
    setSearchInput: Dispatch<SetStateAction<string>>,
    identifier: string,
    applySearch: (e:any, input: string) => void,
}) {

    const handleClear = (e:any) => {
        e.preventDefault();
        setSearchInput('');
        applySearch(e, '')
    };

    const search = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value)
    };

    const handleKeyDown = (e:any) => {
        if (e.key === 'Enter') {
            searchInput && applySearch(e, searchInput);
        }
    }

    return (
        <TextField
            value={searchInput}
            onChange={search}
            onKeyDown={handleKeyDown}
            placeholder={`Search by ${identifier}`}
            fullWidth
            sx={{
                height: '42px', // Adjusts the overall TextField height
                backgroundColor: 'white',
                borderColor: '#CBCEDC',
                '& .MuiOutlinedInput-root': {
                  padding: '0 8px', // Adjusts padding inside the input field
                  height: '100%', // Makes input field height match TextField height
                  fontFamily: 'Montserrat',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #CBCEDC', // Custom focus color
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #1AC1A3', // Custom focus color
                    },
                   
                },
                '& .MuiInputBase-input': {
                  padding: '8px 4px', // Controls padding within the input area
                },
              }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                       <img src={SearchIcon} className="w-[18px] ml-1" alt='magnifying-glass'/>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClear}
                            style={{visibility: searchInput ? 'visible' : 'hidden'}}
                            aria-label="clear search"
                        >
                            <img src={CloseIcon} className="w-[18px]" alt='clear-search'/>
                        </IconButton>
                    </InputAdornment>
                )
            }}
        >

        </TextField>
    )
}