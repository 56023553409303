import { Dispatch, SetStateAction } from 'react';
import { PopupType } from '../../hooks/usePopup';
import LegendIcon from '../../assets/map-legend-o-svgrepo-com.svg';

export default function TableInformationButton({ togglePopup, setPopupType }: {
    togglePopup: (e:any) => void,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
}) {
    return (
        <button onClick={(e:any) => {togglePopup(e); setPopupType('table-info')}} className={`bg-grad-two hover:brightness-90 transition-all  text-primary flex justify-center items-center gap-3 py-2 px-5 rounded text-nowrap`}>
            <img src={LegendIcon} alt='filter-icon' className='w-[18px]'/>
            <p className='font-medium hidden lg:block'>View Legend</p>
        </button>
    )
}