import { useState, MouseEvent, useEffect, ChangeEvent, useCallback } from "react";
import { cleanFileName } from "../../data/functions";
import API from '../../api';
import { FileType, UploadFileForm } from "../../types/files.types";
import { addFile, setSelectedFile } from "../../store/slices/files.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFiles } from "../../store/selectors/files.selectors";

const defaultForm = {
    file_name: '',
    project: '',
    description: '',
    organism: '',
    measurement_type: '',
    target: '',
    access_type: 'private',
    smiles: '',
}

const getDatedFilename = (prefix: string) => {
    const now = new Date();
    return prefix + '-' + now.toISOString().substring(0, 19) + '.csv';
}

const mimeTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];

const useUploadFileHandlers = ({
    isFlow,
    isDashboard,
    next,
    togglePopup,
    setPopupMessage,
}: {
    isFlow?: boolean,
    isDashboard?: boolean,
    next?: (e:any) => void,
    togglePopup: (e:any) => void,
    setPopupMessage? : (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ isAuto, setIsAuto ] = useState(true);
    const [ isWarningOpen, setIsWarningOpen ] = useState(false);
    
    const [ form, setForm ] = useState<UploadFileForm>(defaultForm);
    const [ projects, setProjects ] = useState<{value: string, label: string}[]>([]);
    const [ type, setType ] = useState<'new-file' | 'cas-list'>('new-file');
    const [ headers, setHeaders ] = useState<{value: string, label: string}[]>([]);
    const [ uploadedFile, setUploadedFile ] = useState<File | null>(null);
    const [ casList, setCasList ] = useState('');
    const [ notReturned, setNotReturned ] = useState<string[]>([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const files = useSelector(selectFiles);


    useEffect(() => {
        if (type === 'new-file') {
            if (uploadedFile) {
                if (isAuto) {
                    setForm({...form, file_name: cleanFileName(uploadedFile.name)})
                };
            } else {
                setForm({...form, file_name: ''})
            }
        } else if (type === 'cas-list') {
            if (isAuto) {
                setForm({...form, file_name: getDatedFilename('CAS_List')});
            }
        }
        // eslint-disable-next-line
    }, [isAuto, uploadedFile, type]);
    
    useEffect(() => {
        if (files) {
            const projectArr = files.map(file => file.metadata.project);
            const sortedProjects = projectArr.sort()
            const filteredProjects = Array.from(new Set(sortedProjects)).filter(a => a).map(p => ({value: p, label: p} as { value:string, label: string} ));
            setProjects(filteredProjects);
        }

    }, [files]);

    useEffect(() => {
        if (type === 'cas-list') {
            if (!casList || !form.file_name || !form.access_type || !form.measurement_type || !form.organism || !form.target || !form.project) {
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
        } else {
            if (!uploadedFile || (uploadedFile && mimeTypes.includes(uploadedFile.type) && !form.smiles) || !form.file_name || !form.access_type || !form.measurement_type || !form.organism || !form.project || !form.target) {
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
        }
    }, [form, type, uploadedFile, casList]);


    const toggleWarning = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsWarningOpen(!isWarningOpen);
    }

    const changeFileType = (e: MouseEvent<HTMLButtonElement>, fileType: 'new-file' | 'cas-list') => {
        e.preventDefault();
        setType(fileType);
        setUploadedFile(null);
    }

    const resetValues = () => {
        setUploadedFile(null);
        setForm(defaultForm);
        setHeaders([]);
        setProjects([]);
        setCasList('');
        setIsLoading(false);
        setIsDisabled(false);
        setErrorMessage('');
    }

    const handleCheck = (e:ChangeEvent<HTMLInputElement>) => {
        setIsAuto(!isAuto)
    }

    const handleInput = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const handleSelect = (e:any, name: string) => {
        setForm({...form, [name]: e.value})
    };

    const handleCasList = (e:ChangeEvent<HTMLTextAreaElement>) => {
        setCasList(e.target.value);
    }


    const onDrop = useCallback((acceptedFiles: File[]) => {
        setUploadedFile(acceptedFiles[0]);    
        if (mimeTypes.includes(acceptedFiles[0].type))  {
            handleFilePreload(acceptedFiles[0]);
        } else {
            setHeaders([]);
        }
        // eslint-disable-next-line
    }, []);


    const handleFilePreload = async (file: File) => {
        let formData = new FormData();
        formData.append('file', file, cleanFileName(file.name));
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {
                const response = await API.post('preload', formData, { headers: { 'X-Auth': authKey } });
                setHeaders(response.data.map((name:string) => ({ value: name, label: name })));
                setErrorMessage('');
            } catch (err: any) {
                console.log(err);
                setErrorMessage('We encountered an issue while trying to parse your file. Please check that the file is in the correct format and try again.');
            }
        } 
    };

    const handleFileSubmit = async (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);

        if (type === 'cas-list') {
            const data = await convertCasList(e);
            if (data !== null) {
                const { newFile, noRecordList } = data;
                if (noRecordList === null) {
                    await handleUploadFile(e, newFile);
                } else {
                    setUploadedFile(newFile);
                    setNotReturned(noRecordList);
                    toggleWarning(e);
                }
            } else {
                setErrorMessage('There was a problem converting this cas list');
                setIsLoading(false);
                setIsDisabled(false);
                return;
            }
        } else if (type === 'new-file' && uploadedFile) {
            // casData = null;
            await handleUploadFile(e, uploadedFile);
        } else {
            return;
        }
        
    };

    const handleUploadFile = async (e:any, newFile?: File) => {
        let formData = new FormData();

        let file: File | null = null;

        if (newFile) {
            file = newFile
        } else {
            file = uploadedFile;
        }

        const metadata = {
            description: form.description,
            organism: form.organism,
            target: form.target,
            measurement_type: form.measurement_type,
            project: form.project,
        }
        
        
        if (type === 'new-file' && file) {
            formData.append('file', file, cleanFileName(form.file_name));
            formData.append('field_name', form.smiles);
        } else if (type === 'cas-list' && file) {
            formData.append('file', file, cleanFileName(form.file_name) );
            formData.append('field_name', 'SMILES');
        }

        formData.append('metadata', JSON.stringify(metadata));
        formData.append('access', form.access_type);

        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {

                const response = await API.post('files', formData, { headers: { 'X-Auth': authKey } });
                const returnedFiles: FileType = await response.data[0];
                dispatch(addFile(returnedFiles));
                
                if (isFlow !== undefined && isFlow) {
                    dispatch(setSelectedFile(returnedFiles));
                } else if (isDashboard) {
                    navigate(`/files/${returnedFiles._id.$oid}`)
                    dispatch(setSelectedFile(null));
                } else {
                    dispatch(setSelectedFile(null));
                }


                if (next) {
                    next(e);
                } else {
                    togglePopup(e);
                }

                setErrorMessage('');
                setPopupMessage && setPopupMessage('Your file has been uploaded successfully!', true);
                
    
            } catch (err:any) {
                const details = err.response.data.detail;
                if (details) {
                    setErrorMessage(details);
                } else {
                    setErrorMessage('There was an error uploading your file');
                }
                
            }
        }
        

        setIsLoading(false);
        setIsDisabled(false);
    }

    const convertCasList = async (e:any) => {
        const trimmedList = casList.replace(/\s/g, '');
        const pipeSeparatedList = trimmedList.replaceAll(',', '|');
        const encodedString = encodeURIComponent(pipeSeparatedList);
        
        try {
            const response = await API.get(`https://cdxapps.epa.gov/oms-substance-registry-services/rest-api/substances/cas?casList=${encodedString}`);
            const data = await response.data;
            const smilesArray = data.map((d:any) => d.smilesNotation);
            const totalString = 'SMILES\n' + smilesArray.join('\n');
            const newFile = new File([totalString], form.file_name, {type: "text/csv"});

            const noRecordList: string[] | null = compareNewFileToCasList(pipeSeparatedList.split('|'), data.map((d:any) => d.currentCasNumber));

            return { newFile, noRecordList }
        } catch (err:any) {
            console.log(err);
            return null;
        }
    };

    const compareNewFileToCasList = (originalList: string[], newList: string[]) => {
        let noRecord: string[] = [];

        originalList.forEach(item => {
            if (!newList.includes(item)) {
                noRecord.push(item);
            }
        });

        if (noRecord.length > 0) {
            return noRecord;
        } else {
            return null;
        }


    }


    const handleWarning = async (e:any, proceed: boolean) => {
        e.preventDefault(); 
    
        if (proceed) {
            toggleWarning(e);
            await handleUploadFile(e);
        } else {
            resetValues();
            toggleWarning(e);
        }
    }

    return {
        errorMessage,
        handleFileSubmit,
        handleWarning,
        handleCasList,
        handleCheck,
        handleInput,
        handleSelect,
        onDrop,

        changeFileType,
        toggleWarning,
        
        isAuto,
        isLoading,
        isDisabled,
        isWarningOpen,
        
        projects,
        headers,
        notReturned,
        casList,
        form,
        type,
        uploadedFile,
    }
};

export default useUploadFileHandlers;