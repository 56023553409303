import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, MouseEvent, Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";

import { selectSingleFile } from "../../store/selectors/files.selectors";
import { selectSingleModel } from "../../store/selectors/models.selector";

import { CompleteModel } from "../../types/models.types";
import { MakePredictionForm, SinglePredictionForm, SinglePredictionResults } from "../../types/predictions.types";
import { FieldMappingForm, FieldMappingType } from "../../types/all.types";
import { setSelectedFile } from "../../store/slices/files.slice";
import { setSelectedModel } from "../../store/slices/models.slice";
import { cleanFileName } from "../../data/functions";

import API from '../../api';
import { PopupType } from "../usePopup";


const defaultForm: MakePredictionForm = {
    file_ids: [],
    name: '',
    mode: null,
    average_mode: 'name',
    model_names: [],
    stdizer: "Simple",
    model_ids: [],
    fields_mapping: [],
}

const defaultFieldMapping: FieldMappingForm = {
    'chem-name': {
        name: '',
        op: null,
        type: 'chem-name',
        value: null,
    },
    'chem-id': {
        name: '',
        op: null,
        type: 'chem-id',
        value: null,
    },
    'non-activity': [],
}


const useMakeNewPredictionHandlers = ({ togglePopup, setPopupType, setPopupMessage, selectedType }: {
    togglePopup: (e:any) => void,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
    setPopupMessage: (popupMessage: string, isSuccessMessage: boolean) => void,
    selectedType: string,
}) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const selectedFile = useSelector(selectSingleFile);
    const selectedModel = useSelector(selectSingleModel);
    const [ selectedModels, setSelectedModels ] = useState<CompleteModel[] | null>(null);
    const [ query, setQuery ] = useState('');
    const [ prevQuery, setPrevQuery ] = useState('');
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
 
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ form, setForm ] = useState<MakePredictionForm>(defaultForm);
    const [ fieldsMapping, setFieldsMapping ] = useState<FieldMappingForm>(defaultFieldMapping);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ singleClassField, setSingleClassField ] = useState<FieldMappingType | null>(null);
 
    const [ smiles, setSmiles ] = useState('');
    const [ predictionResults, setPredictionResults ] = useState<SinglePredictionResults[]>([]);
    const [ classificationResults, setClassificationResults ] = useState<SinglePredictionResults[]>([]);
    const [ regressionResults, setRegressionResults ] = useState<SinglePredictionResults[]>([]);
 
    const [ individualMethods, setIndividualMethods ] = useState<{model: string, methods: string[]}[]>([]);
 
    const handleMethodSelection = (e:any, modelName: string) => {
         const existingModel = individualMethods.find(m => m.model === modelName);
         if (existingModel) {
             setIndividualMethods(individualMethods.map(m => {
                 if (m.model === modelName) {
                     return { ...m, methods: e.map((val:any) => val.value)}
                 } else {
                     return { ...m }
                 }
             }))
         } else {
             setIndividualMethods(individualMethods.concat({model: modelName, methods: e.map((val:any) => val.value)}))
         }
    }
 
    const resetMethods = () => {
     setIndividualMethods([]);
    }
 
 
 
    const resetValues = () => {
     dispatch(setSelectedFile(null));
     dispatch(setSelectedModel(null));
     setForm(defaultForm);
     setSelectedModels(null);
     setSmiles('');
     setPredictionResults([]);
     setIndividualMethods([]);
    };
    
 
    const resetFile = (e:MouseEvent<HTMLButtonElement>) => {
         e.preventDefault();
         dispatch(setSelectedFile(null));
         setForm(defaultForm);
    };
 
 
    useEffect(() => {
     const queryArr = search.slice(1).split('&');
     const q = queryArr[0].split('=')[1];
     setQuery(q);
 
     if (prevQuery && prevQuery !== q) {
         resetValues();
     }
 
     setPrevQuery(q);
 
 
     setRoutes([
         {
             label: 'Predictions',
             path: 'predictions',
         },
         {
             label: 'Make New Prediction',
             path: `predictions/make-new-prediction?type=${q}`,
         }
     ])
     //eslint-disable-next-line
    }, [search]);
 
    useEffect(() => {
     if (selectedModel) {
         setSelectedModels([selectedModel])
     } else {
         setSelectedModels(null);
     }
    }, [selectedModel]);

 
 
    useEffect(() => {
     if (predictionResults.length !== 0 && selectedModels) {
         let classification: SinglePredictionResults[] = [];
         let regression: SinglePredictionResults[] = [];
 
         predictionResults.forEach(res => {
             const model_name = res.model;
             const model = selectedModels.find(m => m.name === model_name);
             if (model) {
                 if (model['auc' as keyof object]) {
                     classification.push(res);
                 } else {
                     regression.push(res);
                 }
             }
         })
 
         setClassificationResults(classification);
         setRegressionResults(regression);
     }
    }, [predictionResults, selectedModels]);

 
    const returnMethods = () => {
         if (form.average_mode === 'name' && selectedModels) {
            console.log(selectedModels)
             return selectedModels.flatMap(models => Object.keys(models.methodIds).map(method => models.methodIds[method]));
         } else {
             return individualMethods.flatMap(m => m.methods);
         }
    }
 
    const handleSubmit = async (e:MouseEvent<HTMLButtonElement>) => {
     e.preventDefault();
 
 
     let fields: FieldMappingForm = {
         'chem-name': fieldsMapping['chem-name'] && fieldsMapping['chem-name'].name ? fieldsMapping['chem-name'] : null,
        'chem-id': fieldsMapping['chem-id'] && fieldsMapping['chem-id'].name ? fieldsMapping['chem-id'] : null,
     };
 
     (fieldsMapping['non-activity'] as FieldMappingType[]).forEach((field, i) => {
         fields[`non-activity-${i}` as keyof object] = {...field};
     })
 
     if (singleClassField !== null && singleClassField.name) {
         fields['single-class-label'] = {...singleClassField};
     }
 
     let mapping: FieldMappingType[] = [];
     Object.keys(fields).forEach(key => {
         if (fields[key] !== null) {
             mapping.push(fields[key] as FieldMappingType)
             
         }
     }) 
 
 
     const newForm: MakePredictionForm = {...form, name: cleanFileName(form.name),  fields_mapping: mapping, model_ids: returnMethods(), model_names: selectedModels ? selectedModels.map(model => model.name) : []};
     const authKey = localStorage.getItem('X-Auth');
 
         if (authKey) {
             setIsLoading(true);
             setIsDisabled(true);
 
             try {
 
                 const response = await API.post('resultsets', newForm, { headers: { 'X-Auth': authKey } });      
                 const status = response.status;
                 if (status === 200) {
                     togglePopup(e);
                     setPopupType('success');
                 }
 
                 
 
             } catch (err: any) {
                 
                 console.log(err);
                 setPopupMessage('We encountered an issue generating your resultset. Please try again.', false);
 
             }
 
             setIsLoading(false);
             setIsDisabled(false);
         } 
     }
 
     const handleSingleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
         e.preventDefault();
         if (!selectedModels || !selectedModels.length || !smiles) return;
 
         let ids: string[] = [];
 
         selectedModels.forEach(model => {
             Object.keys(model.methodIds).forEach(id => {
                 ids.push(model.methodIds[id]);
             })
         })
 
         const body: SinglePredictionForm = {
             structures: [smiles],
             models: selectedModels.map(model => model.name),
             model_ids: ids,
             average_mode: true,
         }
 
         const authKey = localStorage.getItem('X-Auth');
 
         if (authKey) {
             setIsLoading(true);
             setIsDisabled(true);
 
             try {
 
                 const response = await API.post('predict', body, { headers: { 'X-Auth': authKey } });
                 const data = await response.data;
                 setPredictionResults(data);
 
                 const targetElement = document.getElementById('results-table');
                 if (targetElement) {
                     document.body.scrollTo({
                         top: targetElement.offsetTop,
                         behavior: 'smooth'
                         });
                 }
 
             } catch (err: any) {
                 console.log(err);
                 setPopupMessage(`${err.response.data.detail}`, false)
             }
 
             setIsLoading(false);
             setIsDisabled(false);
         }
 
         
 
     }
 
 
     const addMultipleModels = (models: CompleteModel[]) => {
         setSelectedModels(models);
     }
 
     
     const deleteModel = (e: MouseEvent<HTMLButtonElement>, index: number, modelName: string) => {
         if (selectedModels) {
             setSelectedModels(selectedModels.filter((m,i) => i !== index))
         }
 
         if (individualMethods.length > 0) {
             setIndividualMethods(individualMethods.filter(m => m.model !== modelName))
         }
 
 
     }
 
 
     useEffect(() => {
         if (query === 'set') {
             if (!selectedFile || (selectedModels && !selectedModels.length)) {
                 setIsDisabled(true)
             } else {
                 setIsDisabled(false);
             }
         } else {
             if (!smiles || !selectedModels || (selectedModels && !selectedModels.length)) {
                 setIsDisabled(true)
             } else {
                 setIsDisabled(false);
             }
         }
     }, [query, selectedModel, selectedFile, selectedModels, smiles]);
  
     const next = (e:MouseEvent<HTMLButtonElement>) => {
         if (selectedType === 'new-file') {
             setPopupType('upload-file');
         } else if (selectedType === 'existing-file') {
             setPopupType('existing-file');
         } else if (selectedType === 'compound-list') {
             setPopupType('upload-file');
         }
     };
 
     const goBack = (e:MouseEvent<HTMLButtonElement>) => {
         setPopupType('select-file-type');
     };


     return {
        next,
        goBack,
        handleMethodSelection,
        handleSingleSubmit,
        handleSubmit,

        resetFile, 
        resetMethods,
        resetValues,

        routes,
        isDisabled,
        isLoading,
        setFieldsMapping,
        setSingleClassField,
        classificationResults,
        regressionResults,
        addMultipleModels,
        deleteModel,

        selectedFile,
        selectedModels,
        query,
        form,
        setForm,
        smiles,
        setSmiles,
        predictionResults,
        individualMethods,
        fieldsMapping,
        singleClassField
     }
};


export default useMakeNewPredictionHandlers;