import { MouseEvent, useState, useEffect } from "react"
import Overlay from "./Overlay";
import { ClassificationRecords, RegressionRecords } from "../../types/models.types";
import NumberLine from "../Data/NumberLine";
import { Limit } from "../../types/all.types";
import ModelGraphs from "../Data/ModelGraphs";
import ChangePage from "../Page/ChangePage";

export default function PlotPopup({ togglePopup, metrics, limits, title, type, allData, methodPlot }: { 
    togglePopup: (e:MouseEvent<HTMLButtonElement>) => void,
    metrics: any,
    title: string,
    type: 'classification' | 'regression',
    allData: any,
    limits?: Limit[],
    methodPlot?: { name: string, index: number, graphs: string[] },
}) {

    const [ fields, setFields ] = useState<ClassificationRecords | RegressionRecords | null>(null);
    const [ plot, setPlot ] = useState<{ name: string, index: number, graphs: string[]} | null>(null);

    useEffect(() => {
        if (methodPlot) {
            setPlot({...methodPlot, index: 0});
        }
    }, [methodPlot]);

    const next = (e:MouseEvent<HTMLButtonElement>, name: string) => {
        e.preventDefault();
        if (plot) {
            if (plot.index === plot.graphs.length-1) {
                return;
            } else {
                setPlot({...plot, index: plot.index + 1});
            }
        }
    }

    const back = (e:MouseEvent<HTMLButtonElement>, name: string) => {
        e.preventDefault();
        if (plot) {
            if (plot.index === 0) {
                return;
            } else {
                setPlot({...plot, index: plot.index - 1});
            }
        }

    }
    

    useEffect(() => {
        if (metrics) {
            const field = metrics.find((m:any) => m.method_name === title);
            if (field) {
                setFields(field);
            }
        }
    }, [metrics, title])
    
    return (
        <Overlay togglePopup={togglePopup}>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded max-w-[1200px] w-full'>
                    <div className='popup-max-height '>
                        <div className='flex gap-6 justify-center items-stretch'>
                                
                        <div className='rounded flex flex-col gap-6 p-6 bg-white w-1/2'>
                            <div className="w-full flex justify-end">
                            {type === 'classification' && (
                                <ChangePage controls={{ back, next }} methodPlot={plot ? plot : undefined} name={plot ? plot.name : ''}/>
                            )}
                            </div>
                            <ModelGraphs type={type} allData={allData} methodPlot={plot ? plot : undefined} />
                            </div>

                            <div className="w-1/2 flex flex-col justify-between items-stretch py-6 pr-6">
                                <div className="field-popup-max-height h-full overflow-y-auto">
                                    <div className="flex flex-col gap-4 justify-start items-stretch">
                                        <p className="font-semibold text-[18px] text-primary">{title}</p>
                                        <div className='flex flex-col gap-4 justify-center items-stretch'>
                                        {fields !== null && Object.keys(fields).map(key => {
                                            const limitsObj = limits && limits.find(limit => limit.value === key);
                                            if (limitsObj) {
                                                return (
                                                    <NumberLine label={key} max={limitsObj.max} min={limitsObj.min} value={Number((Math.round(Number(fields[key as keyof object]) * 100) / 100 as number).toFixed(2))} />
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex justify-end items-center">
                                    <div className="flex gap-2">
                                        <button onClick={togglePopup} className='flex items-center justify-center gap-2 py-2 px-4 rounded text-white text-[14px] text-medium border border-tertiary bg-tertiary hover:bg-secondary hover:border-secondary transition-all font-medium disabled:border-quaternary/[0.3] disabled:bg-quaternary/[0.3]'>
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
       
    )
}