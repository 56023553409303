import ExpandAll from '../../../../assets/expand-all.svg';
import CollapseAll from '../../../../assets/collapse-all.svg';
import Plus from '../../../../assets/purple-plus.svg';
import FormButtons from '../../../../components/Buttons/FormButtons';
import VisBox from './VisBox';
import { useState, MouseEvent, Dispatch, SetStateAction, useEffect } from 'react';
import API from '../../../../api';
import { useParams } from 'react-router-dom';
import { PopupType } from '../../../../hooks/usePopup';
import ErrorMessage from '../../../../components/Alerts/ErrorMessage';

export interface VisForm {
    fingerprint: {
        name: 'ECFP' | 'FCFP' | 'MACCS',
        params?: {
            nBits: number,
            radius: number,
            useChirality: boolean,
        },
    },
    visualization_methods: {
        name: 'TSNE' | 'PCA' | 'UMAP',
        params: {
            [key: string]: number | string | boolean,
        }
    }[],
    isCollapsed: boolean,
}

const defaultVisForm: VisForm = {
    fingerprint: {
        name: 'MACCS',
    },
    visualization_methods: [{
        name: 'TSNE',
        params: {
            perplexity: 30,
            method: 'barnes_hut'
        }
    }],
    isCollapsed: false,
}

export default function CreateVisPopup ({ togglePopup, setPopupType }: {
    togglePopup: (e:any) => void,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
}) {

    const { id } = useParams();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');

    const [ visFormList, setVisFormList ] = useState<VisForm[]>([defaultVisForm]);

    const addVis = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setVisFormList(visFormList.map(form => ({...form, isCollapsed: true})).concat(defaultVisForm));
    }

    const collapseAll = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setVisFormList(visFormList.map(form => ({...form, isCollapsed: true})));
    };

    const expandAll = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setVisFormList(visFormList.map(form => ({...form, isCollapsed: false})));
    };

    const handleSubmit = async (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setErrorMessage('');

        let formattedForm: {
            rs_id: string,
            fingerprint: {
                name: string,
                params: {
                    nBits: number,
                    radius: number,
                    useChirality: boolean,
                } | {},
            },
            visualization_method: {
                name: 'TSNE' | 'PCA' | 'UMAP',
            params: {
                [key: string]: number | string | boolean,
            }
            }
        }[] = [];

        visFormList.forEach(form => {
            form.visualization_methods.forEach(method => {
                formattedForm.push({
                    rs_id: id!,
                    fingerprint: {
                        name: form.fingerprint.name,
                        params: form.fingerprint.params ? {
                            ...form.fingerprint.params,
                            nBits: Number(form.fingerprint.params.nBits),
                            radius: Number(form.fingerprint.params.radius),
                        } : {},
                    },
                    visualization_method: {
                        name: method.name,
                        params: method.params,
                    }
                })
            })
        })
    
        const authKey = localStorage.getItem('X-Auth');
        
        const promises = formattedForm.map(async (form) => {
            return API.post('resultsets/visualize', { ...form }, { headers: { 'X-Auth': authKey} }).then(res => console.log(res.status)).catch(err => console.log(err))
        });

        setIsLoading(true);
        setIsDisabled(true);

        try {
            await Promise.all(promises);
            setPopupType('success');
        } catch (err:any) {
            console.log(err);
            setErrorMessage('There was an error creating the visualizations. Please try again.')
        }

        setIsLoading(false);
        setIsDisabled(false);
    };

    useEffect(() => {
        for (const form of visFormList) {
            if (!form.visualization_methods.length) {
                setIsDisabled(true);
                break;
            } else {
                let hasEmptyParams = false;
                for (const method of form.visualization_methods) {
                    if(Object.values(method.params).includes("")) {
                        hasEmptyParams = true;
                        break;
                    } else {
                        hasEmptyParams = false;
                    }
                }
                
                if (hasEmptyParams) {
                    setIsDisabled(true);
                } else {
                    setIsDisabled(false);
                }
            }
        }
    }, [visFormList]);

    

    return (
        <>
           <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div id='create-vis-popup' className={`bg-background rounded p-8 max-w-[650px] w-full popup-menu`}>
                    <div className='popup-max-height overflow-y-auto'>
                        <div className='flex flex-col gap-8 justify-center items-stretch'>  

                            <div className='w-full flex justify-between items-center'>
                                <p className='text-primary font-semibold text-[1.5rem]'>Create Visualization</p>
                                <div className='flex justify-end items-center gap-4'>
                                    <button onClick={expandAll} className='py-2 px-4 border border-secondary rounded text-secondary hover:bg-secondary hover:text-white transition-all lighten flex justify-center items-center gap-2'>
                                        <img src={ExpandAll} className='w-[16px]' alt='expand-all-icon'/>
                                        <p className='font-medium text-nowrwap'>Expand All</p>
                                    </button>
                                    <button onClick={collapseAll} className='py-2 px-4 border border-primary rounded text-primary hover:bg-primary hover:text-white transition-all lighten flex justify-center items-center gap-2'>
                                        <img src={CollapseAll} className='w-[16px]' alt='collapse-all-icon'/>
                                        <p className='font-medium text-nowrwap'>Collapse All</p>
                                    </button>
                                </div>
                            </div>

                            <div className='flex flex-col gap-6'>
                                <div className='flex flex-col gap-8 justify-start items-stretch'>
                                    {visFormList.map((form, i) => (
                                        <VisBox visForm={form} visFormList={visFormList} setVisFormList={setVisFormList} index={i}/>
                                    ))}
                                    
                                </div>

                                <div className='w-full'>
                                    <button onClick={addVis} className={`lighten text-[#362484] border border-[#362484] hover:bg-[#362484] hover:text-white transition-all flex justify-center items-center gap-3 py-2 px-4 rounded`}>
                                        <img src={Plus} className='w-[16px]' alt='add-icon'/>
                                        <p className='font-medium text-nowrap'>Add New Visualization</p>
                                    </button>
                                </div>
                            </div>

                            {errorMessage && (
                                <ErrorMessage errorMessage={errorMessage} />
                            )}

                            <FormButtons backFunction={undefined} continueFunction={(e:any) => handleSubmit(e)} cancelFunction={(e:any) => togglePopup(e)} isDisabled={isDisabled} isLoading={isLoading}/>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}