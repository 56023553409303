// assets
import Success from '../../assets/success.png';

// components

// hooks
import { useNavigate } from 'react-router-dom';

// types
import { MouseEvent } from "react";

export default function SuccessPopup({togglePopup, type, resetValues}: {togglePopup: (e:MouseEvent<HTMLButtonElement>) => void, type: 'models' | 'predictions' | 'visualizations', resetValues?: () => void}) {
    const navigate = useNavigate();


    return (
        <>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-white rounded p-10  max-w-[450px] w-full'>
                    <div className='popup-max-height overflow-y-auto'>
                        <div className='flex flex-col justify-center items-stretch text-primary'>
                            <div className='w-full flex justify-center mb-2'><img src={Success} alt='success-icon' className='w-[70px]' /></div>
                            <p className='text-[2rem] font-semibold w-full text-center mb-5'>Success!</p>
                            {type === 'models' ? (
                                <>
                                    <div className='w-full flex justify-center mb-8'><p className='max-w-[400px] text-center font-medium'>Your model is now being trained. We'll notify you once this process has completed.</p></div>
                                    <div className='flex gap-4 justify-center flex-col'>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e); resetValues && resetValues()}} className='w-full py-2 px-4 border-2 border-tertiary hover:border-secondary hover:bg-secondary transition-all text-white font-medium rounded bg-tertiary'>Train another model</button>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e); navigate('/models')}} className='w-full py-2 px-4 border-2 border-quaternary text-quaternary hover:text-white hover:bg-quaternary transition-all font-medium rounded bg-white'>Finish</button>
                                    </div>
                                </>
                            ) : type === 'predictions' ? (
                                <>
                                    <div className='w-full flex justify-center mb-8'><p className='max-w-[400px] text-center font-medium'>Your prediction is now being generated. We'll notify you once this process has completed.</p></div>
                                    <div className='flex gap-2 justify-center flex-col'>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e); resetValues && resetValues()}} className='w-full py-2 px-4 border-2 border-tertiary hover:border-secondary hover:bg-secondary transition-all text-white font-medium rounded bg-tertiary'>Make another prediction</button>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e); navigate('/predictions')}} className='w-full py-2 px-4 border-2 border-quaternary text-quaternary hover:text-white hover:bg-quaternary transition-all font-medium rounded bg-white'>Finish</button>
                                    </div>
                                </>
                            ) : (

                                <>
                                    <div className='w-full flex justify-center mb-8'><p className='max-w-[400px] text-center font-medium'>Your visualizations are now being generated. We'll notify you once this process has completed.</p></div>
                                    <div className='flex gap-2 justify-center flex-col'>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e)}} className='w-full py-2 px-4 border-2 border-tertiary hover:border-secondary hover:bg-secondary transition-all text-white font-medium rounded bg-tertiary'>Finish</button>
                                    </div>
                                </>

                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}